import { QueryFunction, useQuery } from '@tanstack/react-query';

import queryKeys from '../constants/query-keys';
import { ConvertKeysToCamelCase, convertToCamelCase, convertToSnakeCase } from '../utils/case-converter';
import apiClient from '../utils/api-client';
import { TBasicListFilters, TPaginationOptions } from '../types/PaginationTypes';
import { TOrderDetails, TOrdersListResponse } from '../types/OrderTypes';

export const useOrders = (filters: TBasicListFilters, paginationOptions: TPaginationOptions) => {
  const { isLoading, data, error, isError } = useQuery({
    queryKey: [queryKeys.ORDERS_LIST, filters, paginationOptions],
    queryFn: fetchOrdersList,
  });
  return { isLoading, ordersResponse: data, error, isError };
};

export const useOneOrder = (orderId: string) => {
  const { isLoading, data, error, isError } = useQuery({
    queryKey: [queryKeys.ORDERS_LIST, orderId],
    queryFn: fetchOneOrder,
    enabled: Boolean(orderId),
  });
  return { isLoading, order: data, error, isError };
};

const fetchOrdersList: QueryFunction<ConvertKeysToCamelCase<TOrdersListResponse>, [string, TBasicListFilters, TPaginationOptions]> = async ({
  queryKey,
}) => {
  const [, filters, paginationOptions] = queryKey;
  const { data } = await apiClient.get('/v1/orders', convertToSnakeCase({ ...filters, ...paginationOptions }));
  return convertToCamelCase(data);
};

const fetchOneOrder: QueryFunction<ConvertKeysToCamelCase<TOrderDetails>, [string, string]> = async ({ queryKey }) => {
  const [, orderId] = queryKey;
  const { data, error } = await apiClient.get(`/v1/orders/${orderId}`);
  if (error) throw new Error(error.message);
  return convertToCamelCase(data);
};
