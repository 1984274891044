import React from 'react';
import { PiDownloadSimple } from 'react-icons/pi';
import { IconButton, IconButtonProps } from '@mui/material';

import { redirectToRailsApp } from '../../utils/route-utils.ts';

interface DownloadReportButtonProps extends IconButtonProps {
  url: string;
}

const DownloadReportButton: React.FC<DownloadReportButtonProps> = ({ url, onClick, sx = {}, ...rest }) => {
  const downloadReport = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    onClick?.(e);
    redirectToRailsApp(url);
  };

  return (
    <IconButton
      {...rest}
      onClick={downloadReport}
      aria-label='download report'
      sx={{
        height: '36px',
        width: '36px',
        backgroundColor: '#fff',
        border: '1px solid',
        borderColor: 'neutral.200',
        '&:focus': { outline: 'none' },
        '&:hover': { borderColor: 'neutral.200' },
        borderRadius: '4px',
        ...sx,
      }}>
      <PiDownloadSimple />
    </IconButton>
  );
};

export default DownloadReportButton;
