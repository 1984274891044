import { DateTime } from 'luxon';

export const formatCurrentDateTime = () => {
  const date = DateTime.now();
  const formattedDate = date.toLocaleString({
    day: '2-digit',
    month: 'short',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    hour12: true,
  });
  return formattedDate.replace(/\b(am|pm)\b/gi, (match) => match.toUpperCase());
};

export const formatDateTime = (date: string) => {
  const formattedDate = DateTime.fromISO(date).toLocaleString({
    day: '2-digit',
    month: 'short',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    hour12: true,
  });
  return formattedDate.replace(/\b(am|pm)\b/gi, (match) => match.toUpperCase());
};

export const formatDate = (dateString: string): string => {
  const date = DateTime.fromISO(dateString);
  const now = DateTime.local();

  const formattedTime = date.toLocaleString(DateTime.TIME_SIMPLE); // e.g., "2:30 PM"

  if (date.hasSame(now, 'day')) {
    return `Today at ${formattedTime}`;
  }

  const yesterday = now.minus({ days: 1 });
  if (date.hasSame(yesterday, 'day')) {
    return `Yesterday at ${formattedTime}`;
  }

  const formattedDate = date.toLocaleString({
    month: 'short',
    day: 'numeric',
  });

  return `${formattedDate} at ${formattedTime}`;
};

interface TimeRemaining {
  days: number;
  hours: number;
  minutes: number;
}

function getTimeUntil(timestamp: string | Date): TimeRemaining | string {
  const target = timestamp instanceof Date ? DateTime.fromJSDate(timestamp) : DateTime.fromISO(timestamp);

  const now = DateTime.now();

  if (!target.isValid) {
    return 'Invalid date format';
  }

  const diff = target.diff(now, ['days', 'hours', 'minutes']);

  if (diff.milliseconds < 0 || diff.days < 0) {
    return 'This date has already passed';
  }

  return {
    days: Math.floor(diff.days || 0),
    hours: Math.floor(diff.hours || 0) % 24,
    minutes: Math.floor(diff.minutes || 0) % 60,
  };
}

export function formatTimeRemaining(time: string): string {
  const timeRemaining = getTimeUntil(time);
  if (typeof timeRemaining === 'string') {
    return timeRemaining;
  }

  const { days, hours, minutes } = timeRemaining;
  const parts: string[] = [];

  if (days > 0) parts.push(`${days} ${days === 1 ? 'day' : 'days'}`);
  if (hours > 0) parts.push(`${hours} ${hours === 1 ? 'hour' : 'hours'}`);
  if (minutes > 0) parts.push(`${minutes} ${minutes === 1 ? 'minute' : 'minutes'}`);

  if (parts.length === 0) return 'less than a minute';

  return parts.join(', ');
}
