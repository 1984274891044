import { Link } from 'react-router-dom';
import { Tabs, Tab, styled, TabsProps } from '@mui/material';
import React from 'react';

type StyledTabsProps = { children?: React.ReactNode; value: number } & TabsProps;

const StyledTabs = styled((props: StyledTabsProps) => (
  <Tabs
    {...props}
    sx={{ borderBottom: 1, borderColor: 'divider', minHeight: 0 }}
    textColor='primary'
    TabIndicatorProps={{
      sx: { height: 4, display: 'flex', justifyContent: 'center', backgroundColor: 'rhino.600' },
    }}
  />
))(({ theme }) => ({
  '& .MuiTab-root': { minWidth: 0, minHeight: 0 },
  '& .MuiTabs-flexContainer': { gap: theme.spacing(5) },
}));

const CustomTab = ({ tabs, selectedTab, parentPath }: { tabs: { label: string; path: string }[]; selectedTab: number; parentPath: string }) => {
  return (
    <StyledTabs value={selectedTab}>
      {tabs.map((tab, index) => (
        <Tab
          key={tab.path}
          label={tab.label}
          component={Link}
          to={`/${parentPath}/${tab.path}`}
          value={index}
          sx={{ textTransform: 'none', px: 2, py: 1, mb: 1 }}
        />
      ))}
    </StyledTabs>
  );
};

export default CustomTab;
