export const NOTIFICATION_PATH = 'settings/notifications';
export const PAYMENT_LINK_PATH = 'payment-link';
export const PAYMENTS_PATH = 'payments';
export const PAYOUTS_PATH = 'payouts';

export const ACCOUNTS_PATH = 'account';
export const BALANCES_PATH = 'balances';
export const BALANCE_CONVERSION_PATH = 'balance-conversion';

export const ACCOUNT_TABS = [
  { label: 'Balances', path: BALANCES_PATH },
  { label: 'Withdrawal', path: 'withdrawal' },
  { label: 'Balance conversions', path: BALANCE_CONVERSION_PATH },
  { label: 'Account Details', path: 'details' },
];

export const NOTIFICATION_TABS = [
  { label: 'All', path: 'all' },
  { label: 'Payment Link', path: PAYMENT_LINK_PATH },
  { label: 'Payments (2)', path: PAYMENTS_PATH },
  { label: 'Payouts', path: PAYOUTS_PATH },
];
