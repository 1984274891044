import { FC, ReactElement } from 'react';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import { DotLottieReact } from '@lottiefiles/dotlottie-react';

export interface BaseAlertProps {
  title: string;
  message: string;
  lottieUrl: string;
  renderActions?: ({ onDismiss }: { onDismiss: () => void }) => ReactElement;
  open: boolean;
  onClose: () => void;
}

export const BaseAlert: FC<BaseAlertProps> = ({ title, message, lottieUrl, open, renderActions, onClose }) => {
  const handleDismiss = () => {
    onClose();
  };

  return (
    <Dialog open={open} onClose={handleDismiss}>
      <DialogTitle textAlign='center'>{title}</DialogTitle>
      <DialogContent sx={{ alignItems: 'center', justifyContent: 'center', display: 'flex', flexDirection: 'column', gap: 1 }}>
        <Box sx={{ maxWidth: '52px', height: '52px' }}>
          <DotLottieReact src={lottieUrl} loop autoplay />
        </Box>
        <Typography variant='subtitle1' color='neutral.400' textAlign='center'>
          {message}
        </Typography>
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'center', mb: 2 }}>
        {renderActions?.({ onDismiss: handleDismiss }) || (
          <Button variant='contained' onClick={handleDismiss}>
            Okay
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};
