import { FC } from 'react';
import { Button, Stack, Typography } from '@mui/material';

import FilteredCustomers from '../components/customers/FilteredCustomers';
import CreateCustomerModal from '../components/customers/CreateCustomerModal';

const CustomersPage: FC = () => (
  <Stack gap={1}>
    <Stack direction='row' gap={2} alignItems='center' justifyContent='space-between'>
      <Typography component='h6' fontSize={24} fontWeight={500}>
        Customers
      </Typography>

      <CreateCustomerModal>
        <Button variant='contained'>create new customer</Button>
      </CreateCustomerModal>
    </Stack>

    <FilteredCustomers />
  </Stack>
);

export default CustomersPage;
