import { Box, Button, Divider, Stack, Typography } from '@mui/material';

import CheckoutHeader from './CheckoutHeader.tsx';
import BankTransferRow from './BankTransferRow.tsx';

export default function Checkout({ transferDetails }: { transferDetails: { name: string; value: string }[] }) {
  const getTransferDetailsString = (): string => {
    return transferDetails
      .map((e) => {
        return `${e.name}: ${e.value}`;
      })
      .join('\n');
  };

  const handleCopyAll = () => {
    navigator.clipboard.writeText(getTransferDetailsString());
  };
  return (
    <Box sx={{ width: 1, border: '1px solid #e7e7e7', borderRadius: '8px' }}>
      <CheckoutHeader name='Glomo Payments' />
      <Box p='8px 28px'>
        <Typography fontSize={12} fontWeight={500} color='neutral.800'>
          Account Transfer
        </Typography>
      </Box>
      <Box sx={{ bgcolor: 'neutral.50', borderBottomLeftRadius: '8px', borderBottomRightRadius: '8px' }}>
        <Stack direction='row' sx={{ justifyContent: 'flex-end', paddingTop: '8px' }}>
          <Button variant='text' onClick={handleCopyAll}>
            <Typography fontSize={10} color='rhino.800' fontWeight={500}>
              Copy all details
            </Typography>
          </Button>
        </Stack>
        <Stack direction='column'>
          <Box sx={{ bgcolor: 'white', borderRadius: '12px', mx: 4, marginBottom: 4 }}>
            {transferDetails.map((e, index) => (
              <span key={index}>
                <BankTransferRow name={e.name} value={e.value} index={index} />
                {index !== transferDetails.length - 1 && <Divider sx={{ backgroundColor: '#F6F6F6' }} />}
              </span>
            ))}
          </Box>
          <Box
            sx={{
              bgcolor: '#FFFCEB',
              borderBottomLeftRadius: '8px',
              borderBottomRightRadius: '8px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              p: '12px 20px',
            }}>
            <Typography sx={{ fontSize: '12px', textAlign: 'center', fontWeight: 400, color: 'neutral.500' }}>
              Transfer the exact amount with the right reference ID for payment to be successfully reconcilled
            </Typography>
          </Box>
        </Stack>
      </Box>
    </Box>
  );
}
