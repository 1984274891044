import { useQuery } from '@tanstack/react-query';
import { DateTime } from 'luxon';

import apiClient from '../utils/api-client.ts';
import { TListBalanceConversionsResponse } from '../types/BalanceConversionTypes';
import { ConvertKeysToCamelCase, convertToCamelCase } from '../utils/case-converter.ts';

export const useConversions = (search: string, dateFrom: DateTime | undefined, dateTo: DateTime | undefined, page: string, pageSize: string) => {
  const {
    isLoading,
    data: conversionResponse,
    isError,
  } = useQuery({
    queryKey: ['conversions', search, dateFrom, dateTo, page, pageSize],
    queryFn: () => fetchConversions(search, dateFrom, dateTo, page, pageSize),
  });

  return { isLoading, conversionResponse, isError };
};

const fetchConversions = async (search: string, dateFrom: DateTime | undefined, dateTo: DateTime | undefined, page: string, pageSize: string) => {
  const { data } = await apiClient.get('/v1/balances/conversions', {
    search: search,
    date_from: dateFrom,
    date_to: dateTo,
    page: page,
    per_page: pageSize,
  });
  return convertToCamelCase(data) as ConvertKeysToCamelCase<TListBalanceConversionsResponse>;
};
