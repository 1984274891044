import { useQuery } from '@tanstack/react-query';

import { User } from '../types/UserTypes';
import apiClient from '../utils/api-client';

function useAuth() {
  const {
    isLoading,
    data: user,
    isError,
  } = useQuery({
    queryKey: ['user'],
    queryFn: fetchUser,
  });
  return { isLoading, user, isError };
}

const fetchUser = async (): Promise<User> => {
  const { data, error } = await apiClient.get('/v1/user');
  if (error) {
    throw new Error(error.message);
  }
  return data;
};

export default useAuth;
