import { Avatar, Box, Dialog, Divider, IconButton, Stack, Toolbar, Tooltip, Typography } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import { PiBank, PiInfo, PiX } from 'react-icons/pi';
import { Currency } from 'dinero.js';
import { useCallback, useEffect, useState } from 'react';

import CurrencySelect from '../common/CurrencySelect.tsx';
import Checkout from '../common/checkout/Checkout.tsx';
import { getBankAccountForSelfTransfer, getEscrowAccountForSelfTransfer } from '../../repositories/bank-account-repository.ts';
import { addFundsEscrowDetails, addFundsTPVBankDetails } from '../../services/bank-account-service.ts';
import { AddFundsEscrowDetails, AddFundsTPVBankDetails } from '../../types/BankAccountTypes';
import useBusiness from '../../repositories/business-repository.tsx';
import { getBusinessDetails } from '../../services/business-service.ts';
import { Payin } from '../../types/PayinTypes';
import { getPayinForSelfTransfer } from '../../repositories/payins-repository.tsx';
import { maskBankAccountNumber } from '../../utils/bank-account-utils.ts';

export default function AddBalanceModal({ open, handleClose }: { open: boolean; handleClose: () => void }) {
  const { business } = useBusiness();
  const [bankAccount, setBankAccount] = useState<AddFundsTPVBankDetails | undefined>(undefined);
  const [escrowAccount, setEscrowAccount] = useState<AddFundsEscrowDetails | undefined>(undefined);
  const [payin, setPayin] = useState<Payin | undefined>(undefined);
  const transferDetails = [
    { name: 'account name', value: escrowAccount?.holderName || 'Glomopay' },
    { name: 'account number', value: escrowAccount?.bankAccountNumber || '9045 1234 5678 12 ' },
    {
      name: 'Beneficiary address',
      value: escrowAccount?.address || 'GIFT SEZ, GIFT City, Gandhinagar, Gujarat, India, 382355',
    },
    { name: 'SWIFT/IBAN code', value: escrowAccount?.bicPrimary || 'HDFCINBBXXX' },
    { name: 'Reference ID', value: `FBO / ${business?.name} \n REF / ${payin?.reference_id}` },
    { name: 'Purpose code', value: payin?.purpose_code || 'PYR0006' },
  ];

  const fetchBankAccountForCurrency = useCallback(async (currency: Currency | undefined) => {
    try {
      const account = await getBankAccountForSelfTransfer(`${currency}`);
      setBankAccount(addFundsTPVBankDetails(account));
    } catch (e) {
      console.error(e);
    }
  }, []);
  const fetchEscrowForCurrency = useCallback(async (currency: Currency | undefined) => {
    try {
      const account = await getEscrowAccountForSelfTransfer(`${currency}`);
      setEscrowAccount(addFundsEscrowDetails(account));
    } catch (e) {
      console.error(e);
    }
  }, []);

  const fetchPayinForCurrency = useCallback(async (currency: Currency | undefined) => {
    try {
      const payin = await getPayinForSelfTransfer(`${currency}`);
      setPayin(payin);
    } catch (e) {
      console.error(e);
    }
  }, []);

  useEffect(() => {
    if (open && bankAccount == undefined && business != undefined) {
      const defaultCurrency = getBusinessDetails(business).defaultCurrency;
      fetchBankAccountForCurrency(defaultCurrency);
      fetchEscrowForCurrency(defaultCurrency);
      fetchPayinForCurrency(defaultCurrency);
    }
  }, [business, open]);

  return (
    <Dialog maxWidth='md' open={open} onClose={handleClose} sx={{ '& .MuiDialog-paper': { borderRadius: 3 }, minWidth: '780px' }}>
      <Box sx={{ flexGrow: 1, px: 6, py: 7 }}>
        <AppBar position='static' color='transparent' elevation={0}>
          <Toolbar sx={{ paddingLeft: '0 !important' }}>
            <Stack sx={{ flexGrow: 1 }}>
              <Typography fontSize={24} fontWeight={500} fontStyle='normal'>
                Add balance via bank transfer
              </Typography>
              <Typography sx={{ color: 'neutral.400', fontSize: 14 }} fontWeight={400} fontStyle='normal'>
                Login to your bank and initiate a transfer to the below account
              </Typography>
            </Stack>
            <IconButton aria-label='close' size='small' onClick={handleClose}>
              <PiX size={20} />
            </IconButton>
          </Toolbar>
        </AppBar>
      </Box>
      <Divider sx={{ marginLeft: '-24px', marginRight: '-24px' }} />
      <Stack direction='row' sx={{ justifyContent: 'space-around', p: 6 }} gap={12}>
        <Stack direction='column' sx={{ flexGrow: 1, justifyContent: 'flex-start', minWidth: '270px' }} gap={6}>
          <Stack gap={2}>
            <Typography fontSize={18} fontStyle='normal' fontWeight={500}>
              Deposit currency
            </Typography>
            <CurrencySelect
              initialValue='USD'
              onChange={(value) => {
                fetchBankAccountForCurrency(value);
                fetchEscrowForCurrency(value);
                fetchPayinForCurrency(value);
              }}
              currencyLongName
            />
          </Stack>
          <Stack gap={2}>
            <Stack direction='row' sx={{ alignItems: 'center' }}>
              <Typography fontSize={18} fontStyle='normal' fontWeight={500}>
                Pay only via
              </Typography>
              <Tooltip title='Make payments only to this bank account for smooth reconciliation' placement='top-start' arrow>
                <Stack sx={{ padding: 1 }}>
                  <PiInfo size={16} />
                </Stack>
              </Tooltip>
            </Stack>
            {bankAccount && bankAccount?.bankName != '' ? (
              <Box sx={{ p: 3, border: '1px solid #D1D1D1', borderRadius: 3 }}>
                <Stack direction='row' gap={3} alignItems='center'>
                  <Avatar sx={{ width: 32, height: 32, bgcolor: 'rhino.50' }}>
                    <PiBank color='#4958AC' />
                  </Avatar>
                  <Stack direction='column'>
                    <Typography sx={{ color: '#3D3D3D', fontSize: 14, fontWeight: '400' }}>{bankAccount.bankName} A/c no.</Typography>
                    <Typography
                      sx={{
                        color: '#3D3D3D',
                        fontSize: 14,
                      }}>
                      {maskBankAccountNumber(bankAccount.bankAccountNumber)}
                    </Typography>
                  </Stack>
                </Stack>
              </Box>
            ) : (
              <Typography>Please add a withdrawal account for the selected currency</Typography>
            )}
          </Stack>
        </Stack>
        <Stack direction='column' sx={{ flexGrow: 1, alignItems: 'center', maxWidth: '350px' }}>
          {bankAccount && bankAccount?.bankName != '' ? (
            <Checkout transferDetails={transferDetails} />
          ) : (
            <Typography>Please add a withdrawal account for the selected currency</Typography>
          )}
        </Stack>
      </Stack>
    </Dialog>
  );
}
