import { useQuery } from '@tanstack/react-query';

import apiClient from '../utils/api-client.ts';
import { ConvertKeysToCamelCase, convertToCamelCase, convertToSnakeCase } from '../utils/case-converter.ts';
import { TCreateApiKeyResponse, TListApiKeysResponse, TRequestRotationResponse } from '../types/ApiKeyTypes';
import { queryClient } from '../utils/query-client.ts';
import { TPaginationOptions } from '../types/PaginationTypes';
import queryKeys from '../constants/query-keys.ts';

export const useApiKeys = (paginationOptions: TPaginationOptions) => {
  const {
    isLoading,
    data: apiKeyResponse,
    isError,
  } = useQuery({
    queryKey: [queryKeys.API_KEYS, paginationOptions],
    queryFn: () => fetchApiKeys(paginationOptions),
  });

  return { isLoading, apiKeyResponse, isError };
};

const fetchApiKeys = async (paginationOptions: TPaginationOptions) => {
  const { data } = await apiClient.get('/v1/api-keys', convertToSnakeCase({ ...paginationOptions }));
  return convertToCamelCase(data) as ConvertKeysToCamelCase<TListApiKeysResponse>;
};

export const fetchApiKey = async (id: string) => {
  const { data, error } = await apiClient.get(`/v1/api-keys/${id}`, {});
  if (error) throw new Error(error.errorDetails);
  queryClient.invalidateQueries({ queryKey: [queryKeys.API_KEYS] });
  return convertToCamelCase(data) as ConvertKeysToCamelCase<TCreateApiKeyResponse>;
};

export const requestApiKeyRotation = async (id: string, expire_after_in_hours: string) => {
  const { data, error } = await apiClient.post(`/v1/api-keys/${id}/request-rotation`, { expire_after_in_hours });
  if (error) throw new Error(error.errorDetails);

  return convertToCamelCase(data) as ConvertKeysToCamelCase<TRequestRotationResponse>;
};

export const rotateApiKey = async (id: string, otp: string) => {
  const { data, error } = await apiClient.post(`/v1/api-keys/${id}/rotate-key`, { otp });
  if (error) throw new Error(error.errorDetails);

  return convertToCamelCase(data) as ConvertKeysToCamelCase<TCreateApiKeyResponse>;
};
