import { Grid2, Stack, Typography } from '@mui/material';

import { useBalances } from '../repositories/account-repository';
import CurrencyBalanceCard from '../components/account-balance/CurrencyBalanceCard';
import FilteredPayments from '../components/payments/FilteredPayments.tsx';

function Balances() {
  const { isLoading, balances } = useBalances();

  if (isLoading || !balances) {
    return <div>Loading...</div>;
  }

  return (
    <Stack gap={1}>
      <Stack gap={4}>
        <Typography fontSize={24} fontWeight={500}>
          Account Balance
        </Typography>
        <Grid2 container spacing={10}>
          {Object.values(balances.balances || []).map((balance) => (
            <Grid2 size={4} key={balance.available.currency_iso}>
              <CurrencyBalanceCard currency_iso={balance.available.currency_iso} balance={balance.available.cents} />
            </Grid2>
          ))}
        </Grid2>
        <Typography fontSize={24} fontWeight={500} color='black'>
          List of Payments
        </Typography>

        <FilteredPayments />
      </Stack>
    </Stack>
  );
}

export default Balances;
