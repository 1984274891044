import { IconButton, Stack, Typography } from '@mui/material';
import { PiArrowLeft } from 'react-icons/pi';
import { useLocation, useNavigate } from 'react-router-dom';

import theme from '../config/theme.ts';
import useHandleBack from '../hooks/useHandleBack.ts';
import { TWebhookFormData, WebhookForm } from '../components/webhooks/WebhookForm.tsx';
import useAlert from '../hooks/useAlert.ts';
import { useUpsertWebhook } from '../repositories/webhooks-repository.tsx';

function WebhookSetupPage() {
  const { showSnackbar } = useAlert()!;
  const handleBack = useHandleBack('/api-keys-and-webhooks/webhooks');
  const navigate = useNavigate();
  const { mutateAsync: upsertWebhook } = useUpsertWebhook();
  const location = useLocation();
  const webhookData = location.state as TWebhookFormData;
  const handleSubmit = async (data: TWebhookFormData) => {
    try {
      await upsertWebhook(data);
      showSnackbar({ title: 'Webhook is updated', alertType: 'success' });
      navigate('/api-keys-and-webhooks/webhooks');
    } catch (err) {
      showSnackbar({ title: (err as Error).message, alertType: 'failure' });
    }
  };
  return (
    <div>
      <Stack direction='column' gap={5}>
        <Stack direction='row' gap={2} alignItems='center'>
          <IconButton data-testid='back-btn' onClick={handleBack}>
            <PiArrowLeft color={theme.palette.neutral[900]} size={24} />
          </IconButton>
          <Typography color='neutral.900' fontSize={24} fontWeight={500}>
            Webhook Edit
          </Typography>
        </Stack>
        <WebhookForm mode='update' initialData={webhookData} onSubmit={handleSubmit} />
      </Stack>
    </div>
  );
}

export default WebhookSetupPage;
