import { useContext } from 'react';

import AlertContext from '../components/common/alert/alert-context';

const useAlert = () => {
  const val = useContext(AlertContext);
  if (val === undefined) throw new Error('useAlert hook has been used outside its provider');
  return val;
};

export default useAlert;
