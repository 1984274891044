import { GridColDef, GridRenderCellParams, GridValidRowModel } from '@mui/x-data-grid';

import { FilteredPayment, FilteredPaymentDetails } from '../types/PaymentTypes';
import { formatDateTime } from '../utils/datetime-utils';
import StatusChip from '../components/common/StatusChip';

import StatusToChipMapper from './status-to-chip-mapper';

export const generatePaymentDetails = (payments: FilteredPayment[] | undefined): FilteredPaymentDetails[] => {
  if (!payments) {
    return [];
  }
  return payments.map((payment: FilteredPayment) => ({
    id: payment.id,
    createdAt: formatDateTime(payment.created_at),
    amount: payment.amount,
    bankAccount: payment.bank_account,
    status: payment.status,
  }));
};

export const paymentStatusToChipMapper = new StatusToChipMapper({
  success: {
    variant: 'green',
    text: 'success',
  },
  failure: {
    variant: 'red',
    text: 'fail',
  },
  action_required: {
    variant: 'yellow',
    text: 'action required',
  },
});

export const paymentDetailColumns: GridColDef[] = [
  { field: 'id', headerName: 'Payment Id', minWidth: 150, flex: 1 },
  { field: 'createdAt', headerName: 'Created at', width: 150, flex: 1 },
  {
    field: 'amount',
    headerName: 'Amount',
    type: 'custom',
    width: 150,
    flex: 1,
  },
  { field: 'bankAccount', headerName: 'Bank Account', width: 150, flex: 1 },
  {
    field: 'status',
    headerName: 'Status',
    description: 'This column has a value getter and is not sortable.',
    sortable: false,
    width: 150,
    flex: 1,
    renderCell: (params: GridRenderCellParams<GridValidRowModel, string>) => {
      const { text, variant } = paymentStatusToChipMapper.mapStatusToChipInputs(params.value!);
      return <StatusChip variant={variant} text={text} />;
    },
  },
];
