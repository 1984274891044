import React, { createContext, Dispatch, FC, ReactElement, ReactNode, SetStateAction, useContext, useEffect, useState } from 'react';
import { Dialog, IconButton } from '@mui/material';
import { PiX } from 'react-icons/pi';

interface CustomModalProps {
  trigger?: ReactElement;
  children: ReactNode;
  width?: string;
  open?: boolean;
  setOpen?: Dispatch<SetStateAction<boolean>>;
  onClose?: () => void;
}

interface ModalCloseProps {
  children: ReactElement;
}

type TModalContext = {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  handleOpen: () => void;
  handleClose: (event: unknown, reason: string) => void;
};

const CustomModalContext = createContext<null | TModalContext>(null);

/**
 * A customizable modal component with support for controlled and uncontrolled states.
 * - Can be either controlled by the parent via `open` and `setOpen` props.
 * - Or have a trigger element provided via the `trigger` prop.
 */
const CustomModal: FC<CustomModalProps> & { ModalClose: FC<ModalCloseProps> } = ({
  trigger: suppliedTriggerElement,
  children,
  width = '50%',
  open: controlledOpen,
  setOpen: setControlledOpen,
  onClose,
}) => {
  const [open, setOpen] = useState(controlledOpen || false);
  const handleOpen = () => {
    setOpen(true);
    setControlledOpen?.(true);
  };
  const handleClose = (_event: unknown, reason: string) => {
    if (reason === 'backdropClick' || reason === 'escapeKeyDown') return;
    setOpen(false);
    setControlledOpen?.(false);
    onClose?.();
  };

  const handleTriggerClick = () => {
    suppliedTriggerElement?.props.onClick?.();
    handleOpen();
  };

  const trigger = suppliedTriggerElement ? React.cloneElement(suppliedTriggerElement, { onClick: handleTriggerClick }) : null;

  // Sync component's local state with parent's state
  useEffect(() => {
    if (controlledOpen !== undefined) setOpen(controlledOpen);
  }, [controlledOpen]);

  return (
    <CustomModalContext.Provider value={{ open, setOpen, handleClose, handleOpen }}>
      {trigger}

      <Dialog open={open} maxWidth='xl' onClose={handleClose} sx={{ '& .MuiDialog-paper': { width } }}>
        <IconButton
          data-testid='modal-close-button'
          sx={{ position: 'absolute', right: '1rem', top: '.6rem' }}
          onClick={(event) => handleClose(event, 'iconBtnClick')}>
          <PiX />
        </IconButton>

        {children}
      </Dialog>
    </CustomModalContext.Provider>
  );
};

const ModalClose: FC<ModalCloseProps> = ({ children }) => {
  const { handleClose } = useContext(CustomModalContext)!;
  const trigger = React.cloneElement(children, { onClick: handleClose });
  return trigger;
};

CustomModal.ModalClose = ModalClose;

export { CustomModal };
