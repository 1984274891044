import { GridColDef, GridRenderCellParams, GridValidRowModel } from '@mui/x-data-grid';
import Dinero from 'dinero.js';

import StatusChip from '../components/common/StatusChip';
import { formatDateTime } from '../utils/datetime-utils';

import StatusToChipMapper from './status-to-chip-mapper';

export const refundStatusToChipMapper = new StatusToChipMapper({
  under_review: {
    variant: 'yellow',
    text: 'in progress',
  },
  pending: {
    variant: 'yellow',
    text: 'in progress',
  },
  initiated: {
    variant: 'yellow',
    text: 'in progress',
  },
  success: {
    variant: 'green',
    text: 'success',
  },
  failed: {
    variant: 'red',
    text: 'fail',
  },
  action_required: {
    variant: 'yellow',
    text: 'action required',
  },
});

export const refundsListColumns: GridColDef[] = [
  { field: 'id', headerName: 'Refund ID', minWidth: 150, flex: 1 },
  { field: 'createdAt', headerName: 'Created at', minWidth: 150, flex: 1, valueGetter: (val) => formatDateTime(val) },
  {
    field: 'amount',
    headerName: 'Amount',
    minWidth: 150,
    flex: 1,
    valueGetter: (val, row) => `${Dinero({ amount: val, currency: row.currency }).toFormat('0,0.00')} ${row.currency}`,
  },
  { field: 'customerName', headerName: 'Customer', minWidth: 150, flex: 1 },
  {
    field: 'status',
    headerName: 'Status',
    minWidth: 150,
    flex: 1,
    renderCell: (params: GridRenderCellParams<GridValidRowModel, string>) => {
      const { text, variant } = refundStatusToChipMapper.mapStatusToChipInputs(params.value!);
      return <StatusChip variant={variant} text={text} />;
    },
  },
];
