import { FC } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Box, Grid2, IconButton, Stack, Typography, useTheme } from '@mui/material';
import { PiArrowLeft, PiCaretDown, PiCoin, PiEnvelope, PiGlobe, PiIdentificationBadge, PiIdentificationCard, PiLink } from 'react-icons/pi';
import { useParams } from 'react-router-dom';

import IconisedLabelledText from '../components/IconisedLabelledText';
import { formatAmount, getCountryName } from '../utils';
import TrackerTimeline from '../components/common/TrackerTimeline';
import DetailSummaryCard from '../components/common/DetailSummaryCard';
import PaymentAmountCard from '../components/common/PaymentAmountCard';
import { orderStatusToChipMapper } from '../services/order-service';
import useHandleBack from '../hooks/useHandleBack';
import { useOneOrder } from '../repositories/order-repository';

const OrderDetailsPage: FC = () => {
  const { id: orderId } = useParams();
  const { isLoading, error, order } = useOneOrder(orderId!);
  const handleBack = useHandleBack('/payin/orders');
  const theme = useTheme();

  if (!orderId) {
    return <div>No ID provided.</div>;
  }

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error || !order) {
    return <div>Error</div>;
  }

  const chipInputs = orderStatusToChipMapper.mapStatusToChipInputs(order.status);

  return (
    <Stack gap={4}>
      <Stack direction='row' gap={3} sx={{ alignItems: 'center' }}>
        <IconButton sx={{ p: 0 }} onClick={handleBack}>
          <PiArrowLeft color={theme.palette.neutral[900]} size={24} />
        </IconButton>
        <Typography color='neutral.900' fontSize={24} fontWeight={500} fontStyle='normal' mr='auto' component='h1'>
          Order details
        </Typography>
      </Stack>

      <Grid2 container spacing={6} sx={{ mt: 4 }}>
        <Grid2 size={6}>
          <DetailSummaryCard
            chipText={chipInputs.text}
            chipVariant={chipInputs.variant}
            summaryText={`You have successfully received ${formatAmount(order.amount, order.currency)} from ${order.customer.name}`}
          />
        </Grid2>
        <Grid2 size={6}>
          <PaymentAmountCard amount={formatAmount(order.amount, order.currency)} createdAt={order.createdAt} />
        </Grid2>
      </Grid2>
      <Stack
        sx={{
          '& .MuiPaper-root': {
            borderRadius: '12px !important',
            boxShadow: 'none',
            border: '1px solid',
            borderColor: 'neutral.100',
          },
        }}>
        <Accordion defaultExpanded>
          <AccordionSummary
            expandIcon={<PiCaretDown />}
            sx={{
              px: 8,
              height: 66,
              minHeight: 66,
              '&.Mui-expanded': {
                height: 66,
                minHeight: 66,
              },
            }}
            aria-controls='panel1-content'
            id='panel1-header'>
            <Typography sx={{ fontSize: 18, fontWeight: 500, fontStyle: 'normal', color: 'neutral.700' }}>Order tracker</Typography>
          </AccordionSummary>
          <AccordionDetails sx={{ m: 0, p: 0, borderTop: '1px solid #e7e7e7', '& .MuiTimeline-root': { m: 0, p: 8 } }}>
            <TrackerTimeline elements={order.orderTracker} />
          </AccordionDetails>
        </Accordion>
      </Stack>
      <Stack
        sx={{
          '& .MuiPaper-root': {
            borderRadius: '12px !important',
            boxShadow: 'none',
            border: '1px solid',
            borderColor: 'neutral.100',
          },
        }}>
        <Accordion defaultExpanded>
          <AccordionSummary
            expandIcon={<PiCaretDown />}
            sx={{
              px: 8,
              height: 66,
              minHeight: 6,
              '&.Mui-expanded': {
                height: 66,
                minHeight: 66,
              },
            }}
            aria-controls='panel1-content'
            id='panel1-header'>
            <Typography sx={{ fontSize: 18, fontWeight: 500, fontStyle: 'normal', color: 'neutral.700' }}>Order details</Typography>
          </AccordionSummary>
          <AccordionDetails sx={{ m: 0, p: 0, borderTop: '1px solid', borderTopColor: 'neutral.100' }}>
            <Box sx={{ display: 'grid', rowGap: '1.5rem', gridTemplateColumns: 'repeat(3,1fr)', p: '2rem' }}>
              <IconisedLabelledText icon={PiIdentificationCard} variant='detail' label='Order ID' text={order.id} />
              <IconisedLabelledText icon={PiIdentificationCard} variant='detail' label='Customer ID' text={order.customer.id} />
              <IconisedLabelledText icon={PiIdentificationBadge} variant='detail' label='Customer name' text={order.customer.name} />
              <IconisedLabelledText icon={PiEnvelope} variant='detail' label='Email' text={order.customer.email} />
              <IconisedLabelledText icon={PiGlobe} variant='detail' label='Country' text={getCountryName(order.customer.country)} />
              <IconisedLabelledText
                icon={PiCoin}
                variant='detail'
                label={
                  <a href={order.invoice.previewUrl} target='_blank' style={{ color: 'inherit', display: 'flex', alignItems: 'center' }}>
                    <span>Invoice &nbsp;</span>
                    <PiLink />
                  </a>
                }
                text={order.invoice.fileName}
              />
            </Box>
          </AccordionDetails>
        </Accordion>
      </Stack>
    </Stack>
  );
};

export default OrderDetailsPage;
