import { FC } from 'react';
import { timelineOppositeContentClasses } from '@mui/lab';
import Timeline from '@mui/lab/Timeline';

import TrackerElement from './TrackerElement.tsx';

interface TrackerTimelineProps {
  elements: Array<{ text: string; createdAt: string }>;
}

const TrackerTimeline: FC<TrackerTimelineProps> = ({ elements }) => (
  <Timeline
    sx={{
      [`& .${timelineOppositeContentClasses.root}`]: {
        flex: 0,
        p: 0,
      },
      '& .MuiTimelineDot-filled': { background: '#E8DEFF' },
      '& .MuiTimelineContent-root': { mb: 4 },
      '& .MuiTimelineConnector-root': { backgroundColor: '#E8DEFF', width: '1px', my: -4 },
      '& .MuiTimelineItem-root:last-child .MuiTimelineConnector-root': { mb: 4 },
    }}>
    {elements.map((element, index) => (
      <TrackerElement key={index} text={element.text} date={element.createdAt} />
    ))}
  </Timeline>
);

export default TrackerTimeline;
