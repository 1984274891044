import { Avatar } from '@mui/material';

// Accepts the ISO-2 country code and returns the corresponding flag
function CountryAvatar({ country_code, size }: { country_code: string | undefined; size?: number }) {
  // Uses https://www.npmjs.com/package/country-flag-icons recommended method. We can use the module as well, but did not want to add a dependency for this simple use case.
  return (
    <Avatar
      alt={country_code}
      sx={{ width: size, height: size }}
      src={`http://purecatamphetamine.github.io/country-flag-icons/3x2/${country_code}.svg`}
    />
  );
}

export default CountryAvatar;
