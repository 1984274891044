import { createTheme } from '@mui/material';
import '@fontsource/inter/300.css';
import '@fontsource/inter/400.css';
import '@fontsource/inter/500.css';
import '@fontsource/inter/600.css';
import '@fontsource/inter/700.css';

declare module '@mui/material/styles' {
  interface Palette {
    rhino: Record<number, string>;
    neutral: Record<number, string>;
  }

  interface PaletteOptions {
    rhino?: Record<number, string>;
    neutral?: Record<number, string>;
  }
}

const rhinoColors = {
  50: '#F3F5FB',
  100: '#E3E9F6',
  200: '#CED9EF',
  300: '#ACC0E4',
  400: '#84A0D6',
  500: '#6782CA',
  600: '#5368BD',
  700: '#4958AC',
  800: '#40498D',
  900: '#343C6A',
};

const neutralColors = {
  50: '#F6F6F6',
  100: '#E7E7E7',
  200: '#D1D1D1',
  300: '#B0B0B0',
  400: '#888888',
  500: '#6D6D6D',
  600: '#5D5D5D',
  700: '#4F4F4F',
  800: '#454545',
  900: '#3D3D3D',
};
const successColors = {
  50: '#E9FFF4',
  100: '#CBFFE3',
  200: '#99FFCC',
  300: '#5BFAB5',
  400: '#1BEC98',
  500: '#00D482',
  600: '#00AD6B',
  700: '#008A5A',
  800: '#006D48',
  900: '#00593C',
  950: '#003323',
};

const errorColors = {
  50: '#FEF2F2',
  100: '#FFE1E1',
  200: '#FFCCCC',
  300: '#FEA3A3',
  400: '#FB6E6E',
  500: '#F24141',
  600: '#E02222',
  700: '#BC1919',
  800: '#9B1919',
  900: '#811B1B',
  950: '#460909',
};

const warningColors = {
  50: '#FFFCEB',
  700: '#B75906',
};

let theme = createTheme({
  spacing: 4,
  palette: {
    mode: 'light',
    primary: { main: '#40498D' },
    secondary: { main: '#6D6D6D' },
    success: { main: '#00D482' },
    error: { main: '#F24141' },
    warning: { main: '#F9A707' },
  },
  typography: { fontFamily: 'Inter, sans-serif' },
  components: {
    MuiMenu: {
      styleOverrides: {
        paper: {
          borderRadius: '12px',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: { textTransform: 'capitalize', borderRadius: '12px', padding: '.5rem 1rem' },
        outlined: {
          backgroundColor: 'white',
          '&:hover': {
            backgroundColor: 'white',
          },
        },
      },
    },
    MuiButtonBase: {
      styleOverrides: {
        root: {
          '&:focus, &:focus-visible': {
            outline: 'none',
          },
        },
      },
    },
    MuiDialog: {
      styleOverrides: { root: { borderRadius: '12px' }, paper: { borderRadius: '12px' } },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: '12px',
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontSize: '18px',
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          '& .MuiInputBase-root.Mui-disabled': {
            backgroundColor: neutralColors[50],
          },
          '& .MuiOutlinedInput-input.Mui-disabled': {
            WebkitTextFillColor: neutralColors[900],
          },
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        standard: {
          '&.MuiAlert-colorInfo': {
            borderRadius: '12px',
            fontSize: '14px',
            fontWeight: 500,
            color: neutralColors[800],
            background: rhinoColors[50],
            '& .MuiAlert-icon': {
              color: neutralColors[700],
            },
          },
        },
      },
    },
  },
});

theme = createTheme(theme, {
  palette: {
    rhino: rhinoColors,
    neutral: neutralColors,
    success: successColors,
    error: errorColors,
    warning: warningColors,
  },
});

export default theme;
