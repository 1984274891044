import React from 'react';
import { Button } from '@mui/material';

import ConvertBalanceModal from './ConvertBalanceModal';

interface ConvertBalanceButtonProps {
  onSuccess?: () => void;
  children?: (handleOpen: () => void) => React.ReactNode;
  variant?: 'text' | 'contained';
}

const ConvertBalanceButton: React.FC<ConvertBalanceButtonProps> = ({ onSuccess, children, variant = 'contained' }) => {
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <>
      {children ? (
        children(handleOpen)
      ) : (
        <Button variant={`${variant}`} sx={{ textTransform: 'none' }} onClick={handleOpen}>
          Convert balance
        </Button>
      )}
      <ConvertBalanceModal open={open} onClose={handleClose} onSuccess={onSuccess} />
    </>
  );
};

export default ConvertBalanceButton;
