export default {
  REFUNDS_LIST: 'refunds-list',
  REFUND_DETAILS: 'refund-details',

  PAYMENTS: 'payments',

  CUSTOMERS_LIST: 'customers-list',

  API_KEYS: 'api-keys',
  WEBHOOKS: 'webhooks',

  CHECKOUT_CUSTOMISATION: 'checkout-customisation',

  ORDERS_LIST: 'orders-list',
};
