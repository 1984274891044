export const getFileFromUrl = async (imageUrl: string, fileName: string = 'logo'): Promise<File> => {
  const response = await fetch(imageUrl);
  const blob = await response.blob();
  return new File([blob], `${fileName}.${blob.type.split('/').at(-1)}`, { type: blob.type });
};

export const getFilenameFromContentDisposition = (contentDisposition: string): string | undefined => {
  // 1) Try extracting filename*= (RFC 5987/6266)
  let match = contentDisposition.match(/filename\*=UTF-8''([^;]+)/);
  if (match) {
    // Decode in case the filename is URL-encoded
    const fileNameDecoded = decodeURIComponent(match[1]);
    return fileNameDecoded;
  } else {
    // 2) Fallback to filename="<filename>"
    match = contentDisposition.match(/filename="([^"]+)"/);
    if (match) {
      return match[1];
    }
  }
};
