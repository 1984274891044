import { FC } from 'react';
import { Stack, styled, Typography } from '@mui/material';
import { DataGrid, GridColDef, GridRowParams } from '@mui/x-data-grid';
import { GridValidRowModel } from '@mui/x-data-grid/models/gridRows';

import defaultDataGridNoRowsSvg from '../../assets/data-grid-no-rows.svg';
import dataGridErrorSvg from '../../assets/error.svg';
import { pageSizeOptions as defaultPageSizeOptions } from '../../constants/app-constants';
import useMemoizedRowCount from '../../hooks/useMemoizedRowCount.ts';

import CustomPagination from './CustomPagination.tsx';

interface CustomNoRowsOverlayProps {
  imageSrc: string;
  title: string;
  subtitle: string;
}

interface CustomDataGridProps {
  isLoading: boolean;
  isError?: boolean;
  rows: GridValidRowModel[];
  rowCount?: number;
  columns: GridColDef[];
  pageSizeOptions?: number[];
  paginationModel: { pageSize: number; page: number };
  onPaginationChange: (paginationModel: { pageSize: number; page: number }) => void;
  onRowClick?: (id: string) => void;
  noRowsOptions?: Partial<CustomNoRowsOverlayProps>;
}

const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
  border: 0,
  borderRadius: '12px',
  '& .MuiDataGrid-columnHeaderTitle': { fontSize: 16, fontWeight: 500, fontStyle: 'normal' },
  '& .MuiDataGrid-main': { background: '#fff', borderRadius: '12px', border: '1px solid', borderColor: theme.palette.neutral[100] },
  '& .MuiTablePagination-spacer': { display: 'none' },
  '& .MuiTablePagination-displayedRows': { marginLeft: 'auto' },
  '& .MuiDataGrid-columnSeparator': { display: 'none' },
  '& .MuiDataGrid-footerContainer': { background: 'transparent', border: 'none' },
  '& .MuiDataGrid-columnHeaders': { borderRadius: '12px', '--DataGrid-containerBackground': theme.palette.rhino[100] },
  '& .MuiDataGrid-cell:focus, .MuiDataGrid-cell:focus-within': { outline: 'none' },
  '& .MuiTablePagination-input': {
    background: '#fff',
    borderRadius: '4px',
    border: '1px solid',
    borderColor: theme.palette.neutral[100],
    fontWeight: 700,
  },
}));

const CustomDataGrid: FC<CustomDataGridProps> = ({
  isLoading,
  rows,
  rowCount,
  columns,
  pageSizeOptions = defaultPageSizeOptions,
  paginationModel,
  onPaginationChange,
  onRowClick,
  noRowsOptions,
  isError,
}) => {
  const noRowsOverlay = CustomNoRowsOverlay({
    imageSrc: noRowsOptions?.imageSrc || defaultDataGridNoRowsSvg,
    title: noRowsOptions?.title || 'No results found',
    subtitle: noRowsOptions?.subtitle || '',
  });

  const memoizedRowCount = useMemoizedRowCount(rowCount);

  if (isError)
    return (
      <StyledDataGrid
        columns={columns}
        sx={{ minHeight: '30rem' }}
        slots={{
          pagination: null,
          noRowsOverlay: CustomErrorOverlay,
          noResultsOverlay: CustomErrorOverlay,
        }}
      />
    );

  return (
    <StyledDataGrid
      loading={isLoading}
      disableColumnMenu
      disableColumnSorting
      disableRowSelectionOnClick
      disableColumnFilter
      disableColumnSelector
      disableMultipleRowSelection
      disableColumnResize
      pagination
      paginationMode='server'
      slots={{
        pagination: rowCount === 0 ? null : CustomPagination,
        noRowsOverlay,
        noResultsOverlay: noRowsOverlay,
      }}
      onRowClick={(params: GridRowParams) => {
        if (onRowClick) {
          onRowClick(params.row.id);
        }
      }}
      getRowId={(row) => row.id || Math.random().toString()}
      getRowHeight={() => 'auto'}
      rows={rows}
      rowCount={memoizedRowCount}
      columns={columns}
      pageSizeOptions={pageSizeOptions}
      paginationModel={paginationModel}
      onPaginationModelChange={onPaginationChange}
      sx={{
        minHeight: rows.length ? '100%' : '24rem',
        '& .MuiDataGrid-row': {
          cursor: onRowClick ? 'pointer' : 'default',
        },
        '&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell': { py: '8px' },
        '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': { py: '15px' },
        '&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell': { py: '22px' },
      }}
    />
  );
};

export default CustomDataGrid;

const CustomNoRowsOverlay =
  ({ imageSrc, title, subtitle }: CustomNoRowsOverlayProps) =>
  () => (
    <Stack alignItems='center' justifyContent='center' height='100%' p={2}>
      <img src={imageSrc} style={{ width: '6rem' }} />
      <Typography variant='h5' my={5}>
        {title}
      </Typography>
      <Typography>{subtitle}</Typography>
    </Stack>
  );

const CustomErrorOverlay = () => (
  <Stack sx={{ height: '100%', textAlign: 'center', gap: 4, py: 4, justifyContent: 'center' }}>
    <Typography variant='h4'>Something went wrong</Typography>
    <Typography variant='h5'>We ran into an issue. Please try again shortly</Typography>
    <img src={dataGridErrorSvg} alt='ERROR' style={{ height: '50%' }} />
  </Stack>
);
