import { FC, useState } from 'react';
import { Box, Stack } from '@mui/material';

import { useCustomers } from '../../repositories/customer-repository';
import CustomDataGrid from '../common/CustomDataGrid';
import { customersListColumns } from '../../services/customer-service';
import SearchField from '../common/SearchField';
import { redirectToRailsApp } from '../../utils/route-utils';
import DownloadReportButton from '../common/DownloadReportButton';
import { getIncrementedPaginationModel } from '../../utils';

const FilteredCustomers: FC = () => {
  const [search, setSearch] = useState<string>('');
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 5,
    page: 0,
  });

  const { customersResponse, isLoading, isError } = useCustomers({ search }, getIncrementedPaginationModel(paginationModel));

  const handleRowClick = (customerId: string) => {
    // Redirect to customer details page
    redirectToRailsApp(`/v1/customers/${customerId}`);
  };

  const downloadReportUrl = `/v1/reports/customers.csv?search=${search}&page=${paginationModel.page + 1}&page_size=${paginationModel.pageSize}`;

  return (
    <div style={{ marginTop: '2rem' }}>
      <Stack direction='row' gap={3} sx={{ alignItems: 'center' }}>
        <SearchField label='Search by name or email' onChange={(searchValue) => setSearch(searchValue)} />
        <DownloadReportButton url={downloadReportUrl} disabled={!customersResponse?.totalCount} sx={{ ml: 'auto' }} />
      </Stack>

      <Box sx={{ mt: 5, borderRadius: '12px', height: customersResponse?.totalCount ? 'auto' : '24rem' }}>
        <CustomDataGrid
          isLoading={isLoading}
          isError={isError}
          rows={customersResponse?.customers || []}
          rowCount={customersResponse?.totalCount}
          columns={customersListColumns}
          paginationModel={paginationModel}
          onPaginationChange={setPaginationModel}
          onRowClick={handleRowClick}
          noRowsOptions={{ title: 'No customers found', subtitle: 'You do not have any customers' }}
        />
      </Box>
    </div>
  );
};

export default FilteredCustomers;
