import React, { FC } from 'react';
import { Badge, Box, Divider, IconButton, Tooltip, useTheme } from '@mui/material';
import { PiBell } from 'react-icons/pi';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom';

import { useNotificationContext } from '../../hooks/useNotificationsContext';
import { formatDate } from '../../utils/datetime-utils';
import { NOTIFICATION_PATH } from '../../constants/route';
import { useNotifications } from '../../hooks/useNotifications';

const NotificationMenu: FC = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { list = [] } = useNotificationContext();
  const { notificationsResponse } = useNotifications({
    status: 'unseen',
    per_page: 1,
  });
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = !!anchorEl;

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => setAnchorEl(event.currentTarget);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleViewAll = () => {
    navigate(NOTIFICATION_PATH);
  };

  const id = open ? 'simple-popover' : undefined;

  return (
    <div>
      <Tooltip title='Notifications'>
        <Badge badgeContent={notificationsResponse?.pageMeta.count} color='primary'>
          <IconButton
            onClick={handleClick}
            size='small'
            aria-controls={open ? 'account-menu' : undefined}
            aria-haspopup='true'
            aria-expanded={open ? 'true' : undefined}
            sx={{
              height: '2.1rem',
              width: '2.1rem',
              backgroundColor: 'neutral.50',
            }}>
            <PiBell color={theme.palette.neutral[900]} style={{ height: '1.5rem', width: '1.5rem' }} />
          </IconButton>
        </Badge>
      </Tooltip>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        slotProps={{
          paper: {
            sx: {
              width: '400px',
            },
          },
        }}>
        <Box sx={{ p: '1rem', pl: '1.5rem' }}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              gap: '10px',
              alignItems: 'center',
            }}>
            <Typography sx={{ fontSize: '16px', fontWeight: '600', lineHeight: '24px' }}>Notifications</Typography>
            <Box
              sx={{
                width: '20px',
                height: '20px',
                backgroundColor: '#40498D',
                borderRadius: '50%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}>
              <Typography sx={{ color: '#fff', fontWeight: '500', fontSize: '14px' }}>{notificationsResponse?.pageMeta.count}</Typography>
            </Box>
          </Box>
        </Box>

        <Divider sx={{ width: '100%', backgroundColor: '#ededed' }} />

        {list.slice(0, 5).map((notification, index) => (
          <Box
            key={notification.id}
            sx={{
              backgroundColor: notification.status === 'unseen' ? '#F8FAFF' : 'white',
            }}>
            <Box sx={{ p: '1rem', pl: '1.5rem' }}>
              <Typography sx={{ color: '#000', fontSize: '14px', marginBottom: '10px' }}>{notification.message}</Typography>
              <Box sx={{ display: 'flex', flexDirection: 'row', gap: '20px' }}>
                <Typography sx={{ color: '#888888', fontSize: '13px' }}>{formatDate(notification.createdAt)}</Typography>
                <Typography sx={{ color: '#888888', fontSize: '13px' }}>{notification.resourceType}</Typography>
              </Box>
            </Box>
            {index < 4 && <Divider sx={{ width: '100%', backgroundColor: '#ededed' }} />}
          </Box>
        ))}
        <Divider sx={{ width: '100%', backgroundColor: '#ededed' }} />

        <Box sx={{ p: '1rem', pl: '1.5rem', textAlign: 'left' }}>
          <Typography
            sx={{
              color: '#40498D',
              fontSize: '14px',
              fontWeight: '600',
              cursor: 'pointer',
            }}
            onClick={handleViewAll}>
            View All
          </Typography>
        </Box>
      </Popover>
    </div>
  );
};

export default NotificationMenu;
