import { useQuery } from '@tanstack/react-query';

import apiClient from '../utils/api-client';
import { Business } from '../types/BusinessTypes';

function useBusiness() {
  const {
    isLoading,
    data: business,
    isError,
  } = useQuery({
    queryKey: ['business'],
    queryFn: fetchBusiness,
  });
  return { isLoading, business, isError };
}

const fetchBusiness = async (): Promise<Business> => {
  const { data, error } = await apiClient.get('/v1/business');
  if (error) {
    throw new Error(error.message);
  }
  return data;
};

export default useBusiness;
