import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import { z } from 'zod';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  InputAdornment,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { FaChevronDown, FaExternalLinkAlt } from 'react-icons/fa';

import { CustomModal } from '../common/CustomModal';
import FormFieldWrapper from '../common/FormFieldWrapper';
import FileUploader from '../common/FileUploader';
import CountryAvatar from '../common/CountryAvatar';
import { useCreateRefund, useRefundDetails } from '../../repositories/refund-repository';
import ConfirmationDialog from '../common/ConfirmationDialog';
import useAlert from '../../hooks/useAlert';
import * as s3Service from '../../services/s3-service';
import { fileSchema } from '../../utils/validation-schemas';
import { currencyToCountry } from '../../utils';

const formSchema = z.object({
  reason: z.string().nonempty('Reason is required'),
  file: fileSchema,
});

type TFormData = z.infer<typeof formSchema>;

interface RefundModalProps {
  paymentId: string;
  paymentAmount: string;
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  setBalanceModalOpen: Dispatch<SetStateAction<boolean>>;
}

const RefundModal: FC<RefundModalProps> = ({ paymentId, paymentAmount, open: refundModalOpen, setOpen: setRefundModalOpen, setBalanceModalOpen }) => {
  const {
    register,
    handleSubmit: handleHookFormSubmit,
    formState: { isDirty, errors },
    setValue,
  } = useForm<TFormData>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      reason: '',
    },
  });

  // When form is submitted, user's inputs will be copied into this state variable
  // This state variable will then be used by confirm dialog
  const [formData, setFormData] = useState<null | TFormData>(null);

  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);
  const { isLoading, refundDetails, error } = useRefundDetails(paymentId);
  const { mutateAsync: createRefund } = useCreateRefund();
  const { showFailureAlert, showSuccessAlert, showSnackbar } = useAlert()!;

  // Handle insufficient balance thrown by create refund error
  useEffect(() => {
    if (error?.message.toLowerCase().includes('insufficient balance')) {
      setRefundModalOpen(false);
      showFailureAlert({
        title: 'Refund request failed',
        message: 'Unable to process the refund due to insufficient balance. Please add funds to continue',
        renderActions: ({ onDismiss }) => (
          <>
            <Button variant='outlined' onClick={onDismiss}>
              cancel
            </Button>
            <Button
              variant='contained'
              onClick={() => {
                onDismiss();
                setBalanceModalOpen(true);
              }}>
              add balance
            </Button>
          </>
        ),
      });
    }
  }, [error, showFailureAlert, setRefundModalOpen, setBalanceModalOpen]);

  // Transfer the values from hook form to our formData state variable and open the confirmation dialog
  const handleSubmit = (values: TFormData) => {
    setFormData({ ...values });
    setConfirmationDialogOpen(true);
  };

  const handleConfirmationDialogClose = () => {
    setFormData(null);
    setConfirmationDialogOpen(false);
  };

  const handleConfirm = async () => {
    if (formData === null) {
      showSnackbar({ title: 'Something went wrong', alertType: 'failure' });
      return console.error('Confirmation modal was opened before submitting the refund form');
    }

    try {
      // Upload document to AWS and get documentUrl
      await s3Service.s3Upload(refundDetails!.uploadUrl, formData.file);

      // Make API call to create refund endpoint
      await createRefund({ paymentId, documentUrl: refundDetails!.uploadUrl, fileName: formData.file.name, reason: formData.reason });

      // Give feedback
      showSuccessAlert({
        title: 'Refund initiated successfully',
        message: 'Your refund has been successfully initiated',
      });
    } catch (err) {
      showSnackbar({ title: (err as Error).message, alertType: 'failure' });
    } finally {
      handleConfirmationDialogClose();
      setRefundModalOpen(false);
    }
  };

  return (
    <CustomModal open={refundModalOpen} setOpen={setRefundModalOpen}>
      <ConfirmationDialog
        title='confirm refund'
        content={`Are you sure you want to initiate a refund of ${paymentAmount}?`}
        open={confirmationDialogOpen}
        onConfirm={handleConfirm}
        onClose={handleConfirmationDialogClose}
      />

      <DialogTitle textTransform='capitalize' fontSize={24}>
        create refunds
      </DialogTitle>

      <Divider />

      {isLoading && (
        <DialogContent sx={{ height: '50vh' }}>
          <Stack alignItems='center' justifyContent='center' height='100%'>
            <CircularProgress />
          </Stack>
        </DialogContent>
      )}

      {error && <DialogContent sx={{ maxHeight: '50vh' }}>{error.message}</DialogContent>}

      {refundDetails && (
        <form onSubmit={handleHookFormSubmit(handleSubmit)}>
          <DialogContent sx={{ maxHeight: '50vh' }}>
            <div>
              <FormFieldWrapper label='payment method' labelHtmlFor='payment_method'>
                <TextField id='payment_method' fullWidth disabled value='SWIFT' />
              </FormFieldWrapper>

              <Typography variant='subtitle2' color='rhino.800' display='flex' alignItems='center' gap={2} mt={1}>
                <a href='#' style={{ color: 'inherit' }} target='_blank'>
                  See how refunds on SWIFT work
                </a>
                <FaExternalLinkAlt />
              </Typography>
            </div>

            <Stack gap={4} mt={4}>
              <FormFieldWrapper label='reason for refund' labelHtmlFor='reason'>
                <TextField
                  select
                  slotProps={{
                    select: { displayEmpty: true },
                  }}
                  id='reason'
                  fullWidth
                  {...register('reason')}
                  error={!!errors.reason}
                  helperText={errors.reason?.message}
                  defaultValue=''
                  sx={{ textTransform: 'capitalize' }}>
                  <MenuItem disabled value=''>
                    Select a reason
                  </MenuItem>
                  {refundDetails?.reasons.map((reason, idx) => (
                    <MenuItem key={idx} sx={{ textTransform: 'capitalize' }} value={reason}>
                      {reason}
                    </MenuItem>
                  ))}
                </TextField>
              </FormFieldWrapper>

              <FormFieldWrapper label='refund amount' labelHtmlFor='amount'>
                <TextField
                  id='amount'
                  fullWidth
                  value={paymentAmount}
                  disabled
                  slotProps={{
                    input: {
                      startAdornment: (
                        <InputAdornment position='start'>
                          <CountryAvatar country_code={currencyToCountry(paymentAmount.slice(-3))} />
                        </InputAdornment>
                      ),
                    },
                  }}
                />
              </FormFieldWrapper>

              <Accordion
                elevation={0}
                disableGutters
                sx={{
                  backgroundColor: 'neutral.50',
                  p: 2,
                  borderRadius: '12px',
                  border: '1px solid',
                  borderColor: 'neutral.100',
                  '&::before': { display: 'none' },

                  '& .MuiCollapse-root': {
                    maxHeight: '1.2rem',
                  },
                }}
                slotProps={{ heading: { component: 'span' } }}>
                <AccordionSummary
                  expandIcon={<FaChevronDown />}
                  sx={{
                    fontSize: '.9rem',
                    color: 'neutral.900',
                    '&.MuiAccordionSummary-root': {
                      height: '1.2rem',
                      minHeight: '1.2rem',
                      maxHeight: '1.2rem',
                    },
                  }}>
                  Paying with {refundDetails?.payingWith}, includes fees of {refundDetails?.totalFees}
                </AccordionSummary>
                <AccordionDetails sx={{ fontSize: '.8rem', color: 'neutral.500' }}>
                  Fx fee - {refundDetails?.fxFees} &nbsp; Transaction fee - {refundDetails?.txnFees}
                </AccordionDetails>
              </Accordion>

              <FormFieldWrapper label='upload refund document' labelHtmlFor='document'>
                <FileUploader
                  id='documentUrl'
                  accept='application/pdf'
                  error={errors.file?.message as string}
                  hint={
                    <Typography variant='subtitle2' color='neutral.400'>
                      We accept files{' '}
                      <Typography component='span' color='neutral.800'>
                        less than 10MB
                      </Typography>
                      . Please ensure the file is in{' '}
                      <Typography component='span' color='neutral.800'>
                        PDF format
                      </Typography>
                    </Typography>
                  }
                  onChange={(files) => {
                    if (files?.[0]) setValue('file', files[0], { shouldDirty: true, shouldTouch: true, shouldValidate: true });
                    else setValue('file', undefined as unknown as File);
                  }}
                />
              </FormFieldWrapper>
            </Stack>
          </DialogContent>

          <DialogActions>
            <CustomModal.ModalClose>
              <Button autoFocus variant='outlined'>
                cancel
              </Button>
            </CustomModal.ModalClose>

            <Button variant='contained' type='submit' disabled={!isDirty}>
              confirm
            </Button>
          </DialogActions>
        </form>
      )}
    </CustomModal>
  );
};

export default RefundModal;
