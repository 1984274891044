import React, { useState } from 'react';
import { IconButton, Menu, MenuItem } from '@mui/material';
import { PiDotsThree } from 'react-icons/pi';

import { requestApiKeyRotation, rotateApiKey } from '../../repositories/api-key-repository.tsx';
import { queryClient } from '../../utils/query-client.ts';
import queryKeys from '../../constants/query-keys.ts';
import useAlert from '../../hooks/useAlert.ts';
import { OtpVerificationForm } from '../common/otp/OtpVerificationForm.tsx';

import RevealApiKeyModal from './RevealApiKeyModal.tsx';
import { RotateKeyForm } from './RotateKeyForm.tsx';

const TableContextMenu = ({ row }: { row: { id: string; publishable: boolean; key: string; createdAt: string } }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [modalOpen, setModalOpen] = useState(false);
  const open = Boolean(anchorEl);
  const [otpVerificationModalOpen, setOtpVerificationModalOpen] = useState(false);
  const [revealApiKeyModalOpen, setRevealApiKeyModalOpen] = useState(false);
  const [otpReceiver, setOtpReceiver] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [token, setToken] = useState('');
  const { showSnackbar } = useAlert()!;

  const handleContextMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    event.stopPropagation();
  };

  const handleContextMenuClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (action: string) => {
    if (action === 'rotate') {
      setModalOpen(true);
    }
    handleContextMenuClose();
  };

  const handleModalSubmit = async (expiryInHours: string) => {
    setIsLoading(true);
    try {
      const data = await requestApiKeyRotation(row.id, expiryInHours);
      // TODO: where to put this constant?
      if (data.status == 'AWAITING_VERIFICATION') {
        setModalOpen(false);
        setOtpReceiver(data.email);
        setOtpVerificationModalOpen(true);
      }
      setIsLoading(false);
    } catch (e: unknown) {
      showSnackbar({
        title: 'Rotation Failed: ' + (e as { message: string }).message,
        alertType: 'failure',
      });
      setIsLoading(false);
      console.error(e);
    }
  };

  const handleOtpSubmit = async (otp: string) => {
    setIsLoading(true);
    try {
      const data = await rotateApiKey(row.id, otp);

      if (data.key) {
        setToken(data.key);
        setRevealApiKeyModalOpen(true);
        showSnackbar({
          title: 'New ' + (row.publishable ? 'Public' : 'Private') + ' key is generated and the old one will be expired',
          alertType: 'success',
        });
      }

      setOtpVerificationModalOpen(false);
      setIsLoading(false);
    } catch (e: unknown) {
      setIsLoading(false);
      showSnackbar({
        title: 'Verification Failed: ' + (e as { message: string }).message,
        alertType: 'failure',
      });
      console.error(e);
    }
  };

  return (
    <div data-testid='context-menu-container'>
      <IconButton
        data-testid='context-menu-button'
        onClick={handleContextMenuClick}
        size='small'
        aria-controls={open ? 'row-menu' : undefined}
        aria-haspopup='true'
        aria-expanded={open ? 'true' : undefined}>
        <PiDotsThree className='w-4 h-4' />
      </IconButton>
      <Menu
        data-testid='context-menu'
        id='row-menu'
        anchorEl={anchorEl}
        open={open}
        onClose={handleContextMenuClose}
        onClick={(e) => e.stopPropagation()}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}>
        <MenuItem data-testid='rotate-key-option' onClick={() => handleMenuItemClick('rotate')}>
          Rotate Key
        </MenuItem>
      </Menu>
      <RotateKeyForm
        isLoading={isLoading}
        title={row.publishable ? 'Rotate Public Key' : 'Rotate Secret Key'}
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        onSubmit={handleModalSubmit}
      />
      <OtpVerificationForm
        isLoading={isLoading}
        otpReceiver={otpReceiver}
        open={otpVerificationModalOpen}
        onClose={() => setOtpVerificationModalOpen(false)}
        onSubmit={handleOtpSubmit}
      />
      <RevealApiKeyModal
        publishable={row.publishable}
        token={token}
        open={revealApiKeyModalOpen}
        setOpen={setRevealApiKeyModalOpen}
        onClose={() => {
          queryClient.invalidateQueries({ queryKey: [queryKeys.API_KEYS] });
        }}
      />
    </div>
  );
};

export default TableContextMenu;
