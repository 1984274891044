import { useQuery } from '@tanstack/react-query';

import apiClient from '../utils/api-client';
import { ConvertKeysToCamelCase, convertToCamelCase } from '../utils/case-converter';
import { NotificationResponse } from '../types/NotificationTypes';

export interface UseNotificationsParams {
  page?: number;
  per_page?: number;
  status?: 'seen' | 'unseen';
  type?: 'Payment' | 'Payin' | 'Payout';
}

export const useNotifications = (params: UseNotificationsParams = {}) => {
  const {
    isLoading,
    data: notificationsResponse,
    isError,
  } = useQuery({
    queryKey: ['notifications', params],
    queryFn: () => fetchNotifications(params),
  });

  return { isLoading, notificationsResponse, isError };
};

const fetchNotifications = async (params: UseNotificationsParams) => {
  const { data } = await apiClient.get('/v1/notifications', {
    page: params.page ?? 1,
    per_page: params.per_page ?? 10,
    status: params.status || '',
    type: params.type || '',
  });
  return convertToCamelCase(data) as ConvertKeysToCamelCase<NotificationResponse>;
};
