import { FC } from 'react';
import { Alert, Snackbar } from '@mui/material';

import { TAlertType } from '../../../types/AlertTypes';

interface AlertSnackbarProps {
  title: string;
  open: boolean;
  onClose: () => void;
  alertType: TAlertType;
}

const AlertSnackbar: FC<AlertSnackbarProps> = ({ title, alertType, open, onClose }) => (
  <Snackbar
    open={open}
    autoHideDuration={6000}
    onClose={onClose}
    anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
    sx={{ '&.MuiSnackbar-root': { top: '50%' } }}>
    <Alert onClose={onClose} severity={alertType === 'success' ? 'success' : 'error'} variant='filled' sx={{ width: '100%' }}>
      {title}
    </Alert>
  </Snackbar>
);

export default AlertSnackbar;
