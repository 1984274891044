import { Avatar, Stack, Toolbar, Typography } from '@mui/material';
import AppBar from '@mui/material/AppBar';

export default function CheckoutHeader({ name }: { name: string }) {
  return (
    <AppBar position='static' color='primary' elevation={0} sx={{ borderTopLeftRadius: '8px', borderTopRightRadius: '8px' }}>
      <Toolbar>
        <Stack sx={{ p: '16px 12px' }}>
          <Stack direction='row' sx={{ alignItems: 'center', flexGrow: 1 }} gap={4}>
            <Avatar
              sx={{
                bgcolor: 'rhino.100',
                borderRadius: '4px',
                color: 'rhino.900',
                fontSize: '12px',
                fontWeight: '600',
                width: '36px',
                height: '36px',
              }}
              variant='square'>
              {name[0].toUpperCase()}
            </Avatar>
            <Typography>{name}</Typography>
          </Stack>
        </Stack>
      </Toolbar>
    </AppBar>
  );
}
