import { FC } from 'react';
import { Route, BrowserRouter, Routes } from 'react-router-dom';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { ThemeProvider } from '@emotion/react';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';

import './App.css';
import { NotificationProvider } from './context/Notification.tsx';
import Layout from './components/common/Layout';
import { queryClient } from './utils/query-client';
import PrivateRoute from './components/common/PrivateRoute';
import theme from './config/theme';
import RedirectToRailsApp from './components/common/RedirectToRailsApp';
import Balances from './pages/BalancesPage';
import AccountPage from './pages/AccountPage';
import ProfilePage from './pages/ProfilePage.tsx';
import PaymentPage from './pages/PaymentPage.tsx';
import BalanceConversions from './pages/BalanceConversionsPage.tsx';
import CustomersPage from './pages/CustomersPage.tsx';
import RefundsPage from './pages/RefundsPage.tsx';
import AlertProvider from './components/common/alert/AlertContext.tsx';
import RefundDetailsPage from './pages/RefundDetailsPage.tsx';
import SettingsPage from './pages/SettingsPage.tsx';
import ApiKeysPage from './pages/ApiKeysPage.tsx';
import ApiKeysAndWebhooksPage from './pages/ApiKeysAndWebhooksPage.tsx';
import WebhooksPage from './pages/WebhooksPage.tsx';
import WebhookSetupPage from './pages/WebhookSetupPage.tsx';
import WebhookEditPage from './pages/WebhookEditPage.tsx';
import BrandingPage from './pages/BrandingPage.tsx';
import BrandingElementsPage from './pages/BrandingElementsPage.tsx';
import { CheckoutCustomisationProvider } from './components/checkout-customisation/CheckoutCustomisationProvider.tsx';
import Notification from './pages/Notifications';
import NotificationRow from './components/notifications';
import OrdersPage from './pages/OrdersPage.tsx';
import OrderDetailsPage from './pages/OrderDetailsPage.tsx';

const App: FC = () => (
  <QueryClientProvider client={queryClient}>
    <ReactQueryDevtools />
    <ThemeProvider theme={theme}>
      <AlertProvider>
        <NotificationProvider
          queryParams={{
            page: 1,
            per_page: 5,
          }}>
          <LocalizationProvider dateAdapter={AdapterLuxon} adapterLocale='en-gb'>
            <BrowserRouter>
              <Routes>
                <Route element={<PrivateRoute />}>
                  <Route element={<Layout />}>
                    <Route index element={<RedirectToRailsApp path='/' />} />
                    <Route path='account/*' element={<AccountPage />}>
                      <Route path='balances' element={<Balances />} />
                      <Route path='balance-conversion' element={<BalanceConversions />} />
                      <Route path='details' element={<RedirectToRailsApp path='/v1/account/details' />} />
                      <Route path='withdrawal' element={<RedirectToRailsApp path='/v1/account/withdrawal' />} />
                    </Route>
                    <Route path='/api-keys-and-webhooks' element={<ApiKeysAndWebhooksPage />}>
                      <Route path='api-keys' element={<ApiKeysPage />} />
                      <Route path='webhooks' element={<WebhooksPage />} />
                    </Route>
                    <Route path='/payin/customers' element={<CustomersPage />} />
                    <Route path='/payin/orders' element={<OrdersPage />} />
                    <Route path='/orders/:id' element={<OrderDetailsPage />} />
                    <Route path='/payin/refunds' element={<RefundsPage />} />
                    <Route path='payments/:id' element={<PaymentPage />} />
                    <Route path='profile' element={<ProfilePage />} />
                    <Route path='/refunds/:id' element={<RefundDetailsPage />} />
                    <Route path='reports' element={<RedirectToRailsApp path='/v1/reports' />} />
                    <Route path='/settings'>
                      <Route index element={<SettingsPage />} />
                      <Route path='notifications/*' element={<Notification />}>
                        <Route path='all' element={<NotificationRow />} />
                        <Route path='payment-link' element={<NotificationRow resourceType='Payment' />} />
                        <Route path='payments' element={<NotificationRow resourceType='Payin' />} />
                        <Route path='payouts' element={<NotificationRow resourceType='Payout' />} />
                      </Route>
                    </Route>
                    <Route path='webhooks/create' element={<WebhookSetupPage />} />
                    <Route path='webhooks/:id/edit' element={<WebhookEditPage />} />
                    <Route
                      path='/branding'
                      element={
                        <CheckoutCustomisationProvider>
                          <BrandingPage />
                        </CheckoutCustomisationProvider>
                      }>
                      <Route path='elements' element={<BrandingElementsPage />} />
                    </Route>
                  </Route>
                </Route>
              </Routes>
            </BrowserRouter>
          </LocalizationProvider>
        </NotificationProvider>
      </AlertProvider>
    </ThemeProvider>
  </QueryClientProvider>
);

export default App;
