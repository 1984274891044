import { FC } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Stack, Typography } from '@mui/material';
import { PiArrowSquareDownRight, PiCaretDown, PiHash, PiLinkSimple, PiReceipt, PiShuffleThin, PiSquaresFour, PiUserSwitch } from 'react-icons/pi';

import { redirectToRailsApp } from '../../../utils/route-utils';

import NavLinkContainer from './NavLinkContainer';

const PayinNav: FC = () => (
  <Accordion disableGutters square elevation={0} sx={{ '&:before': { display: 'none' }, pl: 2 }}>
    <AccordionSummary expandIcon={<PiCaretDown />}>
      <Stack direction='row' gap={2} alignItems='center'>
        <PiArrowSquareDownRight size={20} />
        <Typography fontSize={14}>Payin</Typography>
      </Stack>
    </AccordionSummary>
    <AccordionDetails sx={{ textTransform: 'capitalize' }}>
      <NavLinkContainer onClick={() => redirectToRailsApp('/v1/payin')}>
        <Stack direction='row' gap={2} alignItems='center'>
          <PiSquaresFour size={20} />
          <Typography>overview</Typography>
        </Stack>
      </NavLinkContainer>
      <NavLinkContainer onClick={() => redirectToRailsApp('/v1/payin/payments')}>
        <Stack direction='row' gap={2} alignItems='center'>
          <PiReceipt size={20} />
          <Typography>payments</Typography>
        </Stack>
      </NavLinkContainer>
      <NavLinkContainer navTo='/payin/orders'>
        <Stack direction='row' gap={2} alignItems='center'>
          <PiHash size={20} />
          <Typography>orders</Typography>
        </Stack>
      </NavLinkContainer>
      <NavLinkContainer navTo='/payin/refunds'>
        <Stack direction='row' gap={2} alignItems='center'>
          <PiShuffleThin size={20} />
          <Typography>refunds</Typography>
        </Stack>
      </NavLinkContainer>
      <NavLinkContainer onClick={() => redirectToRailsApp('/v1/payin/payment_links')}>
        <Stack direction='row' gap={2} alignItems='center'>
          <PiLinkSimple size={20} />
          <Typography>payment links</Typography>
        </Stack>
      </NavLinkContainer>
      <NavLinkContainer navTo='/payin/customers'>
        <Stack direction='row' gap={2} alignItems='center'>
          <PiUserSwitch size={20} />
          <Typography>customers</Typography>
        </Stack>
      </NavLinkContainer>
    </AccordionDetails>
  </Accordion>
);

export default PayinNav;
