import React from 'react';
import Drawer from '@mui/material/Drawer';
import { Stack, Toolbar, Typography } from '@mui/material';
import { PiFile, PiSquaresFour, PiUserCircle } from 'react-icons/pi';
import { IoSettingsOutline } from 'react-icons/io5';

import { redirectToRailsApp } from '../../../utils/route-utils';

import NavLinkContainer from './NavLinkContainer';
import PayinNav from './PayinNav';
import PayoutNav from './PayoutNav';

const drawerWidth = 240;

const LeftNavBar: React.FC = () => {
  return (
    <Drawer
      variant='permanent'
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box' },
      }}>
      <Toolbar />
      <Stack sx={{ overflow: 'auto', gap: 2, p: 2, pt: 10 }}>
        <NavLinkContainer onClick={() => redirectToRailsApp('/')}>
          <Stack direction='row' gap={2} alignItems='center'>
            <PiSquaresFour size={20} />
            <Typography> Home</Typography>
          </Stack>
        </NavLinkContainer>
        <PayinNav />
        <PayoutNav />
        <NavLinkContainer navTo='/reports'>
          <Stack direction='row' gap={2} alignItems='center'>
            <PiFile size={20} color='black' />
            <Typography color='text.primary'>Reports</Typography>
          </Stack>
        </NavLinkContainer>
        <NavLinkContainer navTo='/account/balances'>
          <Stack direction='row' gap={2} alignItems='center'>
            <PiUserCircle size={20} color='black' />
            <Typography color='text.primary'>My Account</Typography>
          </Stack>
        </NavLinkContainer>
        <NavLinkContainer navTo='/settings' otherActiveRoutes={['/api-keys-and-webhooks/api-keys', '/api-keys-and-webhooks/webhooks']}>
          <Stack direction='row' gap={2} alignItems='center'>
            <IoSettingsOutline size={20} color='black' />
            <Typography color='text.primary'>Settings</Typography>
          </Stack>
        </NavLinkContainer>
      </Stack>
    </Drawer>
  );
};

export default LeftNavBar;
