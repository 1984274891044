import AppBar from '@mui/material/AppBar';
import { Stack, Typography } from '@mui/material';

import glomopayLogo from '../../assets/glomopay-logo-black.svg';
import { formatCurrentDateTime } from '../../utils/datetime-utils';

import AccountMenu from './AccountMenu';
import NotificationMenu from './NotificationMenu';

function TopBar() {
  return (
    <AppBar
      position='fixed'
      color='inherit'
      sx={{
        zIndex: (theme) => theme.zIndex.drawer + 1,
        boxShadow: '0px 0px 2px 0px rgba(98, 99, 108, 0.25);',
      }}>
      <Stack
        direction='row'
        py={4}
        px={12}
        gap={3}
        sx={{
          justifyContent: 'space-between',
          alignItems: 'center',
        }}>
        <img src={glomopayLogo} alt='GlomoPay Logo' style={{ height: '1.25rem' }} />
        <Typography ml='auto' fontSize={14} color='textSecondary'>{`Last updated ${formatCurrentDateTime()}`}</Typography>
        <NotificationMenu />
        <AccountMenu />
      </Stack>
    </AppBar>
  );
}
export default TopBar;
