import { FC, ReactNode } from 'react';
import { InputLabel } from '@mui/material';

interface FormFieldWrapperProps {
  children: ReactNode;
  label: ReactNode;
  labelHtmlFor: string;
}

const FormFieldWrapper: FC<FormFieldWrapperProps> = ({ children, labelHtmlFor, label }) => (
  <div>
    <InputLabel htmlFor={labelHtmlFor} sx={{ color: 'neutral.900', mb: 2, textTransform: 'capitalize' }}>
      {label}
    </InputLabel>
    {children}
  </div>
);

export default FormFieldWrapper;
