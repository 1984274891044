import { StatusChipVariant } from '../components/common/StatusChip';

type StatusChipInput = { text: string; variant: StatusChipVariant };
type StatusToChipMap = { [status: string]: StatusChipInput };

class StatusToChipMapper {
  protected map: StatusToChipMap;

  constructor(map: StatusToChipMap) {
    this.map = map;
  }

  mapStatusToChipInputs(status: string) {
    if (!this.map[status]) throw new Error(`Could not find entry in status-to-chip map for status = ${status}`);
    return this.map[status];
  }
}

export default StatusToChipMapper;
