import { FC } from 'react';
import { PaginationItem, Pagination as MuiPagination, TablePaginationProps, Typography } from '@mui/material';
import { gridPageCountSelector, GridPagination, useGridApiContext, useGridSelector } from '@mui/x-data-grid';
import { IoChevronBack, IoChevronForward } from 'react-icons/io5';

const PaginationItemNext: FC = () => (
  <Typography sx={{ display: 'flex', px: '.3rem', alignItems: 'center', gap: 2, color: 'neutral.900' }}>
    <span>Next</span>
    <IoChevronForward />
  </Typography>
);

const PaginationItemPrevious: FC = () => (
  <Typography sx={{ display: 'flex', px: '.3rem', alignItems: 'center', gap: 2, color: 'neutral.900' }}>
    <IoChevronBack />
    <span>Back</span>
  </Typography>
);

function Pagination({ page, onPageChange, className }: Pick<TablePaginationProps, 'page' | 'onPageChange' | 'className'>) {
  const apiRef = useGridApiContext();
  const pageCount = useGridSelector(apiRef, gridPageCountSelector);

  return (
    <MuiPagination
      color='primary'
      variant='outlined'
      shape='rounded'
      className={className}
      count={pageCount}
      page={page + 1}
      onChange={(event: unknown, newPage: number) => {
        onPageChange(event as never, newPage - 1);
      }}
      sx={{
        '&.MuiPagination-root': { marginLeft: 'auto !important' },
        '&.MuiTablePagination-spacer': { display: 'none' },
        '& .MuiPagination-ul': { display: pageCount <= 1 ? 'none' : 'flex' },
      }}
      renderItem={(item) => <PaginationItem {...item} slots={{ next: PaginationItemNext, previous: PaginationItemPrevious }} />}
    />
  );
}

// TODO: Amin Fix prop types
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function CustomPagination(props: any) {
  return (
    <GridPagination
      ActionsComponent={Pagination}
      {...props}
      sx={{
        '&.MuiTablePagination-spacer': { display: 'none' },
        '& .MuiTablePagination-displayedRows': { display: 'none !important' },
      }}
    />
  );
}

export default CustomPagination;
