import { Avatar, Divider, FormControl, MenuItem, Select, SelectChangeEvent, Stack, Typography } from '@mui/material';
import { useState } from 'react';
import { PiCaretDown } from 'react-icons/pi';
import { Currency } from 'dinero.js';

import { currencyToCountry, currencyToCountryLong } from '../../utils';
import { useCurrencies } from '../../repositories/account-repository';

import CountryAvatar from './CountryAvatar';

const renderValue = (value: string, currencyLongName: boolean) => {
  if (!value) {
    return (
      <Stack direction='row' alignItems='center' gap={3}>
        <Avatar sx={{ height: 32, width: 32, bgcolor: 'neutral.50' }} children={<></>} />
        <Divider flexItem sx={{ flexGrow: 1, alignSelf: 'center' }} />
      </Stack>
    );
  }
  return (
    <Stack direction='row' alignItems='center' gap={3}>
      <CountryAvatar country_code={currencyToCountry(value)} size={32} />
      <Typography fontWeight={400} fontSize={14} color='neutral.900'>
        {currencyLongName ? currencyToCountryLong(value) : value}
      </Typography>
    </Stack>
  );
};

function CurrencySelect({
  initialValue,
  onChange,
  currencyLongName = false,
}: {
  initialValue?: Currency;
  onChange?: (currency: Currency | undefined) => void;
  currencyLongName?: boolean;
}) {
  const { isLoading, currencies } = useCurrencies();
  const [currency, setCurrency] = useState<Currency | undefined>(initialValue);

  if (isLoading || !currencies) {
    return <div>Loading...</div>;
  }

  const handleChange = (event: SelectChangeEvent) => {
    console.log('Updating currency in selector');
    const value = event.target.value as Currency | undefined;
    setCurrency(value);
    if (onChange) {
      console.log('Calling parent currency callback');
      onChange(value);
    }
  };

  return (
    <FormControl sx={{ minWidth: 120 }}>
      <Select
        value={currency}
        onChange={handleChange}
        displayEmpty
        sx={{ borderRadius: 3 }}
        slotProps={{ input: { sx: { p: 3 } } }}
        IconComponent={PiCaretDown}
        renderValue={(value: string) => renderValue(value, currencyLongName)}>
        {currencies.map((currency) => (
          <MenuItem key={currency} value={currency}>
            <Stack direction='row' gap={4} alignItems='center'>
              <CountryAvatar country_code={currencyToCountry(currency)} size={32} />
              <Typography fontWeight={400} fontSize={14} color='neutral.900'>
                {currencyLongName ? currencyToCountryLong(currency) : currency}
              </Typography>
            </Stack>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

export default CurrencySelect;
