import React, { useState } from 'react';
import {
  Alert,
  Button,
  Checkbox,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControlLabel,
  MenuItem,
  Select,
  Stack,
  Typography,
} from '@mui/material';

import { CustomModal } from '../common/CustomModal.tsx';
import LoadingButton from '../ui/LoadingButton.tsx';

interface RotateKeyFormProps {
  isLoading: boolean;
  title: string;
  open: boolean;
  onClose: () => void;
  onSubmit: (value: string) => void;
}

export const RotateKeyForm = ({ isLoading, title, open, onClose, onSubmit }: RotateKeyFormProps) => {
  const [value, setValue] = useState('');
  const [checked, setChecked] = useState(true);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    onSubmit(value);
  };

  const handleOnClose = () => {
    setValue('');
    onClose();
  };

  const handleCheckBoxClick = (e: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(e.target.checked);
  };

  return (
    <CustomModal open={open} onClose={handleOnClose}>
      <DialogTitle sx={{ color: 'neutral.900' }} fontSize={24} fontWeight={500}>
        {title}
      </DialogTitle>
      <Divider />
      <DialogContent sx={{ padding: 6 }}>
        <Stack direction='column' gap={7}>
          <Alert severity='info'>Rolling will block this API key and generate a new one</Alert>
          <Stack>
            <Typography>Expiration</Typography>
            <Select value={value} onChange={(e) => setValue(e.target.value)} displayEmpty>
              <MenuItem value='' disabled sx={{ display: 'none' }}>
                Select An Expiration date
              </MenuItem>
              <MenuItem value='0'>Now</MenuItem>
              <MenuItem value='1'>1 Hour</MenuItem>
              <MenuItem value='3'>3 Hours</MenuItem>
              <MenuItem value='24'>24 Hours</MenuItem>
              <MenuItem value='168'>7 Days</MenuItem>
            </Select>
          </Stack>
          <FormControlLabel
            sx={{ width: 'max-content' }}
            control={<Checkbox onChange={handleCheckBoxClick} defaultChecked />}
            label='I confirm rotating the API keys'
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <LoadingButton
          data-testid='rotate-api-key-button'
          variant='contained'
          onClick={handleSubmit}
          autoFocus
          disabled={!value || !checked}
          sx={{ borderRadius: 3, textTransform: 'capitalize', minWidth: '6rem !important' }}
          isLoading={isLoading}>
          Rotate API key
        </LoadingButton>
      </DialogActions>
    </CustomModal>
  );
};
