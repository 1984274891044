import { Outlet, useLocation, Navigate } from 'react-router-dom';
import { Box, Typography, Stack } from '@mui/material';

import ConvertBalanceButton from '../components/account-balance/ConvertBalanceButton';
import AddBalanceButton from '../components/account-balance/AddBalanceButton.tsx';
import Tabs from '../components/common/Tabs';
import { ACCOUNTS_PATH, BALANCES_PATH, BALANCE_CONVERSION_PATH, ACCOUNT_TABS } from '../constants/route';

function AccountPage() {
  const { pathname } = useLocation();
  const currentTab = ACCOUNT_TABS.findIndex((tab) => pathname.includes(`/${ACCOUNTS_PATH}/${tab.path}`));

  if (currentTab === -1) {
    return <Navigate to={ACCOUNT_TABS[0].path} replace />;
  }

  const RenderActionButton = () => {
    switch (ACCOUNT_TABS[currentTab]?.path) {
      case BALANCES_PATH:
        return (
          <div>
            <ConvertBalanceButton variant='text' />
            <AddBalanceButton />
          </div>
        );
      case BALANCE_CONVERSION_PATH:
        return (
          <div>
            <ConvertBalanceButton variant='contained' />
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <Stack gap={1}>
      <Stack direction='row' gap={2} alignItems='center' justifyContent='space-between'>
        <Typography fontSize={24} fontWeight={500}>
          My account
        </Typography>
        <RenderActionButton />
      </Stack>
      <Tabs selectedTab={currentTab} tabs={ACCOUNT_TABS} parentPath={ACCOUNTS_PATH} />
      <Box mt={8}>
        <Outlet />
      </Box>
    </Stack>
  );
}

export default AccountPage;
