import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Stack, Typography, Box } from '@mui/material';
import { DotLottieReact } from '@lottiefiles/dotlottie-react';

interface SuccessDialogProps {
  open: boolean;
  onClose: () => void;
  title?: string;
  message?: string;
}

const SuccessDialog: React.FC<SuccessDialogProps> = ({ open, onClose, title = 'Success', message = 'Operation completed successfully.' }) => (
  <Dialog open={open} onClose={onClose} sx={{ '& .MuiDialog-paper': { borderRadius: 3, minWidth: '60vh' } }}>
    <DialogTitle sx={{ textAlign: 'center', p: 6, pb: 3, fontSize: 24 }}>{title}</DialogTitle>
    <DialogContent>
      <Stack sx={{ gap: 3 }} alignItems='center'>
        <Box sx={{ maxWidth: '52px', height: '52px' }}>
          <DotLottieReact src='https://lottie.host/fdfbd0b0-78a7-4763-a60f-53dcb37bc6e7/SVS3GDoLyt.lottie' loop autoplay />
        </Box>
        <Typography fontSize={16} color='neutral.400'>
          {message}
        </Typography>
      </Stack>
    </DialogContent>
    <DialogActions sx={{ p: 6, pt: 0, justifyContent: 'center' }}>
      <Button variant='contained' onClick={onClose} autoFocus sx={{ borderRadius: 3, textTransform: 'capitalize' }}>
        Okay
      </Button>
    </DialogActions>
  </Dialog>
);

export default SuccessDialog;
