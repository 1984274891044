import { GridColDef } from '@mui/x-data-grid';

import { getCountryName } from '../utils';

export const customersListColumns: GridColDef[] = [
  { field: 'name', headerName: 'Name', minWidth: 150, flex: 1 },
  { field: 'country', headerName: 'Country', minWidth: 150, flex: 1, valueGetter: (val) => getCountryName(val) },
  { field: 'email', headerName: 'E-mail', minWidth: 150, flex: 1 },
  { field: 'customerType', headerName: 'Type', minWidth: 150, flex: 1 },
];
