import { Box, Button, DialogContent, DialogTitle, Divider, Stack, Typography } from '@mui/material';
import { PiCopy } from 'react-icons/pi';
import { Dispatch, SetStateAction } from 'react';

import { CustomModal } from '../common/CustomModal';

interface RevealApiKeyModalProps {
  publishable: boolean;
  token: string;
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  onClose: () => void;
}

const RevealApiKeyModal = ({ publishable, token, open: modalOpen, setOpen: setModalOpen, onClose: onModalClose }: RevealApiKeyModalProps) => {
  const handleCopy = () => {
    navigator.clipboard.writeText(token);
  };

  const handleDownload = () => {
    const blob = new Blob([token], { type: 'text/plain' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'token.txt';
    a.click();
    window.URL.revokeObjectURL(url);
  };

  return (
    <CustomModal open={modalOpen} setOpen={setModalOpen} onClose={onModalClose}>
      <DialogTitle sx={{ color: 'neutral.900' }} fontSize={24} fontWeight={500}>
        {publishable ? 'Public' : 'Reveal live'} key
      </DialogTitle>
      <Divider />
      <DialogContent sx={{ padding: 6 }}>
        <Stack direction='column' gap={7} pb={6}>
          <Typography fontSize={18} fontWeight={400} color='neutral.900'>
            Save and store this new key to a secure place, such as a password manager or secret store.{' '}
            {!publishable && "You won't be able to see it again."}
          </Typography>
          <Stack direction='column'>
            <Stack alignItems='flex-end'>
              <Button variant='text' sx={{ fontSize: 10 }} size='small' onClick={handleCopy} endIcon={<PiCopy />}>
                Copy
              </Button>
            </Stack>
            <Box
              mb={3}
              p={2}
              bgcolor='grey.50'
              sx={{
                wordBreak: 'break-all',
                position: 'relative',
              }}>
              <Typography>{token}</Typography>
            </Box>
          </Stack>
        </Stack>
        <Stack direction='row' spacing={2} justifyContent='flex-end'>
          <CustomModal.ModalClose>
            <Button variant='outlined'>Cancel</Button>
          </CustomModal.ModalClose>
          <Button variant='contained' onClick={handleDownload}>
            Download
          </Button>
        </Stack>
      </DialogContent>
    </CustomModal>
  );
};

export default RevealApiKeyModal;
