import { ReactNode, createContext } from 'react';

import { useNotifications, UseNotificationsParams } from '../hooks/useNotifications';
import { NotificationContextProps } from '../types/NotificationTypes';

const NotificationContext = createContext<NotificationContextProps | undefined>(undefined);

interface NotificationProviderProps {
  children: ReactNode;
  queryParams?: UseNotificationsParams;
}

const NotificationProvider = ({ children, queryParams = {} }: NotificationProviderProps) => {
  const { isLoading, notificationsResponse, isError } = useNotifications(queryParams);

  const contextValue: NotificationContextProps = {
    list: notificationsResponse?.data || [],
    pageMeta: notificationsResponse?.pageMeta,
    isLoading,
    isError,
  };

  return <NotificationContext.Provider value={contextValue}>{children}</NotificationContext.Provider>;
};

export { NotificationContext, NotificationProvider };
