import { GridColDef } from '@mui/x-data-grid';
import { Stack } from '@mui/material';

import { formatDateTime } from '../utils/datetime-utils.ts';
import ContextMenu from '../components/webhooks/ContextMenu.tsx';

const events = ['Payment Link', 'Payment', 'Payout', 'Refund'];

export const webhookListColumns: GridColDef[] = [
  {
    field: 'url',
    headerName: 'URL',
    minWidth: 200,
    flex: 1,
  },
  {
    field: 'events',
    headerName: 'Events',
    minWidth: 200,
    flex: 1,
    renderCell: () => (
      <Stack gap={1}>
        {events.map((event: string) => (
          <li key={event}>{event}</li>
        ))}
      </Stack>
    ),
  },
  {
    field: 'createdAt',
    headerName: 'Created On',
    minWidth: 20,
    flex: 1,
    valueGetter: (val) => formatDateTime(val),
  },
  {
    field: 'actions',
    headerName: '',
    minWidth: 100,
    maxWidth: 100,
    sortable: false,
    filterable: false,
    renderCell: (params) => <ContextMenu row={params.row} />,
  },
];
