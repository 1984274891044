import { FC } from 'react';
import { useParams } from 'react-router-dom';
import { IconButton, Stack, Typography, useTheme, Grid2 as Grid, Accordion, AccordionSummary, AccordionDetails, Box } from '@mui/material';
import { PiArrowLeft, PiCaretDown, PiCoin, PiEnvelope, PiGlobe, PiIdentificationCard, PiLink } from 'react-icons/pi';
import { FaRegMessage } from 'react-icons/fa6';

import { useOneRefund } from '../repositories/refund-repository';
import useHandleBack from '../hooks/useHandleBack';
import DetailSummaryCard from '../components/common/DetailSummaryCard';
import PaymentAmountCard from '../components/common/PaymentAmountCard';
import TrackerTimeline from '../components/common/TrackerTimeline';
import IconisedLabelledText from '../components/IconisedLabelledText';
import { formatAmount, getCountryName } from '../utils';
import { refundStatusToChipMapper } from '../services/refund-service';

const RefundDetailsPage: FC = () => {
  const { id: refundId } = useParams();
  const { isLoading, refund, error } = useOneRefund(refundId!);
  const theme = useTheme();
  const handleBack = useHandleBack('/payin/refunds');

  if (isLoading) return <div>Loading....</div>;

  if (error || !refund) return <div>Error loading this page</div>;

  const formattedRefundAmount = formatAmount(refund.amount, refund.currency);
  const chipInputs = refundStatusToChipMapper.mapStatusToChipInputs(refund.status);

  return (
    <Stack gap={4}>
      <Stack direction='row' gap={1} alignItems='center'>
        <IconButton onClick={handleBack}>
          <PiArrowLeft color={theme.palette.neutral[900]} size={24} />
        </IconButton>
        <Typography variant='h5' color='neutral.900' fontWeight={500} mr='auto'>
          Refund details
        </Typography>

        {/* <Button variant='outlined'>cancel refund (under development)</Button> */}
      </Stack>

      <Grid container spacing={6}>
        <Grid size={6}>
          <DetailSummaryCard
            chipText={chipInputs.text}
            chipVariant={chipInputs.variant}
            summaryText={`You have successfully initiated a refund of ${formattedRefundAmount} from ${refund.currency} account`}
          />
        </Grid>
        <Grid size={6}>
          <PaymentAmountCard amount={formattedRefundAmount} createdAt={refund.createdAt} />
        </Grid>
      </Grid>

      <Accordion
        defaultExpanded
        elevation={0}
        sx={{ borderRadius: '12px !important', border: '1px solid', borderColor: 'neutral.100', '&::before': { display: 'none' } }}>
        <AccordionSummary expandIcon={<PiCaretDown />} sx={{ px: 8 }}>
          <Typography sx={{ fontSize: 18, fontWeight: 500, fontStyle: 'normal', color: 'neutral.700' }}>Payment tracker</Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ m: 0, p: 0, borderTop: '1px solid', borderTopColor: 'neutral.100', '& .MuiTimeline-root': { m: 0, p: 8 } }}>
          <TrackerTimeline elements={refund.trackerData} />
        </AccordionDetails>
      </Accordion>

      <Accordion
        defaultExpanded
        elevation={0}
        sx={{ borderRadius: '12px !important', border: '1px solid', borderColor: 'neutral.100', '&::before': { display: 'none' } }}>
        <AccordionSummary expandIcon={<PiCaretDown />} sx={{ px: 8 }}>
          <Typography sx={{ fontSize: 18, fontWeight: 500, fontStyle: 'normal', color: 'neutral.700' }}>Refund details</Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ m: 0, p: 0, borderTop: '1px solid', borderTopColor: 'neutral.100' }}>
          <Box
            sx={{
              display: 'grid',
              gridTemplateColumns: 'repeat(2, 1fr)',
              rowGap: 4,
              p: 8,
            }}>
            <IconisedLabelledText icon={PiIdentificationCard} variant='detail' label='payment ID' text={refund.paymentId} />
            <IconisedLabelledText icon={PiIdentificationCard} variant='detail' label='refund ID' text={refund.id} />
            <IconisedLabelledText icon={PiCoin} variant='detail' label='mid market rate' text={`${refund.midMarketRate} ${refund.currency}`} />
            <IconisedLabelledText icon={FaRegMessage} variant='detail' label='reason' text={refund.reason} />
            <IconisedLabelledText
              icon={PiCoin}
              variant='detail'
              label='total fees'
              text={formatAmount(Number(refund.txnFees.cents) + Number(refund.fxFees.cents), refund.txnFees.currencyIso)}
            />
            <IconisedLabelledText icon={PiIdentificationCard} variant='detail' label='customer name' text={refund.customer.name} />
            <IconisedLabelledText
              icon={PiCoin}
              variant='detail'
              label='transaction fee'
              text={formatAmount(refund.txnFees.cents, refund.txnFees.currencyIso)}
            />
            <IconisedLabelledText icon={PiIdentificationCard} variant='detail' label='customer ID' text={refund.customerId} />
            <IconisedLabelledText icon={PiCoin} variant='detail' label='fx fee' text={formatAmount(refund.fxFees.cents, refund.fxFees.currencyIso)} />
            <IconisedLabelledText icon={PiEnvelope} variant='detail' label='email' text={refund.customer.email} />
            <IconisedLabelledText icon={PiCoin} variant='detail' label='payment method' text={refund.paymentMethod} />
            <IconisedLabelledText icon={PiGlobe} variant='detail' label='country' text={getCountryName(refund.customer.country)} />
            <IconisedLabelledText
              icon={PiCoin}
              variant='detail'
              label={
                <a href={refund.documentUrl} target='_blank' style={{ color: 'inherit', display: 'flex', alignItems: 'center' }}>
                  <span>Invoice &nbsp;</span>
                  <PiLink />
                </a>
              }
              text='some document name'
            />
          </Box>
        </AccordionDetails>
      </Accordion>
    </Stack>
  );
};

export default RefundDetailsPage;
