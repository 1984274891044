import React, { useState } from 'react';
import { Box, Button, Paper } from '@mui/material';

interface BlurTextProps {
  children: React.ReactNode;
  variant?: 'default' | 'custom' | 'nonActionable';
  buttonLabel?: string;
  onButtonClick?: () => void;
}

const BlurText = ({ children, variant = 'default', buttonLabel = 'Reveal', onButtonClick }: BlurTextProps) => {
  const [isRevealed, setIsRevealed] = useState(false);

  const handleClick = () => {
    if (variant === 'default') {
      setIsRevealed(!isRevealed);
    } else {
      onButtonClick?.();
    }
  };

  return (
    <Box sx={{ p: 2, position: 'relative' }}>
      <Paper
        elevation={0}
        sx={{
          filter: variant === 'default' ? (isRevealed ? 'none' : 'blur(4px)') : 'blur(4px)',
          transition: 'filter 0.3s ease',
          background: 'inherit',
        }}>
        {children}
      </Paper>

      {variant !== 'nonActionable' && (
        <Button
          variant='outlined'
          onClick={handleClick}
          sx={{
            position: 'absolute',
            left: '50%',
            top: '50%',
            transform: 'translate(-50%, -50%)',
            textTransform: 'none',
          }}>
          {buttonLabel}
        </Button>
      )}
    </Box>
  );
};

export default BlurText;
