import { Box } from '@mui/material';
import { Link, useLocation } from 'react-router-dom';

function NavLinkContainer({
  children,
  navTo,
  onClick,
  otherActiveRoutes,
  ...rest
}: {
  children: React.ReactNode;
  navTo?: string;
  onClick?: () => void;
  otherActiveRoutes?: string[];
}) {
  const location = useLocation();
  const allActiveRoutes = [navTo].concat(otherActiveRoutes);
  const isActive = navTo && allActiveRoutes.includes(location.pathname);

  return (
    <Box
      pr={4}
      pl={isActive ? 3 : 4}
      py={3}
      onClick={onClick}
      sx={{
        '&:hover': { cursor: 'pointer' },
        color: isActive ? 'text.primary' : 'inherit',
        borderRadius: 1,
        backgroundColor: isActive ? 'rhino.50' : 'transparent',
        borderLeft: isActive ? '4px solid' : 'none',
        borderColor: 'primary.light',
        boxSizing: 'border-box',
        '& p': { fontSize: '14px' },
      }}
      {...rest}>
      {navTo ? (
        <Link to={navTo} style={{ color: 'inherit' }}>
          {children}
        </Link>
      ) : (
        <>{children}</>
      )}
    </Box>
  );
}

export default NavLinkContainer;
