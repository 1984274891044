/* eslint-disable @typescript-eslint/no-explicit-any */

type CamelToSnakeCase<S extends string> = S extends `${infer T}${infer U}`
  ? `${T extends Lowercase<T> ? T : `_${Lowercase<T>}`}${CamelToSnakeCase<U>}`
  : S;

type SnakeToCamelCase<S extends string> = S extends `${infer T}_${infer U}` ? `${T}${Capitalize<SnakeToCamelCase<U>>}` : S;

export type ConvertKeysToSnakeCase<T> = {
  [K in keyof T as CamelToSnakeCase<string & K>]: T[K] extends Array<infer U>
    ? Array<ConvertKeysToSnakeCase<U>>
    : T[K] extends Record<string, any>
      ? ConvertKeysToSnakeCase<T[K]>
      : T[K];
};

export type ConvertKeysToCamelCase<T> = {
  [K in keyof T as SnakeToCamelCase<string & K>]: T[K] extends Array<infer U>
    ? Array<ConvertKeysToCamelCase<U>>
    : T[K] extends Record<string, any>
      ? ConvertKeysToCamelCase<T[K]>
      : T[K];
};

export function convertToSnakeCase<T extends Record<string, any>>(obj: T): ConvertKeysToSnakeCase<T> {
  const toSnakeCase = (key: string): string => key.replace(/([A-Z])/g, '_$1').toLowerCase();

  const transform = (value: any): any => {
    if (Array.isArray(value)) {
      return value.map(transform);
    } else if (value && typeof value === 'object' && !Array.isArray(value)) {
      return convertToSnakeCase(value);
    }
    return value;
  };

  const result: Record<string, any> = {};

  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      const snakeKey = toSnakeCase(key);
      result[snakeKey] = transform(obj[key]);
    }
  }

  return result as ConvertKeysToSnakeCase<T>;
}

export function convertToCamelCase<T extends Record<string, any>>(obj: T): ConvertKeysToCamelCase<T> {
  const toCamelCase = (key: string): string => key.replace(/_([a-z])/g, (_, letter) => letter.toUpperCase());

  const transform = (value: any): any => {
    if (Array.isArray(value)) {
      return value.map(transform);
    } else if (value && typeof value === 'object' && !Array.isArray(value)) {
      return convertToCamelCase(value);
    }
    return value;
  };

  const result: Record<string, any> = {};

  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      const camelKey = toCamelCase(key);
      result[camelKey] = transform(obj[key]);
    }
  }

  return result as ConvertKeysToCamelCase<T>;
}
