import { useState } from 'react';
import { Box } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';

import CustomDataGrid from '../common/CustomDataGrid'; // adjust the path as needed
import { formatDate } from '../../utils/datetime-utils';
import { useNotifications } from '../../hooks/useNotifications';

function NotificationRow({ resourceType }: { resourceType?: 'Payin' | 'Payout' | 'Payment' | null }) {
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 10,
    page: 0,
  });

  const { isLoading, notificationsResponse, isError } = useNotifications({ type: resourceType as 'Payin' | 'Payout' | 'Payment' });

  const columns: GridColDef[] = [
    {
      field: 'message',
      headerName: 'Notifications',
      flex: 1,
      renderCell: (params) => (
        <Box sx={{ display: 'flex', gap: '15px', alignItems: 'center' }}>
          {params.row.status === 'unseen' && (
            <Box
              sx={{
                width: '10px',
                height: '10px',
                backgroundColor: '#40498D',
                borderRadius: '50%',
              }}
            />
          )}
          {params.value}
        </Box>
      ),
    },
    {
      field: 'createdAt',
      headerName: 'Date',
      flex: 1,
      renderCell: (params) => <Box sx={{ display: 'flex', gap: '15px', alignItems: 'center' }}>{formatDate(params.value)}</Box>,
    },
  ];

  const handlePaginationChange = (newPagination: { pageSize: number; page: number }) => {
    setPaginationModel(newPagination);
  };

  return (
    <CustomDataGrid
      isLoading={isLoading}
      isError={isError}
      rows={notificationsResponse?.data || []}
      rowCount={notificationsResponse?.pageMeta.count}
      columns={columns}
      paginationModel={paginationModel}
      onPaginationChange={handlePaginationChange}
      noRowsOptions={{
        title: 'No notifications found',
        subtitle: 'There are no notifications to display at this time.',
      }}
    />
  );
}

export default NotificationRow;
