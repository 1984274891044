import { useMemo, useRef } from 'react';

/**
 * If the value rowCount becomes undefined during loading, it will reset the page to zero.
 * To avoid this issue, you can memoize the rowCount value to ensure it doesn't change during loading.
 * Refer: https://mui.com/x/react-data-grid/pagination/#index-based-pagination
 */
const useMemoizedRowCount = (totalCountFromApi?: number) => {
  const rowCountRef = useRef(totalCountFromApi || 0);
  const rowCount = useMemo(() => {
    if (totalCountFromApi !== undefined) rowCountRef.current = totalCountFromApi;
    return rowCountRef.current;
  }, [totalCountFromApi]);

  return rowCount;
};

export default useMemoizedRowCount;
