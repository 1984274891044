import { useQuery } from '@tanstack/react-query';

import apiClient from '../utils/api-client.ts';
import { TCountryCodesIso3 } from '../types/CountryTypes';

export const getPayinForSelfTransfer = async (currency: string) => {
  const { data, error } = await apiClient.get('/v1/payins/payin-for-currency', { currency: currency });
  if (error) {
    throw new Error(error.message);
  }

  return data;
};

export function usePayinCountries() {
  const { isLoading, data: countries, isError } = useQuery({ queryKey: ['payin-countries'], queryFn: fetchPayinCountries });
  return { isLoading, countries, isError };
}

const fetchPayinCountries = async (): Promise<TCountryCodesIso3[]> => {
  const { data, error } = await apiClient.get('/v1/payins/permitted-countries');
  if (error) {
    throw new Error(error.message);
  }
  return data;
};
