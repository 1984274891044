import { FC } from 'react';

import { BaseAlert, BaseAlertProps } from './BaseAlert';

type FailureAlertProps = Omit<BaseAlertProps, 'lottieUrl'>;

const FailureAlert: FC<FailureAlertProps> = (props) => (
  <BaseAlert {...props} lottieUrl='https://lottie.host/1ed784be-767e-4441-9835-f095e5079658/4OT0yxr4v5.lottie' />
);

export default FailureAlert;
