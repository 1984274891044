import { createContext, FC, ReactNode, useEffect } from 'react';
import { useForm, UseFormReturn } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';

import {
  fetchCheckoutCustomisationLogoUploadUrl,
  useCheckoutCustomisation,
  useUpsertCheckoutCustomisation,
} from '../../repositories/checkout-customisation-repository';
import { TCheckoutCustomisation } from '../../types/CheckoutCustomisationTypes';
import { checkoutCustomisationFormSchema, TCheckoutCustomisationFormValues } from '../../utils/validation-schemas';
import { dummyCheckout, GLOMOPAY_CHECKOUT_DEFAULT_BG_COLOR } from '../../constants/app-constants';
import useBusiness from '../../repositories/business-repository';
import * as s3Service from '../../services/s3-service';
import useAlert from '../../hooks/useAlert';
import { getFileFromUrl } from '../../utils/file-utils';

export interface ICheckoutCustomisationContext {
  api: {
    isCheckoutCustomisationLoading: boolean;
    checkoutCustomisationApiError: Error | null;
    checkoutCustomisation: TCheckoutCustomisation | undefined;
  };
  form: UseFormReturn<TCheckoutCustomisationFormValues>;
  utils: {
    resetForm: () => void;
  };
  handlers: {
    handleSubmit: (values: TCheckoutCustomisationFormValues) => void;
  };
}

const CheckoutCustomisationContext = createContext<ICheckoutCustomisationContext | undefined>(undefined);

const CheckoutCustomisationProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const { business } = useBusiness();
  const { isLoading, error, checkoutCustomisation } = useCheckoutCustomisation(business?.id);

  const form = useForm<TCheckoutCustomisationFormValues>({
    resolver: zodResolver(checkoutCustomisationFormSchema),
    defaultValues: {
      bgColor: GLOMOPAY_CHECKOUT_DEFAULT_BG_COLOR,
      logo: null,
    },
  });

  const resetForm = async () => {
    form.reset({
      bgColor: checkoutCustomisation?.customisations.bgColor || dummyCheckout.checkoutPersonalisation.bgColor,
      logo: checkoutCustomisation?.customisations.logo ? await getFileFromUrl(checkoutCustomisation?.customisations.logo) : undefined,
    });
  };

  const { showSnackbar } = useAlert()!;

  const { mutateAsync: upsertCheckoutCustomisation } = useUpsertCheckoutCustomisation();

  const handleSubmit = async (values: TCheckoutCustomisationFormValues) => {
    try {
      let logo = checkoutCustomisation?.customisations.logo;

      if (!business) throw new Error('Unable to update checkout customisations because business details are missing');

      if (form.formState.dirtyFields.logo) {
        if (values.logo) {
          const { uploadUrl } = await fetchCheckoutCustomisationLogoUploadUrl();
          await s3Service.s3Upload(uploadUrl, values.logo);
          logo = `https://cdn.glomopay.com/logo/${business.id}/checkout_logo`;
        } else {
          logo = null;
        }
      }

      await upsertCheckoutCustomisation({ bgColor: values.bgColor, logo });
      showSnackbar({ title: 'Checkout branding updated successfully', alertType: 'success' });
      form.reset({ bgColor: values.bgColor, logo: values.logo });
    } catch (error) {
      showSnackbar({ title: (error as Error).message, alertType: 'failure' });
    }
  };

  // Reset the default values of the form once the API data is available
  useEffect(() => {
    (async () => {
      form.setValue('bgColor', checkoutCustomisation?.customisations.bgColor || GLOMOPAY_CHECKOUT_DEFAULT_BG_COLOR);

      if (checkoutCustomisation?.customisations.logo) {
        const file = await getFileFromUrl(checkoutCustomisation.customisations.logo);
        form.setValue('logo', file);
      }
    })();
  }, [checkoutCustomisation?.customisations.logo, checkoutCustomisation?.customisations.bgColor, form]);

  return (
    <CheckoutCustomisationContext.Provider
      value={{
        handlers: {
          handleSubmit,
        },
        utils: {
          resetForm,
        },
        api: {
          isCheckoutCustomisationLoading: isLoading,
          checkoutCustomisationApiError: error,
          checkoutCustomisation: checkoutCustomisation!,
        },
        form,
      }}>
      {children}
    </CheckoutCustomisationContext.Provider>
  );
};

export { CheckoutCustomisationContext, CheckoutCustomisationProvider };
