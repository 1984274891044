import { Dispatch, FC, SetStateAction, useState } from 'react';
import { Button, Tooltip } from '@mui/material';

import RefundModal from './RefundModal';

interface PaymentRefundProps {
  paymentId: string;
  paymentAmount: string;
  setBalanceModalOpen: Dispatch<SetStateAction<boolean>>;
  isPaymentRefundable: boolean;
  unableToRefundReason?: string;
}

const PaymentRefund: FC<PaymentRefundProps> = ({ paymentId, paymentAmount, setBalanceModalOpen, isPaymentRefundable, unableToRefundReason }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <>
      <Tooltip title={unableToRefundReason} arrow>
        <span>
          <Button variant='text' onClick={() => setIsModalOpen(true)} disabled={!isPaymentRefundable}>
            create refund
          </Button>
        </span>
      </Tooltip>

      {isModalOpen && (
        <RefundModal
          paymentId={paymentId}
          paymentAmount={paymentAmount}
          open={isModalOpen}
          setOpen={setIsModalOpen}
          setBalanceModalOpen={setBalanceModalOpen}
        />
      )}
    </>
  );
};

export default PaymentRefund;
