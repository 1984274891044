import { forwardRef } from 'react';
import { Autocomplete, AutocompleteProps, TextField } from '@mui/material';
import { getCountryData, getEmojiFlag, TCountryCode } from 'countries-list';
import countries3to2 from 'countries-list/minimal/countries.3to2.min.json';

import { TCountryCodesIso3 } from '../../types/CountryTypes';

type TCountryAutocompleteOption = {
  value: string;
  label: string;
};

interface CountryAutocompleteProps extends Omit<AutocompleteProps<TCountryAutocompleteOption, false, false, false>, 'options' | 'renderInput'> {
  countries: TCountryCodesIso3[];
  error?: boolean;
  helperText?: string;
}

const getOption = (country_iso3: TCountryCodesIso3): TCountryAutocompleteOption => {
  const iso2_code = countries3to2[country_iso3] as TCountryCode;
  return {
    value: country_iso3,
    label: `${getEmojiFlag(iso2_code)} ${getCountryData(iso2_code).name}`,
  };
};
const CountryAutocomplete = forwardRef<HTMLDivElement, CountryAutocompleteProps>(({ countries, error, helperText, ...rest }, ref) => (
  <Autocomplete
    {...rest}
    ref={ref}
    options={countries.map((country_iso3) => getOption(country_iso3))}
    renderInput={(params) => <TextField {...params} error={error} helperText={helperText} placeholder='Select a country' />}
    autoHighlight
  />
));

export default CountryAutocomplete;
