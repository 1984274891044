import { QueryFunction, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import queryKeys from '../constants/query-keys';
import { convertToCamelCase, convertToSnakeCase } from '../utils/case-converter';
import apiClient from '../utils/api-client';
import { TCheckoutCustomisation } from '../types/CheckoutCustomisationTypes';

export const useCheckoutCustomisation = (businessId?: string) => {
  const { isLoading, data, error } = useQuery({
    queryKey: [queryKeys.CHECKOUT_CUSTOMISATION],
    queryFn: fetchCheckoutCustomisation,
    retry: false,
    enabled: Boolean(businessId),
  });

  if (data && data.customisations.logo) data.customisations.logo = `https://cdn.glomopay.com/logo/${businessId}/checkout_logo`;

  return { isLoading, checkoutCustomisation: data ? convertToCamelCase(data) : undefined, error };
};

export const useUpsertCheckoutCustomisation = () => {
  const queryClient = useQueryClient();

  const { mutateAsync } = useMutation({
    mutationFn: upsertCheckoutCustomisation,
    onSuccess() {
      queryClient.invalidateQueries({ queryKey: [queryKeys.CHECKOUT_CUSTOMISATION] });
    },
  });

  return { mutateAsync };
};

const fetchCheckoutCustomisation: QueryFunction<null | TCheckoutCustomisation> = async () => {
  const { data, error } = await apiClient.get('/v1/checkout/customisation');
  if (error) throw new Error(error.message);
  return data;
};

export const fetchCheckoutCustomisationLogoUploadUrl = async () => {
  const { data, error } = await apiClient.get('/v1/checkout/logo/presigned-url');
  if (error) throw new Error(error.message);
  return convertToCamelCase(data);
};

const upsertCheckoutCustomisation = async ({
  logo,
  bgColor,
  buttonText = 'Pay Now',
}: {
  logo?: string | null;
  bgColor?: string;
  buttonText?: string;
}) => {
  const payload: { [key: string]: unknown } = {};
  if (logo) payload.logo = logo;
  if (bgColor) payload.bgColor = bgColor;
  if (buttonText) payload.buttonText = buttonText;

  const { data, error } = await apiClient.put('/v1/checkout/customisation', convertToSnakeCase(payload));
  if (error) throw new Error(error.message);
  return convertToCamelCase(data);
};
