import { GridColDef, GridRenderCellParams, GridValidRowModel } from '@mui/x-data-grid';
import Dinero from 'dinero.js';

import StatusChip from '../components/common/StatusChip';
import { formatDateTime } from '../utils/datetime-utils';

import StatusToChipMapper from './status-to-chip-mapper';

export const orderStatusTypes = {
  ACTIVE: { value: 'active', text: 'active' },
  PAID: { value: 'paid', text: 'paid' },
  FAILED: { value: 'failed', text: 'fail' },
  EXPIRED: { value: 'expired', text: 'expired' },
};

export const orderStatusToChipMapper = new StatusToChipMapper({
  [orderStatusTypes.ACTIVE.value]: {
    variant: 'blue',
    text: 'active',
  },
  [orderStatusTypes.PAID.value]: {
    variant: 'green',
    text: 'paid',
  },
  [orderStatusTypes.FAILED.value]: {
    variant: 'red',
    text: 'fail',
  },
  [orderStatusTypes.EXPIRED.value]: {
    variant: 'red',
    text: 'expired',
  },
});

export const ordersListColumns: GridColDef[] = [
  { field: 'id', headerName: 'Order ID', minWidth: 150, flex: 1 },
  { field: 'createdAt', headerName: 'Created at', minWidth: 150, flex: 1, valueGetter: (val) => formatDateTime(val) },
  {
    field: 'amount',
    headerName: 'Amount',
    minWidth: 150,
    flex: 1,
    valueGetter: (val, row) => `${Dinero({ amount: val, currency: row.currency }).toFormat('0,0.00')} ${row.currency}`,
  },
  { field: 'customerName', headerName: 'Customer', minWidth: 150, flex: 1 },
  {
    field: 'status',
    headerName: 'Status',
    minWidth: 150,
    flex: 1,
    renderCell: (params: GridRenderCellParams<GridValidRowModel, string>) => {
      const { text, variant } = orderStatusToChipMapper.mapStatusToChipInputs(params.value!);
      return <StatusChip variant={variant} text={text} />;
    },
  },
];
