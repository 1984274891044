import { BankAccount, AddFundsTPVBankDetails, AddFundsEscrowDetails } from '../types/BankAccountTypes';

export const addFundsTPVBankDetails = (bankAccount: BankAccount | undefined): AddFundsTPVBankDetails => {
  if (!bankAccount) {
    return { bankAccountNumber: '', bankName: '' };
  }
  return { bankAccountNumber: bankAccount.account_number, bankName: bankAccount.bank_name };
};

export const addFundsEscrowDetails = (bankAccount: BankAccount | undefined): AddFundsEscrowDetails => {
  if (!bankAccount) {
    return { bankAccountNumber: '', holderName: '', bicPrimary: '', bicSecondary: '', address: '' };
  }
  return {
    holderName: bankAccount.holder_name,
    bankAccountNumber: bankAccount.account_number,
    bicPrimary: bankAccount.bic_primary,
    bicSecondary: bankAccount.bic_secondary,
    address: bankAccount.address,
  };
};
