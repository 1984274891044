import { useRef, useCallback } from 'react';

function useDebounce<T extends (...args: any[]) => unknown>(func: T, delay?: number) {
  const timeoutRef = useRef<number | undefined>();

  const debouncedFunction = useCallback(
    (...args: Parameters<T>) => {
      if (timeoutRef.current !== undefined) {
        clearTimeout(timeoutRef.current);
      }
      timeoutRef.current = window.setTimeout(() => {
        func(...args);
      }, delay || 300);
    },
    [func, delay],
  );

  return debouncedFunction;
}

export default useDebounce;
