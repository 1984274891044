import React, { FC, ReactNode } from 'react';
import { IconType } from 'react-icons';
import { Avatar, Stack, Typography } from '@mui/material';

interface IconisedLabelledTextProps {
  icon: IconType;
  label: ReactNode;
  text: string;
  variant?: 'detail' | 'normal';
}

const IconisedLabelledText: FC<IconisedLabelledTextProps> = ({ icon, label, text, variant }) => {
  const labelColor = variant === 'detail' ? 'neutral.500' : 'neutral.800';
  const textColor = variant === 'detail' ? 'neutral.500' : 'neutral.900';

  return (
    <div>
      <Stack direction='row' gap={6}>
        <Stack direction='column' gap={2}>
          <Avatar sx={{ bgcolor: '#E8DEFF', width: 28, height: 28 }}>{React.createElement(icon, { size: 18, color: '#6D6D6D' })}</Avatar>
        </Stack>
        <Stack direction='column' gap={1}>
          <Stack direction='row' alignItems='center'>
            <Typography fontSize={16} fontWeight={500} color={labelColor} sx={{ '&:first-letter': { textTransform: 'uppercase' } }}>
              {label}
            </Typography>
          </Stack>
          <Stack direction='row' alignItems='center'>
            <Typography fontSize={16} fontWeight={400} color={textColor}>
              {text}
            </Typography>
          </Stack>
        </Stack>
      </Stack>
    </div>
  );
};

export default IconisedLabelledText;
