import React, { useState } from 'react';
import { Select, MenuItem, FormControl, styled, SelectChangeEvent } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DateTime } from 'luxon';
import { IoChevronDown } from 'react-icons/io5';

type DateRangeValue = '' | 'today' | '7days' | '30days' | '90days' | 'custom';

const StyledFormControl = styled(FormControl)({
  '& .MuiOutlinedInput-root': {
    height: '36px',
    outerWidth: '120px',
    borderRadius: '8px',
    fontSize: '14px',
    color: '#888',
    backgroundColor: '#FFF',
    '& .MuiOutlinedInput-notchedOutline': {
      border: '1px solid #D1D1D1',
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
      border: '1px solid #D1D1D1',
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      border: '1px solid #D1D1D1',
    },
  },
  '& .MuiSelect-select': {
    padding: '8px 12px',
  },
});

const StyledDatePicker = styled(DatePicker)({
  '& .MuiOutlinedInput-root': {
    height: '36px',
    borderRadius: '8px',
    backgroundColor: '#FFF',
    '& .MuiOutlinedInput-notchedOutline': {
      border: '1px solid #D1D1D1',
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
      border: '1px solid #D1D1D1',
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      border: '1px solid #D1D1D1',
    },
  },
  '& .MuiOutlinedInput-input': {
    padding: '8px 12px',
  },
});

const StyledMenuItem = styled(MenuItem)({
  padding: '8px 12px',
});

interface DateRangeSelectProps {
  onDateRangeChange?: (dateFrom: DateTime | undefined, dateTo: DateTime | undefined) => void;
}

const DateRangeSelect: React.FC<DateRangeSelectProps> = ({ onDateRangeChange }) => {
  const [dateRange, setDateRange] = useState<DateRangeValue>('');
  const [dateFrom, setDateFrom] = useState<DateTime | undefined>(undefined);
  const [dateTo, setDateTo] = useState<DateTime | undefined>(undefined);
  const [showDatePickers, setShowDatePickers] = useState<boolean>(false);

  const handleRangeChange = (event: SelectChangeEvent<DateRangeValue>) => {
    const value = event.target.value as DateRangeValue;
    setDateRange(value);

    let dateFromValue = DateTime.now().startOf('day');
    const dateToValue = DateTime.now().endOf('day');

    switch (value) {
      case '7days': {
        dateFromValue = dateFromValue.minus({ days: 7 });
        break;
      }
      case '30days': {
        dateFromValue = dateFromValue.minus({ days: 30 });
        break;
      }
      case '90days': {
        dateFromValue = dateFromValue.minus({ days: 90 });
        break;
      }
      default:
        break;
    }

    if (value !== 'custom') {
      setShowDatePickers(false);
      setDateFrom(dateFromValue);
      setDateTo(dateToValue);
      onDateRangeChange?.(dateFromValue.toUTC(), dateToValue.toUTC());
      return;
    }

    setDateFrom(undefined);
    setDateTo(undefined);
    setShowDatePickers(true);
  };

  const handleFromDateChange = (newValue: DateTime | null) => {
    if (newValue) {
      const dateFromValue = newValue.startOf('day');
      setDateFrom(dateFromValue);
      if (dateFromValue && dateTo) {
        onDateRangeChange?.(dateFromValue, dateTo);
      }
    }
  };

  const handleToDateChange = (newValue: DateTime | null) => {
    if (newValue) {
      const dateToValue = newValue.endOf('day');
      setDateTo(dateToValue);
      if (dateFrom && dateToValue) {
        onDateRangeChange?.(dateFrom, dateToValue);
      }
    }
  };

  return (
    <div style={{ display: 'flex', gap: '8px', alignItems: 'center' }} data-testid='date-range-select'>
      <StyledFormControl size='small'>
        <Select<DateRangeValue>
          value={dateRange}
          onChange={handleRangeChange}
          displayEmpty
          IconComponent={IoChevronDown}
          MenuProps={{
            PaperProps: {
              sx: {
                marginTop: '8px',
                marginLeft: '24px',
                borderRadius: '8px',
                border: '1px solid #D1D1D1',
                '& .MuiMenuItem-root': {
                  padding: '8px 12px',
                },
                '& li': {
                  fontSize: '14px',
                },
              },
            },
          }}>
          <StyledMenuItem value='' disabled sx={{ display: 'none' }}>
            Date
          </StyledMenuItem>
          <StyledMenuItem value='today'>Today</StyledMenuItem>
          <StyledMenuItem value='7days'>Last 7 Days</StyledMenuItem>
          <StyledMenuItem value='30days'>Last 30 Days</StyledMenuItem>
          <StyledMenuItem value='90days'>Last 90 Days</StyledMenuItem>
          <StyledMenuItem value='custom'>Custom</StyledMenuItem>
        </Select>
      </StyledFormControl>

      {showDatePickers && (
        <>
          <StyledDatePicker
            onChange={handleFromDateChange}
            slotProps={{
              textField: {
                placeholder: 'From date',
                size: 'small',
              },
            }}
          />
          <StyledDatePicker
            onChange={handleToDateChange}
            slotProps={{
              textField: {
                placeholder: 'To date',
                size: 'small',
              },
            }}
          />
        </>
      )}
    </div>
  );
};

export default DateRangeSelect;
