import { Card, CardContent, Stack, Typography } from '@mui/material';
import Dinero, { Currency } from 'dinero.js';

import { currencyToCountry, getCurrencyName } from '../../utils';
import CountryAvatar from '../common/CountryAvatar';

interface CurrencyBalanceCardProps {
  currency_iso: Currency;
  balance: number;
}

function CurrencyBalanceCard({ currency_iso, balance }: CurrencyBalanceCardProps) {
  const dinero = Dinero({ amount: balance, currency: currency_iso });
  const currencyName = getCurrencyName(currency_iso) || currency_iso;

  return (
    <Card variant='outlined' sx={{ borderRadius: 3 }}>
      <CardContent style={{ paddingBottom: '1rem' }}>
        <Stack gap={4}>
          <Stack direction='row' gap={7} alignItems='center' justifyContent='space-between'>
            <Typography fontSize={18} fontWeight={500}>
              {currencyName}
            </Typography>
            <CountryAvatar country_code={currencyToCountry(currency_iso)} />
          </Stack>
          <Typography fontSize={24} fontWeight={700}>
            {`${dinero.toFormat('0,0.00')} ${currency_iso.toUpperCase()}`}
          </Typography>
        </Stack>
      </CardContent>
    </Card>
  );
}

export default CurrencyBalanceCard;
