import { FC, ReactNode, Reducer, useReducer } from 'react';

import { TAlertAction, TAlertState, TShowAlertParams } from '../../../types/AlertTypes';

import SuccessAlert from './SuccessAlert';
import FailureAlert from './FailureAlert';
import AlertContext from './alert-context';
import AlertSnackbar from './AlertSnackbar';

const initialState: TAlertState = {
  open: true,
  alertType: null,
  title: '',
  message: '',
  dialog: true,
};

const reducer: Reducer<TAlertState, TAlertAction> = (_previousState, action: TAlertAction) => {
  switch (action.type) {
    case 'showSuccessAlert':
      return { open: true, alertType: 'success', dialog: true, ...action.payload };
    case 'showFailureAlert':
      return { open: true, alertType: 'failure', dialog: true, ...action.payload };
    case 'showSnackbar':
      return { open: true, title: action.payload.title, alertType: action.payload.alertType, dialog: false };
    case 'closeAlert':
      return { open: false, alertType: null, title: '', message: '', dialog: true };
    default:
      throw new Error('Invalid action.type for Alert Reducer');
  }
};

const AlertProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [state, dispatch] = useReducer<Reducer<TAlertState, TAlertAction>>(reducer, initialState);

  const showSuccessAlert = (params: TShowAlertParams) =>
    dispatch({
      type: 'showSuccessAlert',
      payload: params,
    });

  const showFailureAlert = (params: TShowAlertParams) =>
    dispatch({
      type: 'showFailureAlert',
      payload: params,
    });

  const showSnackbar = (params: { title: string; alertType: 'success' | 'failure' }) =>
    dispatch({
      type: 'showSnackbar',
      payload: params,
    });

  const handleAlertClose = () => dispatch({ type: 'closeAlert' });

  return (
    <AlertContext.Provider value={{ state, showSuccessAlert, showFailureAlert, showSnackbar }}>
      {children}

      {state.open && state.alertType === 'success' && state.dialog && (
        <SuccessAlert open={state.open} onClose={handleAlertClose} title={state.title} message={state.message!} renderActions={state.renderActions} />
      )}

      {state.open && state.alertType === 'failure' && state.dialog && (
        <FailureAlert open={state.open} onClose={handleAlertClose} title={state.title} message={state.message!} renderActions={state.renderActions} />
      )}

      {state.open && !state.dialog && <AlertSnackbar open={state.open} onClose={handleAlertClose} alertType={state.alertType!} title={state.title} />}
    </AlertContext.Provider>
  );
};

export default AlertProvider;
