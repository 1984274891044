import { useMutation, useQuery } from '@tanstack/react-query';

import apiClient from '../utils/api-client.ts';
import { ConvertKeysToCamelCase, convertToCamelCase } from '../utils/case-converter.ts';
import queryKeys from '../constants/query-keys.ts';
import { FilteredWebhooksResponse } from '../types/WebhookTypes';

export const useWebhooks = () => {
  const { isLoading, data, error } = useQuery({
    queryKey: [queryKeys.WEBHOOKS],
    queryFn: fetchWebhooks,
  });

  return { isLoading, webhooksResponse: data, error };
};

const fetchWebhooks = async () => {
  const { data, error } = await apiClient.get('/v1/webhooks');
  if (error) throw new Error(error.errorDetails);
  return convertToCamelCase(data) as ConvertKeysToCamelCase<FilteredWebhooksResponse>;
};

export const useUpsertWebhook = () => {
  const { mutateAsync } = useMutation({
    mutationFn: upsertWebhook,
  });

  return { mutateAsync };
};
const upsertWebhook = async ({ url, secret }: { url: string; secret: string }) => {
  const { data, error } = await apiClient.post('/v1/webhooks', { url, secret });
  if (error) throw new Error(error.errorDetails);
  return convertToCamelCase(data);
};
