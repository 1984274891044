import { isEmpty } from 'lodash';

import { User, UserProfileDetails } from '../types/UserTypes';

export const userInitial = (user?: User) => {
  if (!user) {
    return '';
  }
  if (!isEmpty(user.first_name)) {
    return user.first_name.charAt(0).toUpperCase();
  }
  return user.email.charAt(0).toUpperCase();
};

export const userProfileDetails = (user?: User): UserProfileDetails => {
  if (!user) {
    return { firstName: '', lastName: '', email: '', phone: '', createdAt: '' };
  }
  return { firstName: user.first_name, lastName: user.last_name, email: user.email, phone: user.phone, createdAt: user.created_at };
};
