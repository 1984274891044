import { InputAdornment, OutlinedInput, FormControl, InputLabel } from '@mui/material';
import { styled } from '@mui/material/styles';
import { PiMagnifyingGlass } from 'react-icons/pi';
import { useEffect, useRef } from 'react';

const StyledFormControl = styled(FormControl)({
  '& .MuiOutlinedInput-notchedOutline': {
    top: 0,
    '& legend': {
      display: 'none',
    },
  },
  '& .MuiOutlinedInput-root': {
    borderRadius: '8px',
    height: '36px',
    backgroundColor: '#FFF',
    '& .MuiOutlinedInput-notchedOutline': {
      border: '1px solid #D1D1D1',
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
      border: '1px solid #D1D1D1',
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      border: '1px solid #D1D1D1',
    },
  },
  // Label positioning
  '& .MuiInputLabel-root': {
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    marginLeft: '40px',
    fontSize: '14px',
    color: '#6B7280',
    pointerEvents: 'none',
    '&.Mui-focused': {
      display: 'none',
    },
    '&.MuiInputLabel-shrink': {
      display: 'none',
    },
    '&.MuiFormLabel-filled': {
      display: 'none',
    },
  },
  '& .MuiOutlinedInput-input': {
    padding: '8px',
  },
  '& .MuiInputAdornment-root': {
    marginRight: '2px',
  },
});

const SearchField = ({ label, onChange }: { label: string; onChange: (value: string) => void }) => {
  const timerRef = useRef<ReturnType<typeof setTimeout> | undefined>(undefined);
  useEffect(() => {
    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
    };
  });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    clearTimeout(timerRef.current);
    timerRef.current = setTimeout(() => {
      onChange(event.target.value);
    }, 400);
  };
  return (
    <StyledFormControl variant='outlined'>
      <InputLabel htmlFor='search-field' shrink={false}>
        {label}
      </InputLabel>
      <OutlinedInput
        onChange={handleChange}
        id='search-field'
        startAdornment={
          <InputAdornment position='start'>
            <PiMagnifyingGlass />
          </InputAdornment>
        }
        label='Enter payment id'
      />
    </StyledFormControl>
  );
};

export default SearchField;
