import { FC } from 'react';
import { Stack, Typography } from '@mui/material';

import FilteredRefunds from '../components/refund/FilteredRefunds';

const RefundsPage: FC = () => (
  <Stack gap={1}>
    <Typography fontSize={24} fontWeight={500} textTransform='capitalize'>
      refunds
    </Typography>

    <FilteredRefunds />
  </Stack>
);

export default RefundsPage;
