import { useContext } from 'react';

import { CheckoutCustomisationContext } from './CheckoutCustomisationProvider';

const useCheckoutCustomisationContext = () => {
  const context = useContext(CheckoutCustomisationContext);
  if (context === undefined) throw new Error('useCheckoutCustomisationContext must be used within a CheckoutCustomisationProvider');
  return context;
};

export default useCheckoutCustomisationContext;
