import Grid from '@mui/material/Grid2';
import { IconButton, Tooltip, Typography } from '@mui/material';
import { PiCopy } from 'react-icons/pi';
import { useState } from 'react';

import { maskLastThreeCharacters } from '../../../utils/bank-account-utils.ts';

export default function BankTransferRow(transferDetails: { name: string; value: string; index: number }) {
  const [tooltipText, setTooltipText] = useState<Record<number, string>>({});
  const handleCopy = (id: number) => {
    setTooltipText((prev) => ({ ...prev, [id]: 'Copied' }));
    navigator.clipboard.writeText(transferDetails.value);
    setTimeout(() => {
      setTooltipText((prev) => ({ ...prev, [id]: 'Copy' }));
    }, 2000);
  };
  const handleTooltipClose = (id: number) => {
    setTooltipText((prev) => ({ ...prev, [id]: 'Copy' }));
  };
  return (
    <Grid container spacing={0} sx={{ p: 2, '&:hover .copy-icon': { opacity: 1, visibility: 'visible' } }}>
      <Grid size={6}>
        <Typography sx={{ textTransform: 'capitalize' }} fontSize={12}>
          {transferDetails.name}
        </Typography>
        {transferDetails.name === 'Reference ID' && (
          <Typography sx={{ fontStyle: 'italic' }} fontSize={10} fontStyle='italic' fontWeight={400}>
            (mandatory)
          </Typography>
        )}
      </Grid>
      <Grid size={5} sx={{ alignContent: 'center' }}>
        <Typography
          fontSize={12}
          sx={{
            whiteSpace: 'pre-line',
            textDecoration: `${transferDetails.name === 'Reference ID' ? 'underline' : 'none'}`,
          }}>
          {transferDetails.name === 'SWIFT/IBAN code' || transferDetails.name === 'IFSC number'
            ? maskLastThreeCharacters(transferDetails.value)
            : transferDetails.value}
        </Typography>
      </Grid>
      <Tooltip title={tooltipText[transferDetails.index] || 'Copy'} onClose={() => handleTooltipClose(transferDetails.index)} placement='top' arrow>
        <IconButton
          className='copy-icon'
          sx={{
            p: 0,
            width: 20,
            height: 20,
            opacity: 0,
            visibility: 'hidden',
            transition: 'opacity 0.3s ease, visibility 0.3s ease',
          }}
          size='small'
          onClick={() => handleCopy(transferDetails.index)}>
          <PiCopy />
        </IconButton>
      </Tooltip>
    </Grid>
  );
}
