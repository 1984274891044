import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Accordion, AccordionDetails, AccordionSummary, Grid2, IconButton, Stack, Typography, useTheme } from '@mui/material';
import {
  PiArrowLeft,
  PiCalendar,
  PiCaretDown,
  PiCoin,
  PiCoins,
  PiEnvelope,
  PiGlobe,
  PiIdentificationBadge,
  PiIdentificationCard,
} from 'react-icons/pi';

import { usePayment } from '../repositories/payments-repository';
import IconisedLabelledText from '../components/IconisedLabelledText';
import { formatDateTime } from '../utils/datetime-utils.ts';
import { getCountryName } from '../utils';
import TrackerTimeline from '../components/common/TrackerTimeline';
import DetailSummaryCard from '../components/common/DetailSummaryCard';
import PaymentAmountCard from '../components/common/PaymentAmountCard';
import PaymentRefund from '../components/refund/PaymentRefund';
import AddBalanceModal from '../components/account-balance/AddBalanceModal';
import { paymentStatusToChipMapper } from '../services/payment-service';
import DownloadButton from '../components/common/DownloadButton.tsx';

function PaymentPage() {
  const { id: paymentId } = useParams();
  const { isLoading, payment } = usePayment(paymentId);
  const theme = useTheme();
  const navigate = useNavigate();
  const [balanceModalOpen, setBalanceModalOpen] = useState(false);

  if (isLoading || !payment) {
    return null;
  }

  const chipInputs = paymentStatusToChipMapper.mapStatusToChipInputs(payment.status);

  return (
    <>
      <AddBalanceModal open={balanceModalOpen} handleClose={() => setBalanceModalOpen(false)} />

      <Stack gap={4}>
        <Stack direction='row' gap={3} sx={{ alignItems: 'center' }}>
          <IconButton sx={{ p: 0 }} onClick={() => navigate(-1)}>
            <PiArrowLeft color={theme.palette.neutral[900]} size={24} />
          </IconButton>
          <Typography color='neutral.900' fontSize={24} fontWeight={500} fontStyle='normal' mr='auto'>
            Payment details
          </Typography>

          {payment.status.toLowerCase() === 'success' && (
            <PaymentRefund
              isPaymentRefundable={payment.is_refundable}
              unableToRefundReason={payment.refundable_message}
              paymentAmount={payment.amount}
              paymentId={paymentId!}
              setBalanceModalOpen={setBalanceModalOpen}
            />
          )}

          <DownloadButton downloadUrl={`/v1/payments/${paymentId}/download-receipt`}>Download receipt</DownloadButton>
        </Stack>

        <Grid2 container spacing={6} sx={{ mt: 4 }}>
          <Grid2 size={6}>
            <DetailSummaryCard
              chipText={chipInputs.text}
              chipVariant={chipInputs.variant}
              summaryText={`You have successfully received ${payment.amount} from ${payment.customer.name}`}
            />
          </Grid2>
          <Grid2 size={6}>
            <PaymentAmountCard amount={payment.amount} createdAt={payment.created_at} />
          </Grid2>
        </Grid2>
        <Stack
          sx={{
            '& .MuiPaper-root': {
              borderRadius: '12px !important',
              boxShadow: 'none',
              border: '1px solid',
              borderColor: 'neutral.100',
            },
          }}>
          <Accordion defaultExpanded>
            <AccordionSummary
              expandIcon={<PiCaretDown />}
              sx={{
                px: 8,
                height: 66,
                minHeight: 66,
                '&.Mui-expanded': {
                  height: 66,
                  minHeight: 66,
                },
              }}
              aria-controls='panel1-content'
              id='panel1-header'>
              <Typography sx={{ fontSize: 18, fontWeight: 500, fontStyle: 'normal', color: 'neutral.700' }}>Payment tracker</Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ m: 0, p: 0, borderTop: '1px solid #e7e7e7', '& .MuiTimeline-root': { m: 0, p: 8 } }}>
              <TrackerTimeline
                elements={payment.tracker_data.map((trackerItem: { text: string; created_at: string }) => ({
                  text: trackerItem.text,
                  createdAt: trackerItem.created_at,
                }))}
              />
            </AccordionDetails>
          </Accordion>
        </Stack>
        <Stack
          sx={{
            '& .MuiPaper-root': {
              borderRadius: '12px !important',
              boxShadow: 'none',
              border: '1px solid',
              borderColor: 'neutral.100',
            },
          }}>
          <Accordion defaultExpanded>
            <AccordionSummary
              expandIcon={<PiCaretDown />}
              sx={{
                px: 8,
                height: 66,
                minHeight: 6,
                '&.Mui-expanded': {
                  height: 66,
                  minHeight: 66,
                },
              }}
              aria-controls='panel1-content'
              id='panel1-header'>
              <Typography sx={{ fontSize: 18, fontWeight: 500, fontStyle: 'normal', color: 'neutral.700' }}>Payment and Customer details</Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ m: 0, p: 0, borderTop: '1px solid', borderTopColor: 'neutral.100' }}>
              <Stack direction='row' gap={7} sx={{ justifyContent: 'space-around', pt: 3, px: 8, pb: 8 }}>
                <Stack direction='column' gap={4} sx={{ flexGrow: 1 }}>
                  <Typography fontSize={16} color='neutral.600' fontWeight={500}>
                    Payment details
                  </Typography>
                  <IconisedLabelledText icon={PiIdentificationCard} variant='detail' label='Payment ID' text={payment.id} />
                  <IconisedLabelledText icon={PiIdentificationCard} variant='detail' label='Payment link ID' text={payment.payment_link_id} />
                  <IconisedLabelledText icon={PiCalendar} variant='detail' label='Paid On' text={formatDateTime(payment.paid_on)} />
                  <IconisedLabelledText icon={PiCoins} variant='detail' label='Payment Amount' text={payment.amount} />
                  <IconisedLabelledText icon={PiCoin} variant='detail' label='Fx Fees' text={payment.fx_fees} />
                  <IconisedLabelledText icon={PiCoin} variant='detail' label={'Transaction Fees\n'} text={payment.transaction_fees} />
                </Stack>
                <Stack direction='column' gap={4} sx={{ flexGrow: 1 }}>
                  <Typography fontSize={16} color='neutral.600' fontWeight={500}>
                    Customer details
                  </Typography>
                  <IconisedLabelledText icon={PiIdentificationCard} variant='detail' label='Customer ID' text={payment.customer.id} />
                  <IconisedLabelledText icon={PiIdentificationBadge} variant='detail' label='Customer name' text={payment.customer.name} />
                  <IconisedLabelledText icon={PiEnvelope} variant='detail' label='Email' text={payment.customer.email} />
                  <IconisedLabelledText icon={PiGlobe} variant='detail' label='Country' text={getCountryName(payment.customer.country)} />
                </Stack>
              </Stack>
            </AccordionDetails>
          </Accordion>
        </Stack>
      </Stack>
    </>
  );
}

export default PaymentPage;
