import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, Typography } from '@mui/material';

import { isAsyncFunction } from '../../utils';
import LoadingButton from '../ui/LoadingButton';

interface ConfirmationDialogProps {
  open: boolean;
  title: string;
  content: string;
  error?: string;
  onClose: () => void;
  onConfirm: ((...args: any[]) => void) | ((...args: any[]) => Promise<unknown>);
  cancelText?: string;
  confirmText?: string;
}

const ConfirmationDialog: React.FC<ConfirmationDialogProps> = ({
  open,
  title,
  content,
  error,
  onClose,
  onConfirm,
  cancelText = 'Cancel',
  confirmText = 'Confirm',
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const handleConfirm: React.MouseEventHandler<HTMLButtonElement> = async (e) => {
    setIsLoading(true);
    if (isAsyncFunction(onConfirm)) await onConfirm(e);
    else onConfirm(e);
    setIsLoading(false);
  };

  return (
    <Dialog open={open} onClose={onClose} sx={{ '& .MuiDialog-paper': { borderRadius: 3 } }}>
      <DialogTitle sx={{ textAlign: 'center', p: 6, pb: 3, fontSize: 24, '&:first-letter': { textTransform: 'uppercase' } }}>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText sx={{ color: 'neutral.400', fontSize: 16, textAlign: 'center' }}>{content}</DialogContentText>
        {error && (
          <Typography fontSize={15} color='error'>
            {error}
          </Typography>
        )}
      </DialogContent>
      <DialogActions sx={{ p: 6, pt: 0, justifyContent: 'center', gap: 4 }}>
        <Button variant='outlined' onClick={onClose} sx={{ borderRadius: 3, textTransform: 'capitalize' }}>
          {cancelText}
        </Button>
        <LoadingButton
          variant='contained'
          onClick={handleConfirm}
          autoFocus
          sx={{ borderRadius: 3, textTransform: 'capitalize', minWidth: '6rem !important' }}
          isLoading={isLoading}>
          {confirmText}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationDialog;
