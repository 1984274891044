import { FC } from 'react';
import { Box, Button, Stack, Typography } from '@mui/material';
import { Navigate, Outlet, useLocation } from 'react-router-dom';

// import StyledTabs from '../components/ui/StyledTabs';
import useCheckoutCustomisationContext from '../components/checkout-customisation/useCheckoutCustomisationContext';
import LoadingButton from '../components/ui/LoadingButton';

const tabs = [
  { label: 'Branding', path: 'elements' },
  { label: 'Business Customisation', path: 'business-customisation' },
];

const BrandingPage: FC = () => {
  const location = useLocation();
  const currentTab = tabs.findIndex((tab) => location.pathname.includes(`/branding/${tab.path}`));
  const {
    form: checkoutCustomisationForm,
    utils: { resetForm },
    handlers: { handleSubmit: handleCheckoutCustomiationFormSubmit },
  } = useCheckoutCustomisationContext()!;

  if (currentTab === -1) return <Navigate to={tabs[0].path} replace />;

  const renderActions = () => {
    switch (tabs[currentTab]?.path) {
      case 'elements':
        return (
          <Stack direction='row' gap={3}>
            <Button variant='outlined' onClick={() => resetForm()} sx={{ minWidth: '6.875rem' }}>
              clear
            </Button>
            <LoadingButton
              type='submit'
              variant='contained'
              sx={{ minWidth: '6.875rem' }}
              disabled={!checkoutCustomisationForm.formState.isDirty}
              isLoading={checkoutCustomisationForm.formState.isSubmitting}
              onClick={checkoutCustomisationForm.handleSubmit(handleCheckoutCustomiationFormSubmit)}>
              save
            </LoadingButton>
          </Stack>
        );
      default:
        return null;
    }
  };

  return (
    <Stack gap={1} sx={{ position: 'relative' }}>
      <Stack direction='row' justifyContent='space-between'>
        <Typography color='neutral.900' fontSize={24} fontWeight={500}>
          Branding
        </Typography>

        {renderActions()}
      </Stack>

      {/* <StyledTabs value={currentTab} sx={{ mt: '.75rem' }}>
        {tabs.map((tab, index) => (
          <Tab
            data-testid={tab.path}
            key={tab.path}
            label={tab.label}
            component={Link}
            to={`/branding/${tab.path}`}
            value={index}
            sx={{ textTransform: 'none', px: 2, py: 1, mb: 1 }}
          />
        ))}
      </StyledTabs> */}
      <Box mt={8}>
        <Outlet />
      </Box>
    </Stack>
  );
};
export default BrandingPage;
