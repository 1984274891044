import { forwardRef } from 'react';
import { MenuItem, styled, TextField, TextFieldProps } from '@mui/material';
import { IoChevronDown } from 'react-icons/io5';
import { PiX } from 'react-icons/pi';

const defaultMenuProps = {
  slotProps: {
    paper: {
      sx: {
        marginTop: '.8rem',
        '& .MuiMenuItem-root': {
          padding: '8px 12px',
        },
        '& li': {
          fontSize: '0.875rem',
        },
      },
    },
  },
};

const MuiStyledTextField = styled(TextField)(({ theme }) => ({
  backgroundColor: 'white',
  borderRadius: '12px',

  '& *:first-letter': {
    textTransform: 'capitalize',
  },

  '.MuiOutlinedInput-notchedOutline': {
    borderColor: theme.palette.neutral[200],
  },
  '&:hover .MuiOutlinedInput-notchedOutline': {
    borderColor: theme.palette.neutral[200],
  },
  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    border: '1px solid',
    borderColor: theme.palette.neutral[200],
  },
}));

const StyledClearSelectionButton = styled('button')(({ theme }) => ({
  color: theme.palette.neutral[400],
  backgroundColor: 'transparent',
  outline: 'none',
  border: 'none',
  padding: 0,
  marginRight: '15px',

  '&:focus': {
    outline: 'none',
  },
}));

interface StyledSelectProps extends Omit<TextFieldProps, 'variant'> {
  onClearButtonClick?: () => void;
  placeholder?: string;
}

const StyledSelect = forwardRef<HTMLDivElement, StyledSelectProps>(({ value, onClearButtonClick, placeholder, children, ...rest }, ref) => (
  <MuiStyledTextField
    {...rest}
    ref={ref}
    select
    slotProps={{
      select: {
        sx: { color: value ? 'neutral.900' : 'neutral.400', height: 'inherit', borderRadius: 'inherit' },
        displayEmpty: true,
        MenuProps: defaultMenuProps,
        IconComponent: IoChevronDown,
        endAdornment:
          value && onClearButtonClick ? (
            <StyledClearSelectionButton onClick={onClearButtonClick}>
              <PiX />
            </StyledClearSelectionButton>
          ) : null,
      },
    }}
    value={value}>
    {placeholder && (
      <MenuItem value='' disabled sx={{ display: 'none' }}>
        {placeholder}
      </MenuItem>
    )}
    {children}
  </MuiStyledTextField>
));

export default StyledSelect;
