import { Stack, Typography } from '@mui/material';

import { useBalances } from '../repositories/account-repository';
import FilteredConversions from '../components/payments/FilteredConversions.tsx';

function BalanceConversions() {
  const { isLoading, balances } = useBalances();

  if (isLoading || !balances) {
    return <div>Loading...</div>;
  }

  return (
    <Stack gap={1}>
      <Stack gap={4}>
        <Typography fontSize={24} fontWeight={500} color='black'>
          List of Payments
        </Typography>
        <FilteredConversions />
      </Stack>
    </Stack>
  );
}

export default BalanceConversions;
