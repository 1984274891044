import { FC } from 'react';
import { Stack, Typography } from '@mui/material';

import FilteredOrders from '../components/orders/FilteredOrders';

const OrdersPage: FC = () => (
  <Stack gap={1}>
    <Typography component='h6' fontSize={24} fontWeight={500}>
      Orders
    </Typography>

    <FilteredOrders />
  </Stack>
);

export default OrdersPage;
