import { Outlet, useLocation, Link, Navigate, useNavigate } from 'react-router-dom';
import { Box, Tabs, Tab, Typography, Stack, styled, TabsProps, IconButton } from '@mui/material';
import React from 'react';
import { PiArrowLeft } from 'react-icons/pi';

import theme from '../config/theme.ts';

type StyledTabsProps = { children?: React.ReactNode; value: number } & TabsProps;

const StyledTabs = styled((props: StyledTabsProps) => (
  <Tabs
    {...props}
    sx={{ borderBottom: 1, borderColor: 'divider', minHeight: 0 }}
    textColor='primary'
    TabIndicatorProps={{
      sx: { height: 4, display: 'flex', justifyContent: 'center', backgroundColor: 'rhino.600' },
    }}
  />
))(({ theme }) => ({
  '& .MuiTab-root': { minWidth: 0, minHeight: 0 },
  '& .MuiTabs-flexContainer': { gap: theme.spacing(5) },
}));

const tabs = [
  { label: 'API Keys', path: 'api-keys' },
  { label: 'Webhooks', path: 'webhooks' },
];

function ApiKeysAndWebhooksPage() {
  const navigate = useNavigate();
  const location = useLocation();
  const currentTab = tabs.findIndex((tab) => location.pathname.includes(`/api-keys-and-webhooks/${tab.path}`));

  if (currentTab === -1) {
    return <Navigate to='api-keys' replace />;
  }

  return (
    <Stack gap={1}>
      <Stack direction='row' gap={2} alignItems='center'>
        <IconButton data-testid='back-btn' onClick={() => navigate('/settings')}>
          <PiArrowLeft color={theme.palette.neutral[900]} size={24} />
        </IconButton>
        <Typography color='neutral.900' fontSize={24} fontWeight={500}>
          API Keys and Webhooks
        </Typography>
      </Stack>
      <StyledTabs value={currentTab}>
        {tabs.map((tab, index) => (
          <Tab
            data-testid={tab.path}
            key={tab.path}
            label={tab.label}
            component={Link}
            to={`/api-keys-and-webhooks/${tab.path}`}
            value={index}
            sx={{ textTransform: 'none', px: 2, py: 1, mb: 1 }}
          />
        ))}
      </StyledTabs>
      <Box mt={8}>
        <Outlet />
      </Box>
    </Stack>
  );
}

export default ApiKeysAndWebhooksPage;
