import { Button, Paper, Stack, TextField, Tooltip } from '@mui/material';
import { PiInfo } from 'react-icons/pi';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { useNavigate } from 'react-router-dom';

import { urlSchema } from '../../utils/validation-schemas.ts';
import FormFieldWrapper from '../common/FormFieldWrapper.tsx';
import LoadingButton from '../ui/LoadingButton.tsx';

const formSchema = z.object({
  url: urlSchema,
  secret: z.string().nonempty('Secret is required'),
});
export type TWebhookFormData = z.infer<typeof formSchema>;

export interface WebhookFormProps {
  mode: 'create' | 'update';
  initialData?: TWebhookFormData;
  onSubmit: (data: TWebhookFormData) => Promise<void>;
}

export function WebhookForm({ mode, initialData, onSubmit }: WebhookFormProps) {
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { isValid, isSubmitting, errors },
  } = useForm<TWebhookFormData>({
    mode: 'onChange',
    resolver: zodResolver(formSchema),
    defaultValues: {
      url: initialData?.url || '',
      secret: initialData?.secret || '',
    },
  });

  const handleFormSubmit = handleSubmit(async (data) => {
    await onSubmit(data);
  });

  return (
    <Paper
      elevation={0}
      sx={{
        border: '1px solid',
        borderColor: 'neutral.100',
        py: '1.5rem',
        px: '1rem',
        borderRadius: '12px',
        height: '100%',
        boxSizing: 'border-box',
      }}>
      <form onSubmit={handleFormSubmit}>
        <Stack gap={5}>
          <Stack gap={4}>
            <FormFieldWrapper label='Webhook URL' labelHtmlFor='url'>
              <TextField id='url' placeholder='Webhook Url' fullWidth {...register('url')} error={!!errors.url} helperText={errors.url?.message} />
            </FormFieldWrapper>
            <FormFieldWrapper
              label={
                <Stack data-testid='secret-label' direction='row' alignItems='center' gap={1}>
                  Secret
                  <Tooltip title='Secret used to verify webhook authenticity' placement='top' arrow>
                    <Stack>
                      <PiInfo size={16} />
                    </Stack>
                  </Tooltip>
                </Stack>
              }
              labelHtmlFor='secret'>
              <TextField
                id='secret'
                inputProps={{ 'data-testid': 'secret-input' }} // Add test ID to the input element
                placeholder='Secret'
                fullWidth
                {...register('secret')}
                error={!!errors.secret}
                helperText={errors.secret?.message}
              />
            </FormFieldWrapper>
          </Stack>
          <Stack direction='row' gap={4} justifyContent='end'>
            <Button
              variant='outlined'
              onClick={() => {
                navigate('/api-keys-and-webhooks/webhooks');
              }}>
              Cancel
            </Button>
            <LoadingButton variant='contained' type='submit' disabled={!isValid} isLoading={isSubmitting} sx={{ minWidth: '6rem' }}>
              {mode === 'create' ? 'Create' : 'Save'}
            </LoadingButton>
          </Stack>
        </Stack>
      </form>
    </Paper>
  );
}
