import React from 'react';
import { Avatar, IconButton, Menu, MenuItem, Tooltip } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { PiSignOut, PiUserCircle } from 'react-icons/pi';

import useAuth from '../../repositories/useAuth';
import { userInitial } from '../../services/user-service';
import { redirectToRailsApp } from '../../utils/route-utils';

function AccountMenu() {
  const { user } = useAuth();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const navigate = useNavigate();

  return (
    <React.Fragment>
      <Tooltip title='Account settings'>
        <IconButton
          onClick={handleClick}
          size='small'
          sx={{ ml: 2 }}
          aria-controls={open ? 'account-menu' : undefined}
          aria-haspopup='true'
          aria-expanded={open ? 'true' : undefined}>
          <Avatar sx={{ width: '2.6rem', height: '2.6rem', color: 'neutral.900', backgroundColor: 'rhino.100', fontWeight: 'bold' }}>
            {userInitial(user)}
          </Avatar>
        </IconButton>
      </Tooltip>
      <Menu
        anchorEl={anchorEl}
        id='account-menu'
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}>
        <MenuItem
          onClick={() => {
            navigate('/profile');
          }}
          sx={{ display: 'flex', direction: 'row', gap: 2 }}>
          <PiUserCircle size={24} /> My Profile
        </MenuItem>
        <MenuItem onClick={() => redirectToRailsApp('/logout')} sx={{ display: 'flex', direction: 'row', gap: 2 }}>
          <PiSignOut size={24} /> Sign Out
        </MenuItem>
      </Menu>
    </React.Fragment>
  );
}

export default AccountMenu;
