import { FC, ReactElement } from 'react';
import { Link } from 'react-router-dom';
import { Avatar, Paper, Typography } from '@mui/material';

interface SettingCardProps {
  setting: {
    linkTo: string;
    title: string;
    description: string;
    icon: ReactElement;
  };
}

const SettingCard: FC<SettingCardProps> = ({ setting: { linkTo, title, description, icon } }) => (
  <Link to={linkTo}>
    <Paper
      elevation={0}
      sx={{
        border: '1px solid',
        borderColor: 'neutral.100',
        py: '1.5rem',
        px: '1rem',
        borderRadius: '12px',
        height: '100%',
        boxSizing: 'border-box',
      }}>
      <Avatar sx={{ backgroundColor: '#F6F2FF', color: 'neutral.500' }}>{icon}</Avatar>

      <Typography fontSize={18} color='neutral.700' fontWeight={500} mt='1rem' mb='.5rem'>
        {title}
      </Typography>

      <Typography variant='caption' fontSize={12} color='neutral.500' fontWeight={500}>
        {description}
      </Typography>
    </Paper>
  </Link>
);

export default SettingCard;
