import { Button } from '@mui/material';
import { useCallback, useState } from 'react';

import apiClient from '../../utils/api-client';
import useAlert from '../../hooks/useAlert';

type TDownloadButtonProps = {
  downloadUrl: string;
  children: React.ReactNode;
};

function DownloadButton({ downloadUrl, children }: TDownloadButtonProps) {
  const [downloading, setDownloading] = useState(false);
  const { showSnackbar } = useAlert()!;

  const handleDownloadReceipt = useCallback(async () => {
    setDownloading(true);
    try {
      const response = await apiClient.getFile(downloadUrl);
      const url = window.URL.createObjectURL(response.data);

      const link = document.createElement('a');
      link.href = url;

      const filename = response.filename || 'payment_receipt.pdf';
      link.setAttribute('download', filename);

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      showSnackbar({ title: 'Download successful!', alertType: 'success' });

      setTimeout(() => window.URL.revokeObjectURL(url), 100);
    } catch (error) {
      console.error('Error downloading file:', error);
      showSnackbar({ title: 'Download failed!', alertType: 'failure' });
    }
    setDownloading(false);
  }, [downloadUrl]);

  return (
    <Button variant='contained' onClick={handleDownloadReceipt} loading={downloading} loadingPosition='end'>
      {children}
    </Button>
  );
}

export default DownloadButton;
