import { FC } from 'react';
import { Card, CardContent, Typography } from '@mui/material';

import { formatDateTime } from '../../utils/datetime-utils.ts';

interface PaymentAmountCardProps {
  amount: string;
  createdAt: string;
}

const PaymentAmountCard: FC<PaymentAmountCardProps> = ({ amount, createdAt }) => (
  <Card
    variant='outlined'
    sx={{
      borderRadius: 3,
      px: 6,
      pt: 6,
    }}>
    <CardContent sx={{ p: 0, '&:last-child': { pb: 5 } }}>
      <Typography fontStyle='normal' color='neutral.700' fontWeight={500} fontSize={18}>
        Amount
      </Typography>
      <Typography color='neutral.900' fontSize={24} fontStyle='normal' fontWeight={700} sx={{ mt: 4 }}>
        {amount}
      </Typography>
      <Typography fontSize={16} fontWeight={400} fontStyle='normal' color='neutral.800'>
        {formatDateTime(createdAt)}
      </Typography>
    </CardContent>
  </Card>
);

export default PaymentAmountCard;
