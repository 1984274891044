import { Box, Stack, styled, TextField } from '@mui/material';
import React, { ChangeEvent, useRef, useState } from 'react';

interface OtpInputProps {
  onComplete?: (otp: string) => void;
  onChange: (otp: string) => void;
  length?: number;
}

const StyledTextField = styled(TextField)({
  '& .MuiInputBase-root': {
    width: '48px',
    height: '48px',
    borderRadius: '8px',
    border: '#D1D1D1',
  },
  '& .MuiInputBase-input': {
    padding: 0,
    textAlign: 'center',
    fontSize: '18px',
    fontWeight: 500,
    color: '#5D5D5D',
  },
});

export const OtpInput: React.FC<OtpInputProps> = ({ onComplete, onChange, length = 6 }) => {
  const [otp, setOtp] = useState<string[]>(Array(length).fill(''));
  const inputRefs = useRef<(HTMLInputElement | null)[]>([]);

  if (inputRefs.current.length !== length) {
    inputRefs.current = Array(length).fill(null);
  }

  const handleChange = (index: number, e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
    const value = e.target.value;
    if (!/^\d*$/.test(value)) return;

    const newOtp = [...otp];
    newOtp[index] = value;
    setOtp(newOtp);

    // Emit only non-empty values
    onChange(newOtp.filter(Boolean).join(''));

    // Check for completion
    if (newOtp.every((digit) => digit !== '') && onComplete) {
      onComplete(newOtp.join(''));
    }

    if (value && index < length - 1 && inputRefs.current[index + 1]) {
      inputRefs.current[index + 1]?.focus();
    }
  };

  const handleKeyDown = (index: number, e: React.KeyboardEvent): void => {
    if (e.key === 'Backspace' && !otp[index] && index > 0 && inputRefs.current[index - 1]) {
      const newOtp = [...otp];
      newOtp[index - 1] = '';
      setOtp(newOtp);
      onChange(newOtp.filter(Boolean).join(''));
      inputRefs.current[index - 1]?.focus();
    }
  };

  const handlePaste = (e: React.ClipboardEvent<HTMLInputElement>): void => {
    e.preventDefault();
    const pastedData = e.clipboardData.getData('text/plain').slice(0, length);
    if (!/^\d+$/.test(pastedData)) return;

    const newOtp = [...otp];
    pastedData.split('').forEach((char, index) => {
      if (index < length) newOtp[index] = char;
    });
    setOtp(newOtp);
    onChange(newOtp.filter(Boolean).join(''));

    if (newOtp.every((digit) => digit !== '') && onComplete) {
      onComplete(newOtp.join(''));
    }
  };

  return (
    <Box sx={{ maxWidth: 'md', alignSelf: 'flex-start' }}>
      <Stack direction='row' gap={2} justifyContent='center' mt={2}>
        {otp.map((digit, index) => (
          <StyledTextField
            key={index}
            inputRef={(el) => (inputRefs.current[index] = el)}
            variant='outlined'
            type='text'
            inputMode='numeric'
            inputProps={{
              maxLength: 1,
              pattern: '\\d*',
              'aria-label': `Digit ${index + 1}`,
              style: { textAlign: 'center' },
            }}
            value={digit}
            onChange={(e) => handleChange(index, e)}
            onKeyDown={(e) => handleKeyDown(index, e)}
            onPaste={handlePaste}
          />
        ))}
      </Stack>
    </Box>
  );
};
