import {
  Alert,
  AlertColor,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  IconButton,
  Snackbar,
  Stack,
  Toolbar,
  Typography,
} from '@mui/material';
import { PiPencilLine, PiX } from 'react-icons/pi';
import AppBar from '@mui/material/AppBar';
import React, { useEffect } from 'react';

import { updatePassword } from '../repositories/account-repository.tsx';

import PasswordTextField from './common/PasswordTextField.tsx';

function EditPasswordModal() {
  const [openPasswordDialog, setOpenPasswordDialog] = React.useState(false);
  const [snackbarVariant, setSnackbarVariant] = React.useState<AlertColor>('success');
  const [openPasswordUpdateSnackbar, setOpenPasswordUpdateSnackbar] = React.useState(false);
  const [passwordUpdateResponseMessage, setPasswordUpdateResponseMessage] = React.useState('');
  const [submitButtonDisabled, setSubmitButtonDisabled] = React.useState(true);
  const [passwordFieldError, setPasswordFieldError] = React.useState(false);
  const [currentPasswordError, setCurrentPasswordError] = React.useState('');
  const handleForgetPasswordDialogOpen = () => {
    setOpenPasswordDialog(true);
  };

  const [formValues, setFormValues] = React.useState({
    currentPassword: '',
    newPassword: '',
    newPasswordConfirmation: '',
  });

  const validateForm = () => {
    if (formValues.newPassword !== '' && formValues.newPasswordConfirmation !== '') {
      if (formValues.newPassword !== formValues.newPasswordConfirmation) {
        setPasswordFieldError(true);
        setSubmitButtonDisabled(true);
        return;
      }
    }

    if (formValues.newPassword !== '' && formValues.newPassword === formValues.newPasswordConfirmation && formValues.currentPassword.length > 0) {
      setPasswordFieldError(false); // Clear any existing error
      setSubmitButtonDisabled(false);
    } else {
      setSubmitButtonDisabled(true);
    }
  };

  const handleModalClose = (_event: object, reason: string) => {
    if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
      return;
    }
    setOpenPasswordDialog(false);
    setCurrentPasswordError('');
    setFormValues({
      currentPassword: '',
      newPassword: '',
      newPasswordConfirmation: '',
    });
  };

  useEffect(() => {
    validateForm();
  }, [formValues, validateForm]);

  const handleFieldChange = (key: string, value: string) => {
    setFormValues((prev) => ({ ...prev, [key]: value }));
  };

  const submitForm = async () => {
    try {
      const response = await updatePassword(formValues);
      setSnackbarVariant('success');
      handleModalClose({}, 'formSubmit');
      setPasswordUpdateResponseMessage(response.message);
      setOpenPasswordUpdateSnackbar(true);
    } catch (error) {
      setSnackbarVariant('error');
      const errorObject = error as { message: string; status: number };
      setPasswordUpdateResponseMessage(errorObject.message);
      if (errorObject.status === 400) {
        setCurrentPasswordError(errorObject.message);
      } else {
        setOpenPasswordUpdateSnackbar(true);
      }
    }
  };

  return (
    <div>
      <IconButton aria-label='Edit password' size='small' onClick={handleForgetPasswordDialogOpen}>
        <PiPencilLine size={20} />
      </IconButton>
      <Dialog sx={{ [`& .MuiPaper-root`]: { borderRadius: 3 } }} maxWidth='sm' fullWidth open={openPasswordDialog} onClose={handleModalClose}>
        <Box sx={{ flexGrow: 1 }}>
          <AppBar position='static' color='transparent' elevation={0}>
            <Toolbar>
              <Typography variant='h6' component='div' sx={{ flexGrow: 1 }}>
                Edit password
              </Typography>
              <IconButton aria-label='close' size='small' onClick={(event) => handleModalClose(event, 'iconBtnClick')}>
                <PiX size={20} />
              </IconButton>
            </Toolbar>
          </AppBar>
        </Box>
        <Divider />
        <DialogContent>
          <Stack direction='column' gap={2}>
            <PasswordTextField
              fieldLabel='Current password'
              keyValue='currentPassword'
              value={formValues.currentPassword}
              onChange={handleFieldChange}
              error={currentPasswordError.length != 0}
              errorMesage={currentPasswordError}
              placeholder='Enter current password'
            />
            <PasswordTextField
              fieldLabel='New password'
              keyValue='newPassword'
              value={formValues.newPassword}
              onChange={handleFieldChange}
              error={passwordFieldError}
              errorMesage='Passwords do not match'
              placeholder='Enter new password'
            />
            <PasswordTextField
              fieldLabel='Retype new password'
              keyValue='newPasswordConfirmation'
              value={formValues.newPasswordConfirmation}
              onChange={handleFieldChange}
              error={passwordFieldError}
              errorMesage='Passwords do not match'
              placeholder='Re-enter new password'
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button
            sx={{ borderRadius: 3, padding: '8px 16px' }}
            variant='outlined'
            onClick={() => {
              setOpenPasswordDialog(false);
            }}>
            <Typography sx={{ textTransform: 'capitalize' }}>Cancel</Typography>
          </Button>
          <Button
            sx={{ borderRadius: 3, padding: '8px 16px' }}
            variant='contained'
            type='submit'
            disabled={submitButtonDisabled}
            onClick={submitForm}>
            <Typography sx={{ textTransform: 'capitalize' }}>Submit</Typography>
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={openPasswordUpdateSnackbar}
        onClose={() => setOpenPasswordUpdateSnackbar(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        autoHideDuration={3000}
        message={passwordUpdateResponseMessage}>
        <Alert onClose={() => setOpenPasswordUpdateSnackbar(false)} severity={snackbarVariant} variant='filled' sx={{ width: '100%' }}>
          {passwordUpdateResponseMessage}
        </Alert>
      </Snackbar>
    </div>
  );
}

export default EditPasswordModal;
