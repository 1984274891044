import { useQuery } from '@tanstack/react-query';
import { DateTime } from 'luxon';

import apiClient from '../utils/api-client.ts';
import { FilteredPaymentsResponse } from '../types/PaymentTypes';
import queryKeys from '../constants/query-keys.ts';

export function usePayments(
  search: string,
  dateFrom: DateTime | undefined,
  dateTo: DateTime | undefined,
  page: string,
  pageSize: string,
  onlyFundAdditions: boolean,
): { isLoading: boolean; paymentsResponse: FilteredPaymentsResponse | undefined; isError: boolean } {
  const {
    isLoading,
    data: paymentsResponse,
    isError,
  } = useQuery({
    queryKey: ['payments', search, dateFrom, dateTo, page, pageSize],
    queryFn: () => fetchPayments(search, dateFrom, dateTo, page, pageSize, onlyFundAdditions),
  });

  return { isLoading, paymentsResponse, isError };
}

const fetchPayments = async (
  search: string,
  dateFrom: DateTime | undefined,
  dateTo: DateTime | undefined,
  page: string,
  pageSize: string,
  onlyFundAdditions: boolean,
): Promise<FilteredPaymentsResponse> => {
  const { data } = await apiClient.get('/v1/payments', {
    search: search,
    date_from: dateFrom,
    date_to: dateTo,
    page: page,
    page_size: pageSize,
    only_fund_additions: onlyFundAdditions,
  });
  return data;
};

export function usePayment(id: string | undefined) {
  const {
    isLoading,
    data: payment,
    isError,
  } = useQuery({
    queryKey: [queryKeys.PAYMENTS, id],
    queryFn: () => fetchPayment(id),
    enabled: !!id,
  });
  return { isLoading, payment, isError };
}

const fetchPayment = async (id: string | undefined) => {
  const { data } = await apiClient.get(`/v1/payments/${id}`);
  return data;
};
