import currencyCodes from 'currency-codes';
import DineroFactory, { Currency } from 'dinero.js';
import { registerLocale, alpha3ToAlpha2, getName } from 'i18n-iso-countries';
import * as enLocale from 'i18n-iso-countries/langs/en.json';

registerLocale(enLocale);

const currencyToCountryMap: { [key: string]: string } = {
  USD: 'US',
  EUR: 'EU',
  GBP: 'GB',
  // Add more mappings as and when more currencies are introduced in the backend
};

const currencyToCountryLongMap: { [key: string]: string } = {
  USD: 'United States Dollar',
  EUR: 'Euro',
  GBP: 'Great British Pound',
  // Add more mappings as and when more currencies are introduced in the backend
};
export const currencyToCountryLong = (currency: string): string | undefined => {
  return currencyToCountryLongMap[currency];
};

export const currencyToCountry = (currency: string): string | undefined => {
  return currencyToCountryMap[currency];
};

export function getCurrencyName(isoCode: string): string | undefined {
  const currency = currencyCodes.code(isoCode.toUpperCase());
  return currency ? currency.currency : undefined;
}

export function getCountryName(alpha3Code: string) {
  const alpha2 = alpha3ToAlpha2(alpha3Code.toUpperCase());
  if (!alpha2) return 'Unknown Country';

  return getName(alpha2, 'en') || 'Unknown Country';
}

export const formatAmount = (amountInCents: number | string, currencyCode: string) => {
  const dinero = DineroFactory({ amount: +amountInCents, currency: currencyCode as Currency });
  return `${dinero.toFormat('0,0.00')} ${currencyCode}`;
};

// eslint-disable-next-line @typescript-eslint/no-unsafe-function-type
export const isAsyncFunction = (func: Function) => func.constructor.name === 'AsyncFunction';

export const getIncrementedPaginationModel = ({ page, pageSize }: { page: number; pageSize: number }) => ({ page: page + 1, pageSize });
