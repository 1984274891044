import { Modal, Box, Typography, Button, TextField, Stack, IconButton, Divider } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { PiX } from 'react-icons/pi';
import { Currency, Dinero } from 'dinero.js';

import CurrencySelect from '../common/CurrencySelect';
import { getConvertBalanceQuote, submitConvertBalance } from '../../repositories/account-repository';
import useDebounce from '../../hooks/useDebounce';
import { ConvertBalanceQuote } from '../../types/BalanceTypes';
import ConfirmationDialog from '../common/ConfirmationDialog';
import { ErrorResponse } from '../../utils/api-client';
import SuccessDialog from '../common/SuccessDialog';

interface ConvertBalanceModalProps {
  open: boolean;
  onClose: () => void;
  onSuccess?: () => void;
}

const ConvertBalanceModal: React.FC<ConvertBalanceModalProps> = ({ open, onClose, onSuccess }) => {
  const [sourceCurrency, setSourceCurrency] = useState<Currency | undefined>();
  const [targetCurrency, setTargetCurrency] = useState<Currency | undefined>();
  const [sourceAmount, setSourceAmount] = useState<number | undefined>();
  const [targetAmount, setTargetAmount] = useState<number | undefined>();
  const [referenceAmount, setReferenceAmount] = useState<'source' | 'target'>('source');
  const [fee, setFee] = useState<Dinero | undefined>();
  const [error, setError] = useState<string | undefined>();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [successDialogOpen, setSuccessDialogOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(open);
  const [enableSubmit, setEnableSubmit] = useState(false);
  const [quote, setQuote] = useState<ConvertBalanceQuote>();

  useEffect(() => {
    setModalOpen(open);
    setSourceCurrency(undefined);
    setTargetCurrency(undefined);
    setSourceAmount(undefined);
    setTargetAmount(undefined);
    setEnableSubmit(false);
    setReferenceAmount('source');
    setFee(undefined);
    setError(undefined);
    setQuote(undefined);
  }, [open]);

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    if (!quote || !sourceCurrency || !targetCurrency) {
      return;
    }
    try {
      await submitConvertBalance(sourceCurrency, targetCurrency, quote.source.getAmount(), quote.target.getAmount());
      handleCloseConfirmation();
      setSuccessDialogOpen(true);
      setError(undefined);
    } catch (error) {
      setError((error as ErrorResponse['error']).message);
    }
  };

  const handleCloseConfirmation = () => {
    setDialogOpen(false);
    onClose();
  };

  const openDialog = () => {
    if (!sourceCurrency || !targetCurrency || sourceCurrency === targetCurrency || !sourceAmount || !targetAmount) {
      return;
    }
    setModalOpen(false);
    setDialogOpen(true);
  };

  const getQuote = useDebounce(
    async (sourceCurrency?: Currency, targetCurrency?: Currency, sourceAmount?: number, targetAmount?: number, reference?: 'source' | 'target') => {
      if (!sourceCurrency || !targetCurrency) {
        return;
      }
      if (sourceCurrency === targetCurrency) {
        return;
      }
      if (reference === 'source' && !sourceAmount) {
        return;
      }
      if (reference === 'target' && !targetAmount) {
        return;
      }
      let quote;
      try {
        if (reference === 'source') {
          quote = await getConvertBalanceQuote(sourceCurrency, targetCurrency, sourceAmount);
          setTargetAmount(quote.target.toUnit());
        } else {
          quote = await getConvertBalanceQuote(sourceCurrency, targetCurrency, undefined, targetAmount);
          setSourceAmount(quote.source.toUnit());
        }
        setFee(quote.totalFee);
        setQuote(quote);
        setEnableSubmit(true);
        setError(undefined);
      } catch (error) {
        setError((error as ErrorResponse['error']).message);
        setEnableSubmit(false);
      }
    },
  );

  return (
    <React.Fragment>
      <Modal open={modalOpen} onClose={onClose}>
        <Box
          sx={{
            maxWidth: '50vw',
            margin: '100px auto',
            backgroundColor: 'background.paper',
            borderRadius: 2,
            boxShadow: 24,
            overflow: 'auto',
          }}>
          <Stack>
            <Stack direction='row' sx={{ pt: 10, px: 6, pb: 3, justifyContent: 'space-between', borderBottom: '1px' }}>
              <Stack gap={2}>
                <Typography fontSize={24} fontWeight={500}>
                  Convert Balance
                </Typography>
                <Typography color='secondary.light'>Transfer your balance between currencies in real-time</Typography>
              </Stack>
              <IconButton sx={{ '&:focus': { outline: 'none' }, height: 'fit-content' }} onClick={onClose}>
                <PiX size={20} />
              </IconButton>
            </Stack>
            <Divider />
            <Stack sx={{ p: 6, pb: 0, gap: 8 }}>
              <Stack gap={2}>
                <Typography fontSize={18} fontWeight={500}>
                  From
                </Typography>
                <Stack direction='row' gap={4}>
                  <CurrencySelect
                    onChange={(value) => {
                      setSourceCurrency(value);
                      getQuote(value, targetCurrency, sourceAmount, targetAmount, referenceAmount);
                    }}
                  />
                  <TextField
                    type='number'
                    fullWidth
                    placeholder='Enter the amount'
                    value={sourceAmount}
                    sx={{ '& .MuiInputBase-root': { borderRadius: 3 } }}
                    onChange={(event) => {
                      setSourceAmount(parseFloat(event.target.value));
                      setReferenceAmount('source');
                      getQuote(sourceCurrency, targetCurrency, parseFloat(event.target.value), targetAmount, 'source');
                    }}
                  />
                </Stack>
                {error && (
                  <Typography fontSize={15} color='error'>
                    {error}
                  </Typography>
                )}
              </Stack>

              <Stack gap={4}>
                <Stack gap={2}>
                  <Stack direction='row' justifyContent='space-between'>
                    <Typography fontSize={18} fontWeight={500}>
                      To
                    </Typography>
                    <Typography fontWeight={500}>
                      {sourceCurrency !== targetCurrency &&
                        quote?.midMarketRate &&
                        `1 ${sourceCurrency?.toUpperCase()} = ${quote.midMarketRate} ${targetCurrency?.toUpperCase()}`}
                    </Typography>
                  </Stack>
                  <Stack direction='row' gap={4}>
                    <CurrencySelect
                      onChange={(value) => {
                        setTargetCurrency(value);
                        getQuote(sourceCurrency, value, sourceAmount, targetAmount, referenceAmount);
                      }}
                    />
                    <TextField
                      type='number'
                      fullWidth
                      placeholder='Enter the amount'
                      value={targetAmount}
                      sx={{ '& .MuiInputBase-root': { borderRadius: 3 } }}
                      onChange={(event) => {
                        setTargetAmount(parseFloat(event.target.value));
                        setReferenceAmount('target');
                        getQuote(sourceCurrency, targetCurrency, sourceAmount, parseFloat(event.target.value), 'target');
                      }}
                    />
                  </Stack>
                </Stack>
                {fee && (
                  <Stack direction='row' sx={{ gap: 1 }}>
                    <Typography fontSize={18} fontWeight={500}>
                      Total charges:
                    </Typography>
                    <Typography fontSize={18} fontWeight={700}>
                      {fee.toFormat('$0,0.00')}
                    </Typography>
                  </Stack>
                )}
              </Stack>
            </Stack>
            <Stack direction='row' sx={{ px: 6, pb: 8, gap: 4, mt: 4, justifyContent: 'flex-end' }}>
              <Button onClick={onClose} variant='outlined' sx={{ borderRadius: 3, textTransform: 'capitalize' }}>
                Cancel
              </Button>
              <Button
                disabled={!enableSubmit || sourceCurrency === targetCurrency}
                type='submit'
                variant='contained'
                sx={{ borderRadius: 3, textTransform: 'capitalize' }}
                onClick={openDialog}>
                Convert
              </Button>
            </Stack>
          </Stack>
        </Box>
      </Modal>
      <ConfirmationDialog
        open={dialogOpen}
        title='Confirm Conversion'
        content={`Are you sure you want to convert your balance from ${sourceCurrency?.toUpperCase()} ${sourceAmount} to ${targetCurrency?.toUpperCase()} ${targetAmount}?`}
        error={error}
        onClose={handleCloseConfirmation}
        onConfirm={handleSubmit}
      />
      <SuccessDialog
        open={successDialogOpen}
        message='Balance successfully converted and ready to use'
        title='Conversion Successful'
        onClose={() => {
          if (onSuccess) {
            onSuccess();
          }
          setSuccessDialogOpen(false);
        }}
      />
    </React.Fragment>
  );
};

export default ConvertBalanceModal;
