import { FC, useMemo } from 'react';
import { CheckoutUI } from '@glomopay/checkout-ui';
import { Box } from '@mui/material';

// import StyledSelect from '../components/ui/StyledSelect';
import { dummyCheckout } from '../constants/app-constants';
import useBusiness from '../repositories/business-repository';
import CheckoutCustomisationForm from '../components/checkout-customisation/CheckoutCustomisationForm';
import useCheckoutCustomisationContext from '../components/checkout-customisation/useCheckoutCustomisationContext';

const BrandingElementsPage: FC = () => {
  const { business, isLoading: isBusinessLoading, isError: isBusinessApiError } = useBusiness();
  const {
    api: { isCheckoutCustomisationLoading, checkoutCustomisationApiError },
    form,
  } = useCheckoutCustomisationContext()!;

  const currentBackgroundColor = form.watch('bgColor');
  const currentLogo = form.watch('logo');

  // Memoize preview url to prevent lag when the value of color input changes
  const currentLogoPreviewUrl = useMemo(() => (currentLogo ? URL.createObjectURL(currentLogo) : null), [currentLogo]);

  if (isCheckoutCustomisationLoading || isBusinessLoading) return <div>Loading...</div>;

  if (checkoutCustomisationApiError || isBusinessApiError) return <div>Error</div>;

  return (
    <Box
      sx={{
        display: 'grid',
        backgroundColor: '#fefefe',
        gridTemplateColumns: '1fr 1.8fr',
        borderRadius: '.75rem',
        overflow: 'hidden',
        border: '1px solid',
        borderColor: 'neutral.100',
      }}>
      <CheckoutCustomisationForm />

      <Box padding='3rem' bgcolor='rhino.50'>
        <CheckoutUI
          orderId={dummyCheckout.order.id.slice(6)}
          merchant={{
            name: business!.dba || business!.name,
            logo: currentLogoPreviewUrl,
          }}
          payer={{
            name: dummyCheckout.customer.name,
            email: dummyCheckout.customer.email,
            phone: dummyCheckout.customer.phone || '',
            country: dummyCheckout.customer.country,
            addressLine1: dummyCheckout.customer.address,
            addressLine2: dummyCheckout.customer.city,
            state: dummyCheckout.customer.state,
            pincode: dummyCheckout.customer.pincode || '',
          }}
          payment={{
            amount: dummyCheckout.order.amount,
            currency: dummyCheckout.order.currency,
          }}
          customisation={{
            bgColor: currentBackgroundColor,
          }}
          bankDetails={{
            id: dummyCheckout.order.id,
            holderName: dummyCheckout.methods.bankTransfer.supportedData.holderName,
            accountNumber: dummyCheckout.methods.bankTransfer.supportedData.accountNumber,
            beneficiaryAddress: `${dummyCheckout.methods.bankTransfer.supportedData.addressLine1}, ${dummyCheckout.methods.bankTransfer.supportedData.addressLine2}, ${dummyCheckout.methods.bankTransfer.supportedData.city}, ${dummyCheckout.methods.bankTransfer.supportedData.country}, ${dummyCheckout.methods.bankTransfer.supportedData.pincode}`,
            swiftCode: dummyCheckout.methods.bankTransfer.supportedData.bicPrimary || dummyCheckout.methods.bankTransfer.supportedData.bicSecondary,
            purposeCode: {
              code: dummyCheckout.purposeCode.code,
              description: dummyCheckout.purposeCode.description,
            },
          }}
        />
      </Box>
    </Box>
  );
};

export default BrandingElementsPage;
