import { Box, Button, Stack, Typography } from '@mui/material';
import { PiArrowSquareOut } from 'react-icons/pi';
import { Link } from 'react-router-dom';
import { useState } from 'react';

import { useWebhooks } from '../repositories/webhooks-repository.tsx';
import CustomDataGrid from '../components/common/CustomDataGrid.tsx';
import { webhookListColumns } from '../services/webhooks-service.tsx';

function WebhooksPage() {
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 5,
    page: 0,
  });
  const { isLoading, webhooksResponse } = useWebhooks();
  if (isLoading) return <div>Loading....</div>;

  if (webhooksResponse?.totalCount === 0) {
    return (
      <div>
        <Stack direction='column' alignItems='center' gap={0} sx={{ height: '55vh', justifyContent: 'center' }}>
          <Typography color='neutral.900' fontSize={24} fontWeight={500}>
            Create your first webhook to streamline your workflow
          </Typography>
          <Typography textAlign='center' pt={2} width='50%' color='neutral.600' fontSize={18} fontWeight={400}>
            Execute your functions, workflows, and integrations using webhooks that trigger based on changes to your content.
          </Typography>
          <Link to='/webhooks/create'>
            <Button variant='contained' sx={{ mt: 8 }}>
              Add Webhook
            </Button>
          </Link>
          <Button variant='text' data-testid='copy-button' sx={{ fontSize: 14, fontWeight: 400, mt: 1 }} size='small' endIcon={<PiArrowSquareOut />}>
            Learn more about webhooks
          </Button>
        </Stack>
      </div>
    );
  }

  return (
    <div>
      <Stack gap={8}>
        <Stack>
          <Typography color='neutral.900' fontSize={18} fontWeight={500}>
            Webhook Management
          </Typography>
          <Stack direction='row' justifyContent='space-between'>
            <Typography color='neutral.800' fontSize={14} fontWeight={400}>
              This section displays all webhook configurations
            </Typography>
            <a href='https://docs.glomopay.com/api-documentation/webhooks' target='_blank'>
              <Stack direction='row' gap={1}>
                <Typography color='rhino.800' fontWeight={500} fontSize={14}>
                  Learn more about Webhooks
                </Typography>
                <PiArrowSquareOut />
              </Stack>
            </a>
          </Stack>
        </Stack>
        <Box sx={{ '& .MuiDataGrid-footerContainer': { display: 'none' } }}>
          <CustomDataGrid
            isLoading={isLoading}
            rows={webhooksResponse?.webhooks || []}
            columns={webhookListColumns}
            paginationModel={paginationModel}
            onPaginationChange={setPaginationModel}
          />
        </Box>
      </Stack>
    </div>
  );
}

export default WebhooksPage;
