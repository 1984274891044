import { FC, ReactElement, useState } from 'react';
import { z } from 'zod';
import { Button, DialogActions, DialogContent, DialogTitle, Divider, MenuItem, Stack, TextField } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';

import { CustomModal } from '../common/CustomModal';
import FormFieldWrapper from '../common/FormFieldWrapper';
import { emailSchema, phoneSchema, pincodeSchema } from '../../utils/validation-schemas';
import { customerTypes } from '../../constants/customer-constants';
import CountryAutocomplete from '../common/CountryAutocomplete';
import { useCreateCustomer } from '../../repositories/customer-repository';
import useAlert from '../../hooks/useAlert';
import LoadingButton from '../ui/LoadingButton';
import StyledSelect from '../ui/StyledSelect';
import { usePayinCountries } from '../../repositories/payins-repository';

const formSchema = z.object({
  country: z.object({ value: z.string().nonempty('Country is required'), label: z.string().nonempty('Country is required') }),
  name: z.string().nonempty('Name is required'),
  customerType: z.string().nonempty('Customer type is required'),
  email: emailSchema,
  phone: phoneSchema,
  address: z.string().nonempty('Address is required'),
  city: z.string().nonempty('City is required'),
  state: z.string().nonempty('State is required').max(50, 'State name is too long'),
  pincode: pincodeSchema,
});

type TFormData = z.infer<typeof formSchema>;

interface CreateCustomerModalProps {
  children: ReactElement;
}

const CreateCustomerModal: FC<CreateCustomerModalProps> = ({ children }) => {
  const {
    register,
    handleSubmit: handleHookFormSubmit,
    reset: resetForm,
    formState: { isDirty, isSubmitting, errors },
    control,
  } = useForm<TFormData>({
    mode: 'onChange',
    resolver: zodResolver(formSchema),
    defaultValues: {
      address: '',
      city: '',
      email: '',
      name: '',
      phone: '',
      pincode: '',
      state: '',
      customerType: '',
      country: { label: '', value: '' },
    },
  });

  const { mutateAsync } = useCreateCustomer();
  const { showSnackbar } = useAlert()!;
  const [createCustomerModalOpen, setCreateCustomerModalOpen] = useState(false);
  const { isLoading: loadingCountries, countries } = usePayinCountries();

  if (loadingCountries || !countries) return null;

  const handleSubmit = async (values: TFormData) => {
    try {
      await mutateAsync({ ...values, country: values.country.value });
      setCreateCustomerModalOpen(false);
      resetForm();
      showSnackbar({ title: 'A new customer has been created', alertType: 'success' });
    } catch (err) {
      showSnackbar({ title: (err as Error).message, alertType: 'failure' });
    }
  };

  return (
    <CustomModal trigger={children} open={createCustomerModalOpen} setOpen={setCreateCustomerModalOpen}>
      <DialogTitle>Create new customer</DialogTitle>

      <Divider />

      <form onSubmit={handleHookFormSubmit(handleSubmit)}>
        <DialogContent sx={{ maxHeight: '50vh' }}>
          <Stack gap={4}>
            <FormFieldWrapper label='country' labelHtmlFor='country'>
              <Controller
                name='country'
                control={control}
                render={({ field }) => (
                  <CountryAutocomplete
                    {...field}
                    countries={countries}
                    onChange={(_event, value) => field.onChange(value)}
                    error={!!errors.country}
                    helperText={errors.country?.value?.message}
                  />
                )}
              />
            </FormFieldWrapper>

            <FormFieldWrapper label='name' labelHtmlFor='name'>
              <TextField id='name' placeholder='Name' fullWidth {...register('name')} error={!!errors.name} helperText={errors.name?.message} />
            </FormFieldWrapper>

            <FormFieldWrapper label='customer type' labelHtmlFor='customerType'>
              <Controller
                name='customerType'
                control={control}
                render={({ field }) => (
                  <StyledSelect
                    {...field}
                    id='customerType'
                    fullWidth
                    error={!!errors.customerType}
                    helperText={errors.customerType?.message}
                    defaultValue=''
                    placeholder='Select a customer type'>
                    {Object.values(customerTypes).map((customerType, idx) => (
                      <MenuItem key={idx} sx={{ textTransform: 'capitalize' }} value={customerType}>
                        {customerType}
                      </MenuItem>
                    ))}
                  </StyledSelect>
                )}
              />
            </FormFieldWrapper>

            <FormFieldWrapper label='email' labelHtmlFor='email'>
              <TextField id='email' placeholder='Email' fullWidth {...register('email')} error={!!errors.email} helperText={errors.email?.message} />
            </FormFieldWrapper>

            <FormFieldWrapper label='phone' labelHtmlFor='phone'>
              <TextField id='phone' placeholder='Phone' fullWidth {...register('phone')} error={!!errors.phone} helperText={errors.phone?.message} />
            </FormFieldWrapper>

            <FormFieldWrapper label='address' labelHtmlFor='address'>
              <TextField
                id='address'
                placeholder='Address'
                fullWidth
                {...register('address')}
                error={!!errors.address}
                helperText={errors.address?.message}
              />
            </FormFieldWrapper>

            <FormFieldWrapper label='city' labelHtmlFor='city'>
              <TextField id='city' placeholder='City' fullWidth {...register('city')} error={!!errors.city} helperText={errors.city?.message} />
            </FormFieldWrapper>

            <FormFieldWrapper label='state' labelHtmlFor='state'>
              <TextField id='state' placeholder='State' fullWidth {...register('state')} error={!!errors.state} helperText={errors.state?.message} />
            </FormFieldWrapper>

            <FormFieldWrapper label='pincode' labelHtmlFor='pincode'>
              <TextField
                id='pincode'
                placeholder='Pincode'
                fullWidth
                {...register('pincode')}
                error={!!errors.pincode}
                helperText={errors.pincode?.message}
              />
            </FormFieldWrapper>
          </Stack>
        </DialogContent>

        <DialogActions>
          <CustomModal.ModalClose>
            <Button autoFocus variant='outlined'>
              close
            </Button>
          </CustomModal.ModalClose>

          <LoadingButton variant='contained' type='submit' disabled={!isDirty} isLoading={isSubmitting} sx={{ minWidth: '12rem' }}>
            create new customer
          </LoadingButton>
        </DialogActions>
      </form>
    </CustomModal>
  );
};

export default CreateCustomerModal;
