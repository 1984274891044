import apiClient from '../utils/api-client.ts';

export const getBankAccountForSelfTransfer = async (currency: string) => {
  const { data, error } = await apiClient.get('/v1/bank-account/withdrawal-account-for-currency', { currency: currency });
  if (error) {
    throw new Error(error.message);
  }

  return data;
};

export const getEscrowAccountForSelfTransfer = async (currency: string) => {
  const { data, error } = await apiClient.get('/v1/bank-account/escrow-account-for-currency', { currency: currency });
  if (error) {
    throw new Error(error.message);
  }

  return data;
};
