import { FC } from 'react';
import { Box, Card, CardContent, Typography } from '@mui/material';

import StatusChip, { StatusChipVariant } from './StatusChip.tsx';

interface DetailSummaryCardProps {
  summaryText: string;
  chipText: string;
  chipVariant: StatusChipVariant;
}

const DetailSummaryCard: FC<DetailSummaryCardProps> = ({ chipText, summaryText, chipVariant }) => (
  <Card
    variant='outlined'
    sx={{
      borderRadius: 3,
      px: 6,
      pt: 6,
    }}>
    <CardContent sx={{ p: 0 }}>
      <Box display='flex' justifyContent='space-between' alignItems='center'>
        <Typography fontStyle='normal' color='neutral.700' fontWeight={500} fontSize={18}>
          Summary
        </Typography>
        <StatusChip variant={chipVariant} text={chipText} />
      </Box>
      <Typography fontSize={16} fontWeight={400} fontStyle='normal' color='neutral.800' sx={{ maxWidth: 336, marginTop: 6 }}>
        {summaryText}
      </Typography>
    </CardContent>
  </Card>
);

export default DetailSummaryCard;
