import { QueryFunction, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import apiClient from '../utils/api-client';
import { TPaginationOptions } from '../types/PaginationTypes';
import { TFetchCustomersListFilters, TCustomersListResponse } from '../types/CustomerTypes';
import { ConvertKeysToCamelCase, convertToCamelCase, convertToSnakeCase } from '../utils/case-converter';
import queryKeys from '../constants/query-keys';

export const useCustomers = (filters: TFetchCustomersListFilters, paginationOptions: TPaginationOptions) => {
  const { isLoading, data, error, isError } = useQuery({
    queryKey: [queryKeys.CUSTOMERS_LIST, filters, paginationOptions],
    queryFn: fetchCustomers,
  });

  return { isLoading, customersResponse: data, error, isError };
};

export const useCreateCustomer = () => {
  const queryClient = useQueryClient();

  const { mutateAsync } = useMutation({
    mutationFn: createCustomer,
    retry: false,
    onSuccess() {
      queryClient.invalidateQueries({ queryKey: [queryKeys.CUSTOMERS_LIST] });
    },
  });
  return { mutateAsync };
};

const fetchCustomers: QueryFunction<
  ConvertKeysToCamelCase<TCustomersListResponse>,
  [string, TFetchCustomersListFilters, TPaginationOptions]
> = async ({ queryKey }) => {
  const [, filters, paginationOptions] = queryKey;
  const { data, error } = await apiClient.get('/v1/customers', convertToSnakeCase({ ...filters, ...paginationOptions }));
  if (error) throw new Error(error.message);
  return convertToCamelCase(data);
};

const createCustomer = async (customerPaylod: {
  name: string;
  customerType: string;
  email: string;
  phone: string;
  address: string;
  city: string;
  state: string;
  country: string;
  pincode: string;
}) => {
  const { data, error } = await apiClient.post('/v1/customers', convertToSnakeCase(customerPaylod));
  if (error) throw new Error(error.errorDetails);
  return data;
};
