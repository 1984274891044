import { Box, Typography, Stack } from '@mui/material';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';

import { NOTIFICATION_PATH, NOTIFICATION_TABS } from '../constants/route';
import Tabs from '../components/common/Tabs';

function Notifications() {
  const { pathname } = useLocation();
  const [activeTab, setActiveTab] = useState(-1);
  const navigate = useNavigate();
  useEffect(() => {
    const currentTab = NOTIFICATION_TABS.findIndex((tab) => pathname.includes(tab.path));
    setActiveTab(currentTab);

    if (currentTab < 0 && pathname.includes(NOTIFICATION_PATH)) {
      navigate(NOTIFICATION_TABS[0].path, { replace: true });
    }
  }, [pathname]);

  return (
    <Stack gap={1}>
      <Stack direction='row' gap={2} alignItems='center' justifyContent='space-between'>
        <Typography fontSize={24} fontWeight={500} sx={{ color: '#000', marginBottom: '15px' }}>
          Notifications Center
        </Typography>
      </Stack>

      <Tabs selectedTab={activeTab} tabs={NOTIFICATION_TABS} parentPath={NOTIFICATION_PATH} />
      <Box mt={8}>
        <Outlet />
      </Box>
    </Stack>
  );
}

export default Notifications;
