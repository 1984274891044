import { PiKey, PiPalette } from 'react-icons/pi';

export const pageSizeOptions = [5, 10, 20];

export const generalStatusTypes = {
  SUCCESS: { value: 'success', text: 'success' },
  FAIL: { value: 'failed', text: 'fail' },
  IN_PROGRESS: { value: 'pending', text: 'in progress' },
  ACTION_REQUIRED: { value: 'action_required', text: 'action required' },
};

export const checkoutButtonCustomisationTexts = {
  PAY_NOW: 'pay now',
  PLACE_YOUR_ORDER: 'place your order',
  MAKE_PAYMENT: 'make payment',
  BUY: 'buy',
  PURCHASE: 'purchase',
};

export const GLOMOPAY_CHECKOUT_DEFAULT_BG_COLOR = '#5368BD';

export const appSettings = [
  {
    linkTo: '/branding/elements',
    title: 'Branding',
    description: 'Customize your hosted pages to give it the look and feel that fits your brand.',
    icon: <PiPalette />,
  },
  {
    linkTo: '/api-keys-and-webhooks',
    title: 'API Keys and Webhooks',
    description: 'Access, view, and configure your API keys and Webhooks with ease',
    icon: <PiKey />,
  },
];

export const dummyCheckout = {
  order: {
    id: 'ORDER123456',
    amount: 123456,
    currency: 'USD',
  },
  business: {
    name: 'Loading...',
  },
  checkoutPersonalisation: {
    logo: null,
    bgColor: GLOMOPAY_CHECKOUT_DEFAULT_BG_COLOR,
  },
  customer: {
    name: 'John Doe',
    email: 'john.doe@example.com',
    phone: '+1234567890',
    country: 'US',
    address: '123 Main Street',
    city: 'New York',
    state: 'NY',
    pincode: '10001',
  },
  methods: {
    bankTransfer: {
      supportedData: {
        holderName: 'Acme Inc.',
        accountNumber: '1234567890',
        addressLine1: 'Bank Street 1',
        addressLine2: 'Suite 200',
        city: 'New York',
        country: 'US',
        pincode: '10001',
        bicPrimary: 'BKUS1234',
        bicSecondary: 'BKUS5678',
      },
    },
  },
  purposeCode: {
    code: 'PC001',
    description: 'E-commerce transaction',
  },
};
