import { useState } from 'react';
import { Box, Stack } from '@mui/material';
import { DateTime } from 'luxon';

import SearchField from '../common/SearchField';
import DateRangeSelect from '../common/DateRangeSelect';
import { useConversions } from '../../repositories/conversions-repository';
import { conversionDetailColumns } from '../../services/balance-conversions-service';
import CustomDataGrid from '../common/CustomDataGrid';
import DownloadReportButton from '../common/DownloadReportButton';

export default function FilteredConversions() {
  const [search, setSearch] = useState<string>('');
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 5,
    page: 0,
  });
  const [dateFilter, setDateFilter] = useState<{
    dateFrom: DateTime | undefined;
    dateTo: DateTime | undefined;
  }>({ dateFrom: undefined, dateTo: undefined });
  const { isLoading, conversionResponse, isError } = useConversions(
    search,
    dateFilter.dateFrom,
    dateFilter.dateTo,
    (paginationModel.page + 1).toString(),
    paginationModel.pageSize.toString(),
  );

  const downloadReportUrl = `/api/int/v1/balances/conversion-report?search=${search ? search : ''}&date_from=${dateFilter?.dateFrom ? dateFilter.dateFrom : ''}&date_to=${dateFilter?.dateTo ? dateFilter.dateTo : ''}&page=${paginationModel.page + 1}&per_page=${paginationModel.pageSize}`;

  return (
    <Stack direction='column'>
      <Stack direction='row' gap={3} sx={{ alignItems: 'center' }}>
        <SearchField
          label='Enter conversion id'
          onChange={(searchValue) => {
            setSearch(searchValue);
          }}
        />
        <DateRangeSelect
          onDateRangeChange={(dateFrom, dateTo) => {
            setDateFilter({ dateFrom, dateTo });
          }}
        />
        <DownloadReportButton url={downloadReportUrl} sx={{ ml: 'auto' }} />
      </Stack>
      <Box
        sx={{
          height: conversionResponse?.totalCount ? 'auto' : '24rem',
          width: '100%',
          mt: 5,
          borderRadius: '12px',
          background: 'transparent',
        }}>
        <CustomDataGrid
          isLoading={isLoading}
          isError={isError}
          rows={conversionResponse?.conversions || []}
          rowCount={conversionResponse?.totalCount}
          columns={conversionDetailColumns}
          pageSizeOptions={[5, 10, 20]}
          paginationModel={paginationModel}
          onPaginationChange={setPaginationModel}
          noRowsOptions={{ title: 'No payments made', subtitle: 'No payment transactions have been made. Make one today!' }}
        />
      </Box>
    </Stack>
  );
}
