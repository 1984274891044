import { FC } from 'react';
import { CircularProgress, Button, ButtonProps } from '@mui/material';

interface LoadingButtonProps extends ButtonProps {
  isLoading?: boolean;
}

const LoadingButton: FC<LoadingButtonProps> = ({ isLoading = false, children, disabled, ...props }) => (
  <Button {...props} disabled={isLoading || disabled}>
    {isLoading ? <CircularProgress size='1.65rem' color='inherit' /> : children}
  </Button>
);

export default LoadingButton;
