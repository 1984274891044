import { FormControl, FormHelperText, IconButton, InputAdornment, OutlinedInput } from '@mui/material';
import React from 'react';
import { PiEye, PiEyeSlash } from 'react-icons/pi';

function PasswordTextField({
  fieldLabel,
  keyValue,
  value,
  onChange,
  error,
  errorMesage,
  placeholder,
}: {
  fieldLabel: string;
  keyValue: string;
  value: string;
  onChange: (key: string, value: string) => void;
  error: boolean;
  errorMesage: string;
  placeholder: string;
}) {
  const [showPassword, setShowPassword] = React.useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);

  return (
    <FormControl sx={{ m: 1 }} variant='outlined'>
      <FormHelperText sx={{ fontSize: '16px', color: 'neutral.900', marginBottom: '0.5rem', marginLeft: 0, fontWeight: '500' }}>
        {fieldLabel}
      </FormHelperText>
      <OutlinedInput
        placeholder={placeholder}
        sx={{ borderRadius: 3 }}
        error={error}
        id={keyValue}
        aria-describedby={keyValue}
        inputProps={{
          'aria-label': 'weight',
        }}
        value={value}
        onChange={(e) => onChange(keyValue, e.target.value)}
        type={showPassword ? 'text' : 'password'}
        endAdornment={
          <InputAdornment position='end'>
            <IconButton aria-label={showPassword ? 'hide the password' : 'display the password'} onClick={handleClickShowPassword} edge='end'>
              {showPassword ? <PiEyeSlash /> : <PiEye />}
            </IconButton>
          </InputAdornment>
        }
      />
      {error && (
        <FormHelperText id='outlined-password-helper-text' sx={{ color: 'red' }}>
          {errorMesage}
        </FormHelperText>
      )}
    </FormControl>
  );
}

export default PasswordTextField;
