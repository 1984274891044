import { useEffect } from 'react';

import { redirectToRailsApp } from '../../utils/route-utils';

function RedirectToRailsApp({ path }: { path: string }) {
  useEffect(() => {
    redirectToRailsApp(path);
  }, [path]);

  return <h5>Loading...</h5>;
}

export default RedirectToRailsApp;
