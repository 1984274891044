import { Button } from '@mui/material';
import { useState } from 'react';

import AddBalanceModal from './AddBalanceModal.tsx';

function AddBalanceButton() {
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <>
      <Button variant='contained' onClick={handleOpen}>
        Add Balance
      </Button>
      <AddBalanceModal open={open} handleClose={handleClose} />
    </>
  );
}

export default AddBalanceButton;
