import { z } from 'zod';

export const emailSchema = z.string().email('Invalid email address');

export const urlSchema = z
  .string()
  .min(1, 'URL is required')
  .regex(/^https:\/\/(?:www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b[-a-zA-Z0-9()@:%_+.~#?&/=]*$/, 'Invalid URL format');

export const pincodeSchema = z.string().regex(/^[A-Za-z0-9\s-]+$/, 'Invalid pincode');

export const phoneSchema = z.string().regex(/^\+?[0-9]{10,15}$/, 'Invalid phone number');

export const fileSchema = z.instanceof(File, { message: 'File is required' });

export const checkoutCustomisationFormSchema = z.object({
  bgColor: z
    .string()
    .min(1, 'Background color is required')
    .regex(/^#([0-9A-Fa-f]{3}|[0-9A-Fa-f]{6})$/, 'Enter a valid hex color (e.g., #ff0000)'),
  logo: fileSchema.optional().nullable(),
});

export type TCheckoutCustomisationFormValues = z.infer<typeof checkoutCustomisationFormSchema>;
