import React, { useState } from 'react';
import { IconButton, Menu, MenuItem } from '@mui/material';
import { PiDotsThree } from 'react-icons/pi';
import { useNavigate } from 'react-router-dom';

const ContextMenu = ({ row }: { row: { id: string; url: string; secret: string } }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const navigate = useNavigate();

  const handleContextMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    event.stopPropagation();
  };

  const handleContextMenuClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (action: string) => {
    if (action === 'edit') {
      navigate(`/webhooks/${row.id}/edit`, { state: { url: row.url, secret: row.secret } });
    }
    handleContextMenuClose();
  };

  return (
    <div data-testid='context-menu-container'>
      <IconButton
        data-testid='context-menu-button'
        onClick={handleContextMenuClick}
        size='small'
        aria-controls={open ? 'row-menu' : undefined}
        aria-haspopup='true'
        aria-expanded={open ? 'true' : undefined}>
        <PiDotsThree className='w-4 h-4' />
      </IconButton>
      <Menu
        data-testid='context-menu'
        id='row-menu'
        anchorEl={anchorEl}
        open={open}
        onClose={handleContextMenuClose}
        onClick={(e) => e.stopPropagation()}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}>
        <MenuItem data-testid='rotate-key-option' onClick={() => handleMenuItemClick('edit')}>
          Edit
        </MenuItem>
      </Menu>
    </div>
  );
};

export default ContextMenu;
