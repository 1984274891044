import { FC, useState } from 'react';
import { Box, MenuItem, Stack } from '@mui/material';
import { DateTime } from 'luxon';
import { useNavigate } from 'react-router-dom';

import CustomDataGrid from '../common/CustomDataGrid';
import SearchField from '../common/SearchField';
import DateRangeSelect from '../common/DateRangeSelect';
import { useRefunds } from '../../repositories/refund-repository';
import { refundsListColumns } from '../../services/refund-service';
import { generalStatusTypes } from '../../constants/app-constants';
import StyledSelect from '../ui/StyledSelect';
import { getIncrementedPaginationModel } from '../../utils';
import DownloadReportButton from '../common/DownloadReportButton';

const FilteredRefunds: FC = () => {
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 5,
    page: 0,
  });
  const [search, setSearch] = useState<string>('');
  const [status, setStatus] = useState<string>('');
  const [dateFilter, setDateFilter] = useState<{
    dateFrom: DateTime | undefined;
    dateTo: DateTime | undefined;
  }>({ dateFrom: undefined, dateTo: undefined });

  const navigate = useNavigate();

  const { refundsResponse, isLoading, isError } = useRefunds(
    {
      search,
      status,
      dateFrom: dateFilter.dateFrom?.toString(),
      dateTo: dateFilter.dateTo?.toString(),
    },
    getIncrementedPaginationModel(paginationModel),
  );

  const handleRowClick = (refundId: string) => {
    navigate(`/refunds/${refundId}`);
  };

  const downloadReportUrl = `/api/int/v1/refunds/report?search=${search}&page=${paginationModel.page + 1}&page_size=${paginationModel.pageSize}&date_from=${dateFilter.dateFrom || ''}&date_to=${dateFilter.dateTo || ''}&status=${status}`;

  return (
    <div style={{ marginTop: '2rem' }}>
      <Stack direction='row' gap={3} alignItems='center'>
        <SearchField label='Search by refund id' onChange={(searchValue) => setSearch(searchValue)} />

        <DateRangeSelect
          onDateRangeChange={(dateFrom, dateTo) => {
            setDateFilter({ dateFrom, dateTo });
          }}
        />

        <StyledSelect
          defaultValue=''
          sx={{ ml: 'auto', minWidth: '12rem', height: '2.2rem', borderRadius: '8px' }}
          value={status}
          onChange={(e) => setStatus(e.target.value as string)}
          onClearButtonClick={() => setStatus('')}
          placeholder='status'>
          {Object.values(generalStatusTypes).map((statusType, idx) => (
            <MenuItem key={idx} value={statusType.value} sx={{ textTransform: 'capitalize' }}>
              {statusType.text}
            </MenuItem>
          ))}
        </StyledSelect>

        <DownloadReportButton url={downloadReportUrl} disabled={!refundsResponse?.totalCount} />
      </Stack>

      <Box sx={{ mt: 5, borderRadius: '12px', height: refundsResponse?.totalCount ? 'auto' : '24rem' }}>
        <CustomDataGrid
          isLoading={isLoading}
          isError={isError}
          rows={refundsResponse?.refunds || []}
          rowCount={refundsResponse?.totalCount}
          columns={refundsListColumns}
          paginationModel={paginationModel}
          onPaginationChange={setPaginationModel}
          onRowClick={handleRowClick}
          noRowsOptions={{ title: 'No refunds found', subtitle: 'You do not have any refunds' }}
        />
      </Box>
    </div>
  );
};

export default FilteredRefunds;
