import axios from 'axios';

import { getFilenameFromContentDisposition } from './file-utils';

const CORE_SERVER_BASE_API_URL = `${import.meta.env.GLOMO_RAILS_APP_BASE_URL}/api/int`;

const trimLeadingSlash = (url: string) => {
  return url.replace(/^\//, '');
};

interface SuccessResponse<T> {
  data: T;
  filename?: never;
  error?: never;
}

interface FileDownloadResponse {
  data: Blob;
  filename: string;
  error?: never;
}

export interface ErrorResponse {
  data?: never;
  filename?: never;
  error: {
    status: number;
    message: string;
    errorDetails?: string;
  };
}

type ApiResponse<T> = SuccessResponse<T> | FileDownloadResponse | ErrorResponse;

const withErrorHandler = <T = unknown>(
  fn: (...args: any[]) => Promise<{ data: T }>,
): ((...args: Parameters<typeof fn>) => Promise<ApiResponse<T>>) => {
  return async (...args) => {
    try {
      return fn(...args);
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.response) {
          // The request was made, and the server responded with a status code
          // that falls out of the range of 2xx
          const statusCode = error.response.status;
          const errorData = error.response.data;
          return { error: { status: statusCode, message: errorData.error, errorDetails: errorData.message } };
        } else if (error.request) {
          // The request was made, but no response was received
          throw error;
        } else {
          // Something happened in setting up the request that triggered an error
          throw error;
        }
      } else {
        // Handle errors that are not Axios errors
        throw error;
      }
    }
  };
};

const apiClient = {
  get: withErrorHandler(async (url: string, params = {}) => {
    axios.defaults.withCredentials = true;
    const response = await axios.get(`${CORE_SERVER_BASE_API_URL}/${trimLeadingSlash(url)}`, { params });
    return { data: response.data };
  }),
  getFile: withErrorHandler(async (url: string, params = {}) => {
    axios.defaults.withCredentials = true;
    const response = await axios.get(`${CORE_SERVER_BASE_API_URL}/${trimLeadingSlash(url)}`, { params, responseType: 'blob' });
    let filename;
    const disposition = response.headers['content-disposition'];
    if (disposition) {
      filename = getFilenameFromContentDisposition(disposition);
    }
    return { data: response.data, filename };
  }),
  post: withErrorHandler(async (url: string, data = {}) => {
    axios.defaults.withCredentials = true;
    const response = await axios.post(`${CORE_SERVER_BASE_API_URL}/${trimLeadingSlash(url)}`, data);
    return { data: response.data };
  }),
  patch: withErrorHandler(async (url: string, data = {}) => {
    axios.defaults.withCredentials = true;
    const response = await axios.patch(`${CORE_SERVER_BASE_API_URL}/${trimLeadingSlash(url)}`, data);
    return { data: response.data };
  }),
  put: withErrorHandler(async (url: string, data = {}) => {
    axios.defaults.withCredentials = true;
    const response = await axios.put(`${CORE_SERVER_BASE_API_URL}/${trimLeadingSlash(url)}`, data);
    return { data: response.data };
  }),
};

export default apiClient;
