import { useQuery } from '@tanstack/react-query';
import DineroFactory, { Currency } from 'dinero.js';
import Decimal from 'decimal.js';

import apiClient from '../utils/api-client';
import { AccountBalances } from '../types/AccountTypes';
import { ConvertBalanceQuote } from '../types/BalanceTypes';
import { queryClient } from '../utils/query-client';

export function useBalances() {
  const { isLoading, data: balances, isError } = useQuery({ queryKey: ['balances'], queryFn: fetchBalances });
  return { isLoading, balances, isError };
}

export function useCurrencies() {
  const {
    isLoading,
    data: currencies,
    isError,
  } = useQuery({
    queryKey: ['currencies'],
    queryFn: fetchCurrencies,
    staleTime: 1000 * 60 * 60, // 1 hour
  });
  return { isLoading, currencies, isError };
}

const fetchBalances = async (): Promise<AccountBalances> => {
  const { data } = await apiClient.get('/v1/balances');
  return data;
};

const fetchCurrencies = async (): Promise<Array<Currency>> => {
  const { data } = await apiClient.get('/v1/account/supported-currencies');
  return data;
};

export const getConvertBalanceQuote = async (
  sourceCurrency: Currency,
  targetCurrency: Currency,
  sourceAmount?: number,
  targetAmount?: number,
): Promise<ConvertBalanceQuote> => {
  console.log(`Getting quote for ${sourceCurrency} to ${targetCurrency} and sourceAmount: ${sourceAmount} and targetAmount: ${targetAmount}`);
  const { data, error } = await apiClient.post('/v1/balances/convert/quote', {
    source_currency: sourceCurrency,
    source_amount: sourceAmount,
    target_currency: targetCurrency,
    target_amount: targetAmount,
  });
  if (error) {
    throw new Error(error.message);
  }
  const { source, target, fee, mid_market_rate } = data;

  return {
    source: DineroFactory({ amount: source.cents, currency: source.currency_iso }),
    target: DineroFactory({ amount: target.cents, currency: target.currency_iso }),
    totalFee: DineroFactory({ amount: fee.total_fee.cents, currency: fee.total_fee.currency_iso }),
    midMarketRate: new Decimal(mid_market_rate).toDecimalPlaces(2).toNumber(),
  };
};

export const updatePassword = async (formValues: { currentPassword: string; newPassword: string; newPasswordConfirmation: string }) => {
  const { data, error } = await apiClient.patch('/v1/user/update-password', {
    current_password: formValues.currentPassword,
    password: formValues.newPassword,
    password_confirmation: formValues.newPasswordConfirmation,
  });
  if (error) {
    throw error;
  }
  return data;
};

export const submitConvertBalance = async (sourceCurrency: Currency, targetCurrency: Currency, sourceAmount: number, targetAmount: number) => {
  const { data, error } = await apiClient.post('/v1/balances/convert', {
    source_currency: sourceCurrency,
    source_amount: sourceAmount,
    target_currency: targetCurrency,
    target_amount: targetAmount,
  });
  if (error) {
    throw new Error(error.message);
  }

  queryClient.invalidateQueries({ queryKey: ['balances'] });
  queryClient.invalidateQueries({ queryKey: ['conversions'] });
  return data;
};
