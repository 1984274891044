import { Typography } from '@mui/material';
import { FC } from 'react';

import SettingCard from '../components/SettingCard';
import { appSettings } from '../constants/app-constants';

const SettingsPage: FC = () => (
  <div>
    <Typography variant='h5' fontWeight={500} textTransform='capitalize' mb='2.25rem'>
      settings
    </Typography>

    <div style={{ display: 'grid', gridTemplateColumns: 'repeat(3,1fr)', gap: '3.25rem', maxHeight: '190px' }}>
      {appSettings.map((setting, idx) => (
        <SettingCard key={idx} setting={setting} />
      ))}
    </div>
  </div>
);

export default SettingsPage;
