import { GridColDef } from '@mui/x-data-grid';
import { Stack, Typography } from '@mui/material';
import { PiInfo } from 'react-icons/pi';

import TokenCell from '../components/api-keys/TokenCell.tsx';
import { formatDateTime, formatTimeRemaining } from '../utils/datetime-utils.ts';
import TableContextMenu from '../components/api-keys/TableContextMenu.tsx';

const isKeyExpiringInAWeek = (expiresAt: string) => {
  const expiresAtDate = new Date(expiresAt);
  const weekFromNow = new Date();
  weekFromNow.setDate(weekFromNow.getDate() + 7);
  return expiresAtDate < weekFromNow;
};

export const apiKeyListColumns: GridColDef[] = [
  {
    field: 'publishable',
    headerName: 'Name',
    maxWidth: 150,
    flex: 1,
    renderCell: (params) => (
      <Stack>
        <div>{params.row.publishable ? 'Public Key' : 'Secret Key'}</div>
        {isKeyExpiringInAWeek(params.row.expiresAt) && (
          <Stack direction='row' gap={2} pt={2}>
            <PiInfo color='red' height='12px' />
            <Typography fontSize={12} fontWeight={500} color='error.500'>
              Expires in {formatTimeRemaining(params.row.expiresAt)}
            </Typography>
          </Stack>
        )}
      </Stack>
    ),
  },
  {
    field: 'key',
    headerName: 'Token',
    minWidth: 400,
    flex: 1,
    renderCell: (params) => <TokenCell value={params.value} row={params.row} />,
  },
  {
    field: 'createdAt',
    headerName: 'Created On',
    minWidth: 250,
    maxWidth: 250,
    flex: 1,
    valueGetter: (val) => formatDateTime(val),
  },
  {
    field: 'actions',
    headerName: '',
    minWidth: 100,
    maxWidth: 100,
    sortable: false,
    filterable: false,
    renderCell: (params) => !isKeyExpiringInAWeek(params.row.expiresAt) && <TableContextMenu row={params.row} />,
  },
];
