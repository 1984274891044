import { Box, Collapse, Fade, Grid2 as Grid, IconButton, List, ListItem, Stack, Typography } from '@mui/material';
import { FC, ReactNode, useRef, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { RiUploadCloud2Line } from 'react-icons/ri';
import { GoAlert } from 'react-icons/go';
import { PiX } from 'react-icons/pi';
import { TransitionGroup } from 'react-transition-group';
import { DateTime } from 'luxon';

import useAlert from '../../hooks/useAlert';

interface FileUploaderProps {
  id: string;
  title?: string;
  multiple?: boolean;
  onChange?: (files: File[] | null) => void;
  required?: boolean;
  error?: string;
  touched?: boolean;
  maxFiles?: number;
  hint?: ReactNode;
  accept?: string;
  files?: File[];
  maxFileSizeInKB?: number;
}

const FileUploader: FC<FileUploaderProps> = ({
  id,
  onChange,
  title,
  error,
  hint,
  files: controlledFiles,
  required = false,
  multiple = false,
  maxFiles = 1,
  accept = '',
  maxFileSizeInKB = Infinity,
}) => {
  const [_files, setFiles] = useState<File[]>(controlledFiles || []);
  const files = controlledFiles || _files;
  const { showSnackbar } = useAlert()!;
  const fileInputRef = useRef<HTMLInputElement>(null);

  const remainingFileUploadCapacity = maxFiles - files.length;

  const handleFileChange = (newFiles: File[]) => {
    // Validate max number of files
    if (newFiles.length > remainingFileUploadCapacity) {
      const errorMessage = remainingFileUploadCapacity
        ? `Only ${remainingFileUploadCapacity}${files.length ? ' more' : ''} file${remainingFileUploadCapacity > 1 ? 's' : ''} can be uploaded for this field`
        : 'No more files can be uploaded for this field';

      return showSnackbar({ title: errorMessage, alertType: 'failure' });
    }

    // Check for file size
    for (const file of newFiles) {
      if (file.size > maxFileSizeInKB * 1024) {
        return showSnackbar({ title: `File size exceeds ${maxFileSizeInKB} KB`, alertType: 'failure' });
      }
    }

    // Check for duplicate files
    const allowedFiles: File[] = [];
    newFiles.forEach((curFile) => {
      if (!files.find((f) => f.name === curFile.name)) allowedFiles.push(curFile);
    });

    setFiles((prevFiles) => [...prevFiles, ...allowedFiles]);
    onChange?.([...files, ...allowedFiles]);
  };

  const handleClick = () => fileInputRef.current?.click();

  const handleFileRemove = (index: number) => {
    const filesCopy = [...files];
    filesCopy.splice(index, 1);
    setFiles(filesCopy);
    onChange?.(filesCopy.length ? filesCopy : null);
  };

  const { getRootProps, isDragActive } = useDropzone({
    multiple,
    noClick: true,
    onDrop: handleFileChange,
    onDropRejected: (error) => {
      console.log(error);
    },
  });

  return (
    <Box {...getRootProps()} id={id}>
      {/* Hidden HTML file input element */}
      <input
        id={`file-uploader-${id}`}
        type='file'
        onChange={(e) => handleFileChange(Array.from(e.target.files || []))}
        hidden
        required={required}
        value=''
        ref={fileInputRef}
        multiple={multiple}
        accept={accept}
        data-testid={`file-uploader-${id}`}
      />

      {/* Custom file upload container */}
      <Stack
        onClick={handleClick}
        alignItems='center'
        justifyContent='center'
        sx={{
          position: 'relative',
          borderRadius: '12px',
          border: '1px dashed',
          borderColor: 'primary.main',
          padding: '3rem 1rem',
          cursor: 'default',
          overflow: 'hidden',
        }}>
        {/* Give feedback when drag is active */}
        <Fade in={isDragActive}>
          <Stack alignItems='center' justifyContent='center' sx={{ position: 'absolute', inset: 0, zIndex: 50, backgroundColor: 'neutral.50' }}>
            <Typography>Drop it like it's hot!</Typography>
          </Stack>
        </Fade>

        {/* Display errors if any */}
        <Stack
          direction='row'
          alignItems='center'
          gap={2}
          sx={{
            position: 'absolute',
            left: '.5rem',
            top: '.5rem',
            borderRadius: '4px',
            padding: '.3rem .6rem',
            opacity: error ? 1 : 0,
            transition: 'opacity',
            backgroundColor: 'error.50',
            color: 'error.300',
          }}>
          <GoAlert />
          <Typography variant='caption'>{error}</Typography>
        </Stack>

        {/* Title */}
        <Stack direction='row' alignItems='center' gap={2} mb={2} color='primary.main'>
          <RiUploadCloud2Line size={24} />
          <Typography variant='subtitle2'>{title ?? 'Upload file'}</Typography>
        </Stack>

        {/* Subtitle */}
        <Typography variant='caption' textAlign='center' color='neutral.800'>
          Drag & drop your files here or Click to{' '}
          <Typography component='span' variant='caption' color='primary' sx={{ textDecoration: 'underline' }}>
            browse
          </Typography>{' '}
          {files.length ? 'more' : ''} files from your device
        </Typography>

        {/* Display currently uploaded files if any */}
        <List sx={{ mt: files.length ? 3 : 0, width: '100%' }}>
          <TransitionGroup>
            {files.map((file, idx) => (
              <Collapse key={idx}>
                <ListItem>
                  <Grid
                    container
                    spacing={2}
                    sx={{
                      position: 'relative',
                      backgroundColor: 'neutral.50',
                      p: 2.5,
                      borderRadius: '4px',
                      border: '1px solid',
                      borderColor: 'neutral.100',
                      width: '100%',
                      color: 'neutral.800',
                    }}>
                    <IconButton
                      size='small'
                      data-testid={`file-uploader-remove-${idx}`}
                      onClick={(e) => {
                        e.stopPropagation();
                        handleFileRemove(idx);
                      }}
                      sx={{
                        position: 'absolute',
                        zIndex: 10,
                        right: 0,
                        top: 0,
                        transform: 'translateX(50%) translateY(-50%)',
                        backgroundColor: 'neutral.100',
                        '&:hover': { backgroundColor: 'primary.main', color: 'neutral.50' },
                      }}>
                      <PiX size={10} />
                    </IconButton>
                    <Grid size={6}>
                      <Typography sx={{ width: '90%', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>{file.name}</Typography>
                    </Grid>
                    <Grid size={6} textAlign='right'>
                      <Typography>{(file.size / (1024 * 1024)).toFixed(2)} MB</Typography>
                    </Grid>
                    <Grid size={6}>
                      <Typography>{file.type}</Typography>
                    </Grid>
                    <Grid size={6} textAlign='right'>
                      <Typography>{DateTime.fromMillis(file.lastModified).toFormat('dd/mm/yyyy')}</Typography>
                    </Grid>
                  </Grid>
                </ListItem>
              </Collapse>
            ))}
          </TransitionGroup>
        </List>
      </Stack>

      {hint && <Box mt={2}>{hint}</Box>}
    </Box>
  );
};

export default FileUploader;
