import { useEffect, useState } from 'react';
import { Box, Stack } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { DateTime } from 'luxon';

import SearchField from '../common/SearchField';
import DateRangeSelect from '../common/DateRangeSelect';
import { usePayments } from '../../repositories/payments-repository';
import { FilteredPaymentDetails } from '../../types/PaymentTypes';
import { generatePaymentDetails, paymentDetailColumns } from '../../services/payment-service';
import CustomDataGrid from '../common/CustomDataGrid';
import DownloadReportButton from '../common/DownloadReportButton';

const FETCH_ONLY_FUND_ADDITION_PAYMENTS = true;

export default function FilteredPayments() {
  const navigate = useNavigate();
  const [search, setSearch] = useState<string>('');
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 5,
    page: 0,
  });
  const [currentRows, setCurrentRows] = useState<FilteredPaymentDetails[]>([]);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [dateFilter, setDateFilter] = useState<{
    dateFrom: DateTime | undefined;
    dateTo: DateTime | undefined;
  }>({ dateFrom: undefined, dateTo: undefined });
  const { isLoading, paymentsResponse } = usePayments(
    search,
    dateFilter.dateFrom,
    dateFilter.dateTo,
    (paginationModel.page + 1).toString(),
    paginationModel.pageSize.toString(),
    FETCH_ONLY_FUND_ADDITION_PAYMENTS,
  );

  useEffect(() => {
    if (paymentsResponse) {
      const newRows = generatePaymentDetails(paymentsResponse.payments);
      setCurrentRows(newRows);
      setTotalCount(paymentsResponse.total_count);
    }
  }, [paymentsResponse]);
  const redirectToPayment = (paymentId: string) => {
    navigate(`/payments/${paymentId}`);
  };
  const downloadReportUrl = `/api/int/v1/reports/fund-addition-report?search=${search ? search : ''}&date_from=${dateFilter?.dateFrom ? dateFilter.dateFrom : ''}&date_to=${dateFilter?.dateTo ? dateFilter.dateTo : ''}&page=${paginationModel.page + 1}&page_size=${paginationModel.pageSize}`;
  return (
    <Stack>
      <Stack direction='row' gap={3} sx={{ alignItems: 'center' }}>
        <SearchField
          label='Enter payment id'
          onChange={(searchValue) => {
            setSearch(searchValue);
          }}
        />
        <DateRangeSelect
          onDateRangeChange={(dateFrom, dateTo) => {
            setDateFilter({ dateFrom, dateTo });
          }}
        />
        <DownloadReportButton url={downloadReportUrl} sx={{ ml: 'auto' }} />
      </Stack>
      <Box sx={{ mt: 5, borderRadius: '12px', height: totalCount ? 'auto' : '24rem' }}>
        <CustomDataGrid
          isLoading={isLoading}
          rows={currentRows}
          rowCount={totalCount}
          columns={paymentDetailColumns}
          pageSizeOptions={[5, 10, 20]}
          paginationModel={paginationModel}
          onPaginationChange={setPaginationModel}
          onRowClick={redirectToPayment}
          noRowsOptions={{ title: 'No payments made', subtitle: 'No payment transactions have been made. Make one today!' }}
        />
      </Box>
    </Stack>
  );
}
