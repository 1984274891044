import { TimelineOppositeContent } from '@mui/lab';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import { Card, CardContent, Stack, Typography } from '@mui/material';
import TimelineItem from '@mui/lab/TimelineItem';

import { formatDateTime } from '../../utils/datetime-utils.ts';

export default function TrackerElement({ text, date }: { text: string; date: string }) {
  return (
    <TimelineItem>
      <TimelineOppositeContent />
      <TimelineSeparator>
        <TimelineDot />
        <TimelineConnector />
      </TimelineSeparator>
      <TimelineContent>
        <Card
          variant='outlined'
          sx={{
            borderRadius: 3,
            backgroundColor: '#FAFAFA',
            p: 3,
          }}>
          <CardContent sx={{ p: 0, '&:last-child': { pb: 0 } }}>
            <Stack direction='column' gap={1}>
              <Stack direction='row'>
                <Typography fontStyle='normal' color='neutral.500' fontWeight={500} fontSize={16}>
                  {text}
                </Typography>
              </Stack>
              <Stack direction='row'>
                <Typography fontSize={14} fontWeight={400} fontStyle='normal' color='neutral.400'>
                  {formatDateTime(date)}
                </Typography>
              </Stack>
            </Stack>
          </CardContent>
        </Card>
      </TimelineContent>
    </TimelineItem>
  );
}
