import { FC } from 'react';

import { BaseAlert, BaseAlertProps } from './BaseAlert';

type SuccessAlertProps = Omit<BaseAlertProps, 'lottieUrl'>;

const SuccessAlert: FC<SuccessAlertProps> = (props) => (
  <BaseAlert {...props} lottieUrl='https://lottie.host/fdfbd0b0-78a7-4763-a60f-53dcb37bc6e7/SVS3GDoLyt.lottie' />
);

export default SuccessAlert;
