import { FC, useState } from 'react';
import { Box, MenuItem, Stack } from '@mui/material';
import { DateTime } from 'luxon';
import { useNavigate } from 'react-router-dom';

import CustomDataGrid from '../common/CustomDataGrid';
import SearchField from '../common/SearchField';
import DateRangeSelect from '../common/DateRangeSelect';
import { ordersListColumns, orderStatusTypes } from '../../services/order-service';
import StyledSelect from '../ui/StyledSelect';
import { getIncrementedPaginationModel } from '../../utils';
import { useOrders } from '../../repositories/order-repository';

const FilteredOrders: FC = () => {
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 5,
    page: 0,
  });
  const [search, setSearch] = useState<string>('');
  const [status, setStatus] = useState<string>('');
  const [dateFilter, setDateFilter] = useState<{
    dateFrom: DateTime | undefined;
    dateTo: DateTime | undefined;
  }>({ dateFrom: undefined, dateTo: undefined });

  const navigate = useNavigate();

  const { ordersResponse, isLoading, isError } = useOrders(
    {
      search,
      status,
      dateFrom: dateFilter.dateFrom?.toString(),
      dateTo: dateFilter.dateTo?.toString(),
    },
    getIncrementedPaginationModel(paginationModel),
  );

  const handleRowClick = (orderId: string) => {
    navigate(`/orders/${orderId}`);
  };

  return (
    <div style={{ marginTop: '2rem' }}>
      <Stack direction='row' gap={3} alignItems='center'>
        <SearchField label='Search by order id' onChange={(searchValue) => setSearch(searchValue)} />

        <DateRangeSelect
          onDateRangeChange={(dateFrom, dateTo) => {
            setDateFilter({ dateFrom, dateTo });
          }}
        />

        <StyledSelect
          defaultValue=''
          sx={{ ml: 'auto', minWidth: '12rem', height: '2.2rem', borderRadius: '8px' }}
          value={status}
          onChange={(e) => setStatus(e.target.value as string)}
          onClearButtonClick={() => setStatus('')}
          data-testid='status-filter'
          placeholder='status'>
          {Object.values(orderStatusTypes).map((statusType, idx) => (
            <MenuItem key={idx} value={statusType.value} sx={{ textTransform: 'capitalize' }}>
              {statusType.text}
            </MenuItem>
          ))}
        </StyledSelect>
      </Stack>

      <Box sx={{ mt: 5, borderRadius: '12px' }}>
        <CustomDataGrid
          isLoading={isLoading}
          isError={isError}
          rows={ordersResponse?.orders || []}
          rowCount={ordersResponse?.totalCount}
          columns={ordersListColumns}
          paginationModel={paginationModel}
          onPaginationChange={setPaginationModel}
          onRowClick={handleRowClick}
          noRowsOptions={{ title: 'No orders found', subtitle: 'You do not have any orders' }}
        />
      </Box>
    </div>
  );
};

export default FilteredOrders;
