import { Box, Button, Stack } from '@mui/material';
import { PiCopy } from 'react-icons/pi';
import { useState } from 'react';

import { fetchApiKey } from '../../repositories/api-key-repository.tsx';
import BlurText from '../common/BlurText.tsx';
import useAlert from '../../hooks/useAlert.ts';

import RevealApiKeyModal from './RevealApiKeyModal.tsx';

const TokenCell = ({ value, row }: { value: string; row: { id: string; publishable: boolean; revealed: boolean } }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [revealedToken, setRevealedToken] = useState('');
  const { showSnackbar } = useAlert()!;

  const handleReveal = async () => {
    try {
      const apiKeyResponse = await fetchApiKey(row.id);
      setRevealedToken(apiKeyResponse?.key || '');
      setIsOpen(true);
    } catch (e) {
      showSnackbar({
        alertType: 'failure',
        title: (e as { message: string }).message,
      });
    }
  };

  const unsetApiKey = () => {
    setRevealedToken('');
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(value);
  };
  return (
    <Stack data-testid='token-cell-container'>
      <Box
        data-testid='token-value-box'
        flexWrap='wrap'
        sx={{
          overflowWrap: 'break-word',
          p: 2,
          background: 'rgba(246, 246, 246, 0.43)',
          whiteSpace: 'break-spaces',
          wordBreak: 'break-all',
          lineHeight: '24px',
        }}>
        {!row.publishable && !row.revealed ? (
          <>
            <BlurText variant='custom' buttonLabel='Reveal Key' onButtonClick={handleReveal}>
              testyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxMjM0NTY3ODkwIiwibmFtZSI6IkpvaG4gRG9lIiwiYWRtaW4iOnRydWUsImlhdCI6MTUxNjIzOTAyMn0.NHVaYe26MbtOYhSKkoKYdFVomg4i8ZJd8_-RU8VNbftc4TSMb4bXP3l3YlNWACwyXPGffz5aXHc6lty1Y2t4SWRqGteragsVdZufDn5BlnJl9pdR_kdVFUsra2rWKEofkZeIC4yWytE58sMIihvo9H1ScmmVwBcQP6XETqYd0aSHp1gOa9RdUPDvoXQ5oqygTqVtxaDr6wUFKrKItgBMzWIdNZ6y7O9E0DhEPTbE9rfBo6KTFsHAZnMg4k68CDp2woYIaXbmYTWcvbzIuHO7_37GT79XdIwkm95QJ7hYC9RiwrV7mesbY4PAahERJawntho0my942XheVLmGwLMBkQQ
            </BlurText>
          </>
        ) : (
          value
        )}
        <RevealApiKeyModal publishable={false} open={isOpen} setOpen={setIsOpen} token={revealedToken.toString() || ''} onClose={unsetApiKey} />
      </Box>
      {row.publishable && (
        <Stack data-testid='copy-container' direction='row' justifyContent='flex-end' alignItems='center'>
          <Button variant='text' data-testid='copy-button' sx={{ fontSize: 10 }} size='small' onClick={handleCopy} endIcon={<PiCopy />}>
            Copy
          </Button>
        </Stack>
      )}
    </Stack>
  );
};

export default TokenCell;
