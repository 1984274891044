import { FC, useState } from 'react';
import { Box } from '@mui/material';

import CustomDataGrid from '../common/CustomDataGrid';
import { useApiKeys } from '../../repositories/api-key-repository.tsx';
import { apiKeyListColumns } from '../../services/api-keys-service.tsx';
import { getIncrementedPaginationModel } from '../../utils';

const FilteredApiKeys: FC = () => {
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 5,
    page: 0,
  });

  const { apiKeyResponse, isLoading, isError } = useApiKeys(getIncrementedPaginationModel(paginationModel));

  return (
    <div style={{ marginTop: 32 }} data-testid='filtered-api-keys-wrapper'>
      <Box
        sx={{
          mt: 0,
          borderRadius: '12px',
          height: apiKeyResponse?.totalCount ? 'auto' : '24rem',
          '& .MuiDataGrid-row': {},
          '& .MuiDataGrid-cell': {
            overflow: 'visible',
            height: 'max-content',
          },
        }}>
        <CustomDataGrid
          data-testid='filtered-api-keys-grid'
          isLoading={isLoading}
          isError={isError}
          rows={apiKeyResponse?.keys || []}
          rowCount={apiKeyResponse?.totalCount}
          columns={apiKeyListColumns}
          paginationModel={paginationModel}
          onPaginationChange={setPaginationModel}
          noRowsOptions={{ title: 'No Keys found', subtitle: 'You do not have any Api Keys' }}
        />
      </Box>
    </div>
  );
};

export default FilteredApiKeys;
