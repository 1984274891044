import React, { useState } from 'react';
import { Button, DialogActions, DialogContent, DialogTitle, Divider, Stack, Typography } from '@mui/material';

import LoadingButton from '../../ui/LoadingButton.tsx';
import { CustomModal } from '../CustomModal.tsx';

import { OtpInput } from './OtpInput.tsx';

export interface OtpVerificationFormProps {
  isLoading: boolean;
  otpReceiver: string;
  open: boolean;
  onClose: () => void;
  onSubmit: (value: string) => void;
}

export const OtpVerificationForm = ({ isLoading, otpReceiver, open, onClose, onSubmit }: OtpVerificationFormProps) => {
  const [value, setValue] = useState('');

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    onSubmit(value);
  };

  const handleOtpChange = (otp: string) => {
    setValue(otp);
  };

  return (
    <CustomModal open={open} onClose={onClose}>
      <DialogTitle sx={{ color: 'neutral.900' }} fontSize={24} fontWeight={500}>
        We'll need additional information
      </DialogTitle>
      <Divider />
      <DialogContent sx={{ padding: 6 }}>
        <Stack direction='column' gap={7} pb={6}>
          <Typography fontSize={18} fontWeight={400}>
            For security reasons, we need to verify it's you before we complete this action. Please enter OTP sent to: {otpReceiver}
          </Typography>
          <Stack>
            <Typography fontSize={18} fontWeight={500}>
              Your 6 digit code
            </Typography>
            <OtpInput onChange={handleOtpChange} />
          </Stack>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <LoadingButton
          data-testid='submit-button'
          variant='contained'
          onClick={handleSubmit}
          autoFocus
          disabled={!value || value.length < 6}
          sx={{ borderRadius: 3, textTransform: 'capitalize', minWidth: '6rem !important' }}
          isLoading={isLoading}>
          Submit
        </LoadingButton>
      </DialogActions>
    </CustomModal>
  );
};
