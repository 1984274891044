import { useNavigate } from 'react-router-dom';

const useHandleBack = (fallbackRoute: string) => {
  const navigate = useNavigate();

  return () => {
    if (typeof window !== 'undefined' && window.history.length > 1) navigate(-1);
    else navigate(fallbackRoute);
  };
};

export default useHandleBack;
