import { Paper, Stack, Typography } from '@mui/material';
import { FC } from 'react';

import FilteredApiKeys from '../components/api-keys/FilteredApiKeys.tsx';

const ApiKeysPage: FC = () => {
  return (
    <div>
      <Stack direction='row' gap={1} alignItems='center'>
        <Typography variant='h5' fontWeight={500} textTransform='capitalize' color='neutral.900'>
          Glomo's Credential Details
        </Typography>
      </Stack>
      <Paper
        elevation={0}
        sx={{
          border: '1px solid',
          borderColor: 'neutral.100',
          p: 8,
          mt: '40px',
          borderRadius: '12px',
          height: '100%',
          boxSizing: 'border-box',
        }}>
        <Stack direction='column'>
          <Stack direction='row'>
            <Typography fontSize={18} fontWeight={500} color='neutral.900'>
              API Keys
            </Typography>
          </Stack>
          <Stack direction='row' justifyContent='space-between'>
            <Typography fontSize={14} color='neutral.800' fontWeight={400}>
              Easily view and organize your API keys and security settings
            </Typography>
            <a href='https://docs.glomopay.com/api-documentation/authentication#authentication' target='_blank'>
              <Typography color='rhino.800' fontWeight={500} fontSize={14}>
                Learn more about API authentication
              </Typography>
            </a>
          </Stack>
          <FilteredApiKeys />
        </Stack>
      </Paper>
    </div>
  );
};

export default ApiKeysPage;
