import { FC } from 'react';
import { Chip } from '@mui/material';

export type StatusChipVariant = 'green' | 'red' | 'yellow' | 'grey' | 'blue';

function getChipColors(variant: StatusChipVariant) {
  switch (variant) {
    case 'green':
      return { backgroundColor: 'success.50', textColor: 'success.700' };
    case 'red':
      return { backgroundColor: 'error.50', textColor: 'error.700' };
    case 'yellow':
      return { backgroundColor: 'warning.50', textColor: 'warning.700' };
    case 'grey':
      return { backgroundColor: 'neutral.100', textColor: 'neutral.700' };
    case 'blue':
      return { backgroundColor: 'rhino.50', textColor: 'rhino.700' };
    default:
      return { backgroundColor: 'neutral.100', textColor: 'neutral.700' };
  }
}

interface StatusChipProps {
  variant: StatusChipVariant;
  text: string;
}

const StatusChip: FC<StatusChipProps> = ({ variant, text }) => {
  const { backgroundColor, textColor } = getChipColors(variant);

  return (
    <Chip
      sx={{
        borderRadius: '4px',
        border: 'none',
        minWidth: '8rem',
        height: '1.5rem',
        p: 1,
        backgroundColor,
        '& .MuiChip-label': {
          color: textColor,
          fontSize: '15px',
          '&:first-letter': { textTransform: 'capitalize' },
        },
        '--Chip-radius': '4px',
        '--Chip-gap': '18px',
        '--Chip-paddingInline': '30px',
      }}
      label={text}
    />
  );
};

export default StatusChip;
