import { Outlet } from 'react-router-dom';

import useAuth from '../../repositories/useAuth';

function PrivateRoute() {
  const { isLoading, user, isError } = useAuth();

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (!user || isError) {
    window.location.href = `${import.meta.env.GLOMO_RAILS_APP_BASE_URL}/login`;
  }

  return <Outlet />;
}

export default PrivateRoute;
