import { QueryFunction, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import queryKeys from '../constants/query-keys';
import apiClient from '../utils/api-client';
import { TRefundDetails, TRefundExploration, TRefundsListResponse } from '../types/RefundTypes';
import { ConvertKeysToCamelCase, convertToCamelCase, convertToSnakeCase } from '../utils/case-converter';
import { TBasicListFilters, TPaginationOptions } from '../types/PaginationTypes';

export const useRefundDetails = (paymentId: string) => {
  const { isLoading, data, error } = useQuery({
    queryKey: [queryKeys.REFUND_DETAILS, paymentId],
    queryFn: fetchRefundDetails,
    retry: false,
  });

  return { isLoading, refundDetails: data ? convertToCamelCase(data) : undefined, error };
};

export const useRefunds = (filters: TBasicListFilters, paginationOptions: TPaginationOptions) => {
  const { isLoading, data, error, isError } = useQuery({
    queryKey: [queryKeys.REFUNDS_LIST, filters, paginationOptions],
    queryFn: fetchRefundsList,
  });
  return { isLoading, refundsResponse: data, error, isError };
};

export const useOneRefund = (refundId: string) => {
  const { isLoading, data, error } = useQuery({
    queryKey: [queryKeys.REFUNDS_LIST, refundId],
    queryFn: fetchOneRefund,
  });
  return { isLoading, refund: data, error };
};

export const useCreateRefund = () => {
  const queryClient = useQueryClient();

  const { mutateAsync } = useMutation({
    mutationFn: createRefund,
    onSuccess(data) {
      queryClient.invalidateQueries({ queryKey: [queryKeys.PAYMENTS, data.paymentId] });
      queryClient.invalidateQueries({ queryKey: [queryKeys.REFUNDS_LIST] });
    },
  });

  return { mutateAsync };
};

const fetchRefundsList: QueryFunction<ConvertKeysToCamelCase<TRefundsListResponse>, [string, TBasicListFilters, TPaginationOptions]> = async ({
  queryKey,
}) => {
  const [, filters, paginationOptions] = queryKey;
  const { data } = await apiClient.get('/v1/refunds', convertToSnakeCase({ ...filters, ...paginationOptions }));
  return convertToCamelCase(data);
};

const fetchRefundDetails: QueryFunction<TRefundExploration, [string, string]> = async ({ queryKey }) => {
  const [, paymentId] = queryKey;
  const { data, error } = await apiClient.get(`/v1/payments/${paymentId}/refund-details`);
  if (error) throw new Error(error.message);
  return data;
};

const createRefund = async ({
  paymentId,
  reason,
  fileName,
  documentUrl,
}: {
  paymentId: string;
  reason: string;
  fileName: string;
  documentUrl: string;
}) => {
  const { data, error } = await apiClient.post('/v1/refunds', convertToSnakeCase({ id: paymentId, reason, documentUrl, fileName }));
  if (error) throw new Error(error.message);
  return convertToCamelCase(data);
};

const fetchOneRefund: QueryFunction<ConvertKeysToCamelCase<TRefundDetails>, [string, string]> = async ({ queryKey }) => {
  const [, refundId] = queryKey;
  const { data, error } = await apiClient.get(`/v1/refunds/${refundId}`);
  if (error) throw new Error(error.message);
  return convertToCamelCase(data);
};
