import { FC, useState } from 'react';
import { Controller } from 'react-hook-form';
import { HexColorPicker, HexColorInput } from 'react-colorful';
import { Box, IconButton, InputAdornment, Popover, Stack, TextField, Tooltip, Typography, useTheme } from '@mui/material';
import { PiArrowCounterClockwise, PiInfo } from 'react-icons/pi';

import { GLOMOPAY_CHECKOUT_DEFAULT_BG_COLOR } from '../../constants/app-constants';
import FileUploader from '../common/FileUploader';

import useCheckoutCustomisationContext from './useCheckoutCustomisationContext';

const CheckoutCustomisationForm: FC = () => {
  const theme = useTheme();
  const {
    form,
    api: { checkoutCustomisation },
  } = useCheckoutCustomisationContext()!;
  // TODO: Make stateless Popover component like Radix ui
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const open = Boolean(anchorEl);

  const currentLogo = form.watch('logo');
  const currentBackgroundColor = form.watch('bgColor');

  const handleColorPopoverOpen = (e: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(e.currentTarget);
  };

  const handleColorPopoverClose = () => {
    setAnchorEl(null);
  };

  return (
    <Stack paddingX='1.25rem' paddingY='1.75rem' gap='1rem'>
      <Typography color='neutral.700' fontWeight={500} fontSize='1.125rem'>
        Upload logo
      </Typography>

      <Stack direction='row' alignItems='center' gap='.5rem'>
        <FileUploader
          id='logo'
          accept='image/*'
          title={checkoutCustomisation?.customisations.logo ? 'Replace logo' : 'Upload logo'}
          error={form.formState.errors.logo?.message as string}
          files={currentLogo ? [currentLogo] : []}
          maxFileSizeInKB={500}
          onChange={(files) => {
            if (files?.[0]) form.setValue('logo', files[0], { shouldDirty: true, shouldTouch: true, shouldValidate: true });
            else form.setValue('logo', undefined, { shouldDirty: true, shouldTouch: true, shouldValidate: true });
          }}
        />
        <Tooltip title='Restore to default' placement='top-start' arrow>
          <span>
            <IconButton
              disabled={!currentLogo}
              onClick={() => {
                form.setValue('logo', undefined, { shouldDirty: true, shouldTouch: true, shouldValidate: true });
              }}>
              <PiArrowCounterClockwise />
            </IconButton>
          </span>
        </Tooltip>
      </Stack>

      <Box color='neutral.400' fontSize='.75rem'>
        <ul style={{ listStyleType: 'disc', listStylePosition: 'inside' }}>
          <li>Max file size: 500 KB</li>
          <li>File type: jpeg, jpg, png, svg</li>
          <li>Upload your logo in a square format for optimal display.</li>
        </ul>
      </Box>
      <Stack direction='row' alignItems='center' gap='.5rem'>
        <Typography color='neutral.700' fontWeight={500} fontSize='1.125rem'>
          Primary brand colour
        </Typography>

        <Tooltip arrow title="Select your brand's primary colour. It will be used in the header and buttons" placement='top-start'>
          <button>
            <PiInfo
              style={{
                height: '.8rem',
                width: '.8rem',
              }}
            />
          </button>
        </Tooltip>
      </Stack>

      <div>
        <Stack direction='row' alignItems='center' gap='.5rem'>
          <TextField
            onClick={handleColorPopoverOpen}
            variant='outlined'
            fullWidth
            value={currentBackgroundColor}
            error={!!form.formState.errors.bgColor}
            helperText={form.formState.errors.bgColor?.message}
            slotProps={{
              input: {
                readOnly: true,
                startAdornment: (
                  <InputAdornment position='start'>
                    <Box
                      sx={{
                        width: '1.75rem',
                        height: '1.75rem',
                        backgroundColor: currentBackgroundColor,
                        borderRadius: '100%',
                      }}
                    />
                  </InputAdornment>
                ),
              },
            }}
          />

          <Tooltip title='Restore to default' placement='top-start' arrow>
            <IconButton
              onClick={() =>
                form.setValue('bgColor', GLOMOPAY_CHECKOUT_DEFAULT_BG_COLOR, { shouldDirty: true, shouldTouch: true, shouldValidate: true })
              }>
              <PiArrowCounterClockwise />
            </IconButton>
          </Tooltip>
        </Stack>

        <Popover
          open={open}
          anchorEl={anchorEl}
          onClose={handleColorPopoverClose}
          anchorOrigin={{
            vertical: 'center',
            horizontal: 'left',
          }}
          sx={{
            '& .MuiPaper-root': {
              boxShadow: 'none',
            },
          }}>
          <Controller
            name='bgColor'
            control={form.control}
            render={({ field }) => (
              <Stack gap='1rem' style={{ padding: '1rem', width: '21rem', alignItems: 'center' }}>
                <HexColorPicker color={field.value} onChange={field.onChange} style={{ width: '100%' }} />
                <HexColorInput
                  color={field.value}
                  onChange={field.onChange}
                  style={{ border: `1px solid ${theme.palette.neutral[100]}`, width: '70%', textAlign: 'center' }}
                />
              </Stack>
            )}
          />

          <Typography textTransform='uppercase' textAlign='center'>
            hex
          </Typography>
        </Popover>
      </div>

      {/* <Stack direction='row' alignItems='center' gap='.5rem'>
          <Typography color='neutral.700' fontWeight={500} fontSize='1.125rem'>
            Button text
          </Typography>

          <Tooltip arrow title="Select the text that you want to appear inside your checkout's button" placement='top-start'>
            <button>
              <PiInfo
                style={{
                  height: '.8rem',
                  width: '.8rem',
                }}
              />
            </button>
          </Tooltip>
        </Stack>
        <StyledSelect
          // defaultValue={checkoutButtonCustomisationTexts.PAY_NOW}
          value={checkoutButtonCustomisationTexts.PAY_NOW}
          // onChange={(e) => setStatus(e.target.value as string)}
          // onClearButtonClick={() => setStatus('')}
          // placeholder='status'
        >
          {Object.values(checkoutButtonCustomisationTexts).map((text, idx) => (
            <MenuItem key={idx} value={text} sx={{ textTransform: 'capitalize' }}>
              {text}
            </MenuItem>
          ))}
        </StyledSelect> */}
    </Stack>
  );
};

export default CheckoutCustomisationForm;
