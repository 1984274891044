import { Accordion, AccordionDetails, AccordionSummary, Stack, Typography } from '@mui/material';
import { PiArrowSquareUpRight, PiCaretDown, PiReceipt, PiSquaresFour, PiUserSwitch } from 'react-icons/pi';

import { redirectToRailsApp } from '../../../utils/route-utils';

import NavLinkContainer from './NavLinkContainer';

function PayoutNav() {
  return (
    <Accordion disableGutters square elevation={0} sx={{ '&:before': { display: 'none' }, pl: 2 }}>
      <AccordionSummary expandIcon={<PiCaretDown />}>
        <Stack direction='row' gap={2} alignItems='center'>
          <PiArrowSquareUpRight size={20} />
          <Typography fontSize={14}>Payout</Typography>
        </Stack>
      </AccordionSummary>
      <AccordionDetails>
        <NavLinkContainer onClick={() => redirectToRailsApp('/v1/payout')}>
          <Stack direction='row' gap={2} alignItems='center'>
            <PiSquaresFour size={20} />
            <Typography>Overview</Typography>
          </Stack>
        </NavLinkContainer>
        <NavLinkContainer onClick={() => redirectToRailsApp('/v1/payout/transactions')}>
          <Stack direction='row' gap={2} alignItems='center'>
            <PiReceipt size={20} />
            <Typography>Transactions</Typography>
          </Stack>
        </NavLinkContainer>
        <NavLinkContainer onClick={() => redirectToRailsApp('/v1/payout/beneficiaries')}>
          <Stack direction='row' gap={2} alignItems='center'>
            <PiUserSwitch size={20} />
            <Typography>Beneficiaries</Typography>
          </Stack>
        </NavLinkContainer>
      </AccordionDetails>
    </Accordion>
  );
}

export default PayoutNav;
