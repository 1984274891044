import { Typography, Stack, Paper } from '@mui/material';
import { PiCalendar, PiEnvelope, PiIdentificationCard, PiLock, PiPhone } from 'react-icons/pi';

import IconisedLabelledText from '../components/IconisedLabelledText.tsx';
import useAuth from '../repositories/useAuth.tsx';
import { userProfileDetails } from '../services/user-service.ts';
import EditPasswordModal from '../components/EditPasswordModal.tsx';
import { formatDateTime } from '../utils/datetime-utils.ts';

function ProfilePage() {
  const { user } = useAuth();
  const userDetails = userProfileDetails(user);

  return (
    <Stack gap={10}>
      <Stack direction='row' gap={2} alignItems='center' justifyContent='space-between'>
        <Typography fontSize={24} fontWeight={500} color='neutral.900'>
          My Profile
        </Typography>
      </Stack>
      <Paper variant='outlined' sx={{ padding: '32px' }}>
        <Stack direction='row' gap={40}>
          <Stack direction='column' gap={6}>
            <Stack direction='row' gap={2}>
              <IconisedLabelledText icon={PiIdentificationCard} label='First Name' text={userDetails.firstName} />
            </Stack>
            <Stack direction='row' gap={2}>
              <IconisedLabelledText icon={PiEnvelope} label='Email' text={userDetails.email} />
            </Stack>
            <Stack direction='row' gap={2}>
              <IconisedLabelledText icon={PiLock} label='Password' text='********' />
              <EditPasswordModal />
            </Stack>
          </Stack>
          <Stack direction='column' gap={6}>
            <Stack direction='row' gap={2}>
              <IconisedLabelledText icon={PiIdentificationCard} label='Last Name' text={userDetails.lastName} />
            </Stack>
            <Stack direction='row' gap={2}>
              <IconisedLabelledText icon={PiPhone} label='Phone number' text={userDetails.phone} />
            </Stack>
            <Stack direction='row' gap={2} pb={8}>
              <IconisedLabelledText icon={PiCalendar} label='Created on' text={formatDateTime(userDetails.createdAt)} />
            </Stack>
          </Stack>
        </Stack>
      </Paper>
    </Stack>
  );
}

export default ProfilePage;
