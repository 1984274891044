import { Box, Toolbar } from '@mui/material';
import { Outlet } from 'react-router-dom';

import TopBar from './TopBar';
import LeftNavBar from './left-nav/LeftNavBar';

function Layout() {
  return (
    <Box
      sx={{
        display: 'flex',
        backgroundColor: 'grey.50',
        minHeight: '100vh',
        width: '100vw',
        overflowX: 'clip',
      }}>
      <TopBar />
      <LeftNavBar />
      <Box component='main' sx={{ flexGrow: 1, px: 12, pt: 14, pb: 3 }}>
        <Toolbar />
        <Outlet />
      </Box>
    </Box>
  );
}

export default Layout;
