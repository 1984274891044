import { GridColDef, GridRenderCellParams, GridValidRowModel } from '@mui/x-data-grid';

import StatusChip from '../components/common/StatusChip';
import { formatDateTime } from '../utils/datetime-utils';
import { TBalanceConversion } from '../types/BalanceConversionTypes';
import { formatAmount } from '../utils';

import StatusToChipMapper from './status-to-chip-mapper';

export const balanceConversionStatusToChipMapper = new StatusToChipMapper({
  success: {
    variant: 'green',
    text: 'success',
  },
  failure: {
    variant: 'red',
    text: 'fail',
  },
});

export const conversionDetailColumns: GridColDef[] = [
  { field: 'id', headerName: 'Conversion Id', minWidth: 150, flex: 1 },
  { field: 'createdAt', headerName: 'Created at', minWidth: 150, flex: 1, valueGetter: (val) => formatDateTime(val) },
  {
    field: 'from',
    headerName: 'From',
    width: 150,
    flex: 1,
    valueGetter: (val: TBalanceConversion['from']) => formatAmount(val.cents, val.currencyIso),
  },
  {
    field: 'to',
    headerName: 'To',
    width: 150,
    flex: 1,
    valueGetter: (val: TBalanceConversion['to']) => formatAmount(val.cents, val.currencyIso),
  },
  {
    field: 'fees',
    headerName: 'Fees',
    width: 150,
    flex: 1,
    valueGetter: (val: TBalanceConversion['fees']) => formatAmount(val.cents, val.currencyIso),
  },
  {
    field: 'status',
    headerName: 'Status',
    description: 'This column has a value getter and is not sortable.',
    sortable: false,
    width: 150,
    flex: 1,
    renderCell: (params: GridRenderCellParams<GridValidRowModel, string>) => {
      const { text, variant } = balanceConversionStatusToChipMapper.mapStatusToChipInputs(params.value!);
      return <StatusChip variant={variant} text={text} />;
    },
  },
];
